.error404 {
  .alert-warning {
    text-align: center;
    min-height: 65vh;
    @include flexbox();
    @include justify(center);
    @include flex-wrap(wrap);
    flex-direction: column;
    .title {
      color: #2759a4;
      font-family: $additionalFont_bold;
      font-size: 98px;
      text-transform: uppercase;
    }
    .subtitle {
      color: #1493ff;
      font-size: 35px;
      margin: 15px 0 0;
    }
  }
}