.f-row {
  @include flexbox();
}

.f-wrap {
  @include flex-wrap(wrap);
}

.text-center {
  text-align: center;
}

.m-0 {
  margin: 0 !important;
}

.tab-content {
   .tab-pane {
    display: none;
     &.active {
       display: block;
     }
  }
}
.fade {
  opacity: 0;
  @include anim(opacity .15s linear);
  &.show {
    opacity: 1;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}
.collapse:not(.show) {
  display: none;
}

.dropdown {
 &.show {
   .dropdown-toggle {
     &:after {
       border-bottom: 7px solid;
       border-right: 7px solid transparent;
       border-top: 0;
       border-left: 7px solid transparent;
     }
   }
 }
}
.dropdown-toggle {
  padding: 0;
  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    vertical-align: middle;
    content: "";
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 0;
    border-left: 7px solid transparent;
    @include anim(opacity .15s linear);
    @include breakpoint('sm-xs') {
      display: none;
    }
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 144px;
  padding: 10px 0;
  margin: 10px 0 0;
  font-size: 12px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
  &.show {
    display: block;
  }
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 6px;
  color: #494949;
  font-family: $additionalFont_Semibold;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover {
    background-color: #e1e1e1;
    color: #494949;
  }
  &.active {
    background-color: #e1e1e1;
  }
}

.red-text {
  color: #e70021;
}

.grayscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  @include anim(all .25s ease);
}

.d-none {
  display: none !important;
}

.custom-checkbox {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked {
      & + label {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 6px;
          width: 6px;
          height: 14px;
          border: solid #0079bf;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          @include breakpoint('xs') {
            top: 1px;
          }
        }
      }
    }
  }
  label {
    position: relative;
    cursor: pointer;
    color: #34455f;
    font-size: 24px;
    letter-spacing: -1px;
    line-height: 1;
    @include breakpoint('xs') {
      font-size: 20px;
    }
    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #0079bf;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 5px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }
}

.pagination {
  text-align: center;
  list-style-type: none;
  width: 100%;
  @include flexbox();
  @include align(center);
  @include justify(center);
  .page-item {
    display: inline-block;
    &.active {
      .page-link {
        color: $baseWhite;
        background: $primaryColour;
      }
    }
    .page-link {
      width: 32px;
      height: 32px;
      text-align: center;
      font-weight: 600;
      transition: all 250ms ease-in-out;
      border-radius: 50%;
      font-size: 24px;
      @include flexbox();
      @include align(center);
      @include justify(center);
      &:hover {
        color: $baseWhite;
        background: $primaryColour;
        .next, 
        .prev {
          &:after,
          &:before {
            background: $baseWhite;
          }
        }
      }
      .next {
        position: relative;
        &:after,
        &:before {
          content: '';
          background-color: $primaryColour;
          position: absolute;
          left: -7px;
          height: 4px;
          width: 18px;
          border-radius: 3px;
        }
        &:before {
          top: -7px;
        }
        &:after {
          top: 4px;
        }
        &:before {
          transform: rotate(45deg) translateX(0);
        }
        &:after {
          transform: rotate(-45deg) translateX(0);
        }
      }
      .prev {
        position: relative;
        &:after,
        &:before {
          content: '';
          background-color: $primaryColour;
          position: absolute;
          left: -11px;
          height: 4px;
          width: 18px;
          border-radius: 3px;
        }
        &:before {
          top: -7px;
        }
        &:after {
          top: 4px;
        }
        &:before {
          transform: rotate(-45deg) translateX(0);
        }
        &:after {
          transform: rotate(45deg) translateX(0);
        }
      }
    }
  }
  li + li {
    margin-left: 5px;
  }
}

.sitemap-row {
  @include flexbox();
  @include flex-wrap(wrap);
  @include breakpoint($max: 575px) {
    max-width: 300px;
    margin: auto;
  }
  .sitemap-col {
    width: 33.33%;
    @include breakpoint($max: 575px) {
      width: 100%;
    }
    ul {
      & > li{
          ul {
          padding: 0 0 0 20px;
          margin: 0;
        }
      } 
    }
  }
}

.hide-slider {
  opacity: 0;
}