/*----------------------------------------------------------------
------------------------------------------------------------------
--------------------------- MAIN + RESETS ------------------------
------------------------------------------------------------------
----------------------------------------------------------------*/

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
::-moz-selection {
    background: $primaryColour;
    color: $textOnPrimaryColour;
    text-shadow: none;
}
::selection {
    background: $primaryColour;
    color: $textOnPrimaryColour;
    text-shadow: none;
}
.chromeframe,
li.graphic-only {
    display: none !important;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
}
body {
    margin: 0;
    line-height: 1.6;
    font-size: 17px;
    color: $textColour;
    background: #fff;
    min-height: 100%;
    font-weight: normal;
    position: relative;
    overflow-x: hidden;
    @include breakpoint('xs') {
        font-size: 14px;
    }
}
#wrap {
    position: relative;
    background: $backgroundColour;
    min-height: 100% !important;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden],
template {
    display: none;
}
/* Embedded content */

img {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
svg:not(:root) {
    overflow: hidden;
}
/* Grouping content */

figure {
    margin: 15px 40px;
}
hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}
pre {
    overflow: auto;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 100%;
}
/* Forms */

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
}
button {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}

/* HTML5 / MISC */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
ins {
    background-color: $primaryColour;
    color: #fff;
    text-decoration: none;
}
del {
    text-decoration: line-through;
}