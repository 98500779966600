.casino-title-widget {
  padding: 0;
  .text-container {
    @include flexbox();
    @include justify(space-between);
    margin: 0 0 40px;
    .text-holder {
      padding-right: 8%;
      @include breakpoint('sm-xs') {
        padding-right: 0;
      }
    }
    .title {
      color: $primaryColour;
      font-size: 54px;
      font-family: $additionalFont_bold;
      font-style: italic;
      text-transform: uppercase;
      @include breakpoint('sm-xs') {
        font-size: 40px;
      }
      @include breakpoint('xs') {
        font-size: 30px;
      }
      &.underline {
        position: relative;
        &:after {
          content: '';
          display: block;
          margin: 3px 0 0;
          width: 100%;
          height: 4px;
          background-color: rgba(99, 99, 99, 0.44);
        }
      }
    }
    .text {
      color: #494949;
      font-family: $additionalFont_bold;
      font-size: 15px;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .logo {
      min-width: 13%;
      @include breakpoint('sm-xs') {
        display: none;
      }
    }
    .logo_mobile {
      display: none;
      @include breakpoint('sm-xs') {
        display: inline-block;
        max-width: 100px;
        margin-left: 30px;
        vertical-align: middle;
      }
      @include breakpoint('xs') {
        max-width: 68px;
      }
    }
  }
}