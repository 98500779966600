/* Variables */
$primaryColour: #2759a4 !default;
$primaryColourDark: #34455f !default;

$downloadsWidgetColour: #d10211 !default;
$downloadsWidgetColourDark: #970710 !default;

$primaryColourHover:darken($primaryColour,15%);

$textColour:#000;
$borderColour:#000;
$borderWidth:2px;
$boxBackground:#f3f3f3;
$baseWhite: #ffffff;

$textDark:#000;
$boxBackgroundDark: #000;
$tabsBg: #f5f5f5;
$textOnPrimaryColour:#fff;
$backgroundColour:#fff;

$widgetBackgrounGray: #f0f3f8;

$mainWidgetPadding: 40px;
$mainWidgetPaddingMobile: 25px;
$sidebarWidgetPadding: 25px;
$sidebarWidgetPaddingMobile: 25px;

$gutterWidth:15px;
$gutterWidthMobile:20px;

$additionalFont_regular: 'ProximaNova-Regular';
$additionalFont_bold: 'ProximaNova-Bold';
$additionalFont_Semibold: 'ProximaNova-Semibold';
$additionalFont_light: 'ProximaNova-Light';
$additionalFont_thin: 'ProximaNova-Thin';






$borderWidth: 2px;

$baseRedColor: #ff0000;