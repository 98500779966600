.intro-game-widget {
  .heading-holder {
    margin: 40px 0 15px;
    @include breakpoint('xs') {
      margin: 25px 0 15px;
    }
    .title {
      color: #34455f;
      //font-size: 30px;
      font-size: 40px;
      font-family: $additionalFont_Semibold;
      @include breakpoint('xs') {
        //font-size: 22px;
        font-size: 28px;
      }
    }
    .date {
      color: #34455f;
      font-size: 16px;
      margin: 5px 0 0;
      line-height: 1.2;
    }
  }
  .img-holder {
    margin: 0 0 40px;
    @include breakpoint('xs') {
      margin: 0 0 25px;
    }
    img {
      width: 100%;
    }
  }
  h2 {
    color: #34455f;
    font-family: $additionalFont_bold;
    font-size: 25px;
    padding: 25px 0 0;
    @include breakpoint('xs') {
      font-size: 20px;
      padding: 10px 0 0;
    }
  }
  h3 {
    color: $primaryColourDark;
    font-size: 20px;
    margin: 25px 0 15px;
    @include breakpoint('xs') {
      font-size: 17px;
      margin: 10px 0 10px;
    }
  }
}

.category {
  .heading-holder {
    margin: 40px 0 57px;
  }
  .heading-text {
    line-height: 1.3;
    font-style: italic;
  }
}