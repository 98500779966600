.slider-widget {
  padding: 0 0 54px;
  .tab-nav {
    background-color: #299cff;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      li {
        display: inline-block;
        a {
          color: $baseWhite;
          font-size: 24px;
          padding: 15px 25px;
          display: block;
          &.active {
            font-family: $additionalFont_bold;
            background-color: $primaryColour;
          }
          @include breakpoint('xs') {
            font-size: 18px;
            padding: 15px 20px;
          }
        }
      }
    }
  }
  .tab-content {
    padding: 56px 0 0;
    @include breakpoint('xs') {
      padding: 30px 0 0;
    }
    .container {
      @include breakpoint('lg') {
        max-width: 1015px;
      }
      @include breakpoint('xl') {
        max-width: 1315px;
      }
      @include breakpoint('xs') {
        padding: 0;
      }
    }
    .tab-pane {
      overflow-y: hidden;
      height: 0;
      display: block;
      &.active {
        overflow-y: visible;
        height: auto;
      }
    }
  }
}