.main-content,
aside {
    float: left;
    @include breakpoint('sm-xs') {
        width: 100%;
    }
}
.widget-container {
    position: relative;
}
@include breakpoint('sm-xs') {
    .main-content {
        margin-bottom: $mainWidgetPadding;
        @include breakpoint('xs') {
            margin-bottom: $mainWidgetPaddingMobile;
        }
    }
}
.main-content .widget-container {
    margin-top: $mainWidgetPadding;
    @include breakpoint('xs') {
        margin-top: $mainWidgetPaddingMobile;
    }
    h3 {
        margin-bottom: 25px;
        @include breakpoint('xs') {
            margin-bottom: 15px;
        }
    }
}
aside .widget-container {
    margin-top: $sidebarWidgetPadding;
    @include breakpoint('xs') {
        margin-top: $sidebarWidgetPaddingMobile;
    }
    h4 {
        margin-bottom: 18px;
        @include breakpoint('xs') {
            margin-bottom: 15px;
        }
    }
}
aside .widget-container:first-child {
    margin-top: 0 !important;
}

h1.page-title + .standard-widget {
    margin-top: ($mainWidgetPadding / 2);
}