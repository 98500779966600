.intro-inner-widget {
  // background: url("../img/intro-casino.png");
  color: $baseWhite;
  padding: 60px 0 23px;
  background-repeat: no-repeat;
  background-color: #000;
  background-position: 100% 0;
  background-size: contain;
  @include breakpoint('xs') {
    padding: 30px 0 23px;
  }
  @include breakpoint($max: 575px) {
    background-size: unset;
  }
  &:after {
    @include breakpoint('sm-xs') {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .logo-casino {
    display: none;
    @include breakpoint($max: 575px) {
      display: block;
      text-align: right;
      position: absolute;
      right: 15px;
      bottom: 15%;
      max-width: 80px;
    }
  }
  .text-holder {
    position: relative;
    max-width: 57%;
    @include flexbox();
    @include flex-wrap(wrap);
    @include breakpoint('sm-xs') {
      max-width: 100%;
    }
  }
  .info-col {
    flex: 50%;
    @include breakpoint($max: 575px) {
      flex: 100%;
      @include flexOrder(2);
    }
    .name {
      font-size: 35px;
    }
    .rating-row {
      font-size: 18px;
      font-family: $additionalFont_thin;
      text-transform: uppercase;
      margin: 8px 0 0;
      @include flexbox();
      @include flex-wrap(wrap);
      .rating {
        margin: 0 0 0 15px;
      }
    }
    .btn {
      display: inline-block;
      margin: 35px 0 0;
      padding: 4px 60px;
      text-transform: uppercase;
      font-size: 21px;
      &:before,
      &:after {
        display: none;
      }
    }
    .players-position {
      margin: 40px 0 0;
      font-size: 15px;
      display: block;
      color: $baseWhite;
      .flag-container {
        margin: 0 3px 0 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        padding: 5px;
      }
    }
  }
  .list-col {
    font-size: 18px;
    letter-spacing: -0.75px;
    margin: 0;
    list-style-type: none;
    flex-direction: column;
    flex: 50%;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify(center);
    @include breakpoint($max: 575px) {
      flex: 100%;
      margin: 0 0 30px;
      @include flexOrder(1);
    }
    li {
      position: relative;
      margin: 0;
      padding: 0 0 0 30px;
      line-height: 1.2;
      letter-spacing: 0;
      font-size: 15px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        background-color: $baseWhite;
        height: 4px;
      }
      &:before {
        transform: rotate(45deg) translateX(-1px);
        width: 10px;
      }
      &:after {
        transform: rotate(-45deg) translateX(5px);
        width: 15px;
      }
      &+li {
        margin-top: 15px;
      }
    }
  }
}