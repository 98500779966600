.gambling-widget {
  background: url('../img/gambling-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $primaryColour;
  padding: 55px 0 0;
  @include breakpoint('xs') {
    padding: 22px 0 0;
  }
  &.gamecat {
    background-image: url('../img/gambling_category.jpg');
    background-size: 100%;
    background-color: #34455f;
    margin-top: 3px;
    .items-container {
      max-width: 1125px;
      margin: 0 auto;
    }
    .item {
      width: 50%;
      @include breakpoint('lg') {
        max-width: 440px;
      }
      @include breakpoint('md') {
        max-width: 380px;
      }
      @include breakpoint('xs-sml') {
        width: 100%;
        max-width: 280px;
      }
    }
  }
  .text-holder {
    color: $baseWhite;
    text-align: center;
    max-width: 1075px;
    margin: auto;
    p {
      font-size: 15px;
      line-height: 1.2;
      @include breakpoint('xs') {
        margin: 10px 0 0;
      }
    }
  }
  .title {
    font-family: $additionalFont_bold;
    font-size: 50px;
    font-style: italic;
    text-transform: uppercase;
    @include breakpoint('sm-xs') {
      font-size: 35px;
    }
    @include breakpoint('xs') {
      font-size: 24px;
    }
  }
  .items-container {
    @include flexbox();
    @include justify(space-around);
    @include flex-wrap(wrap);
    padding: 25px 0 80px;
    @include breakpoint('xs') {
      padding: 25px 0 20px;
    }
  }
  .item {
    background-color: $baseWhite;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    width: 33%;
    margin: 125px 0 0;
    @include breakpoint('lg') {
      max-width: 350px;
    }
    @include breakpoint('xl') {
      max-width: 440px;
    }
    @include breakpoint('md') {
      max-width: 280px;
    }
    @include breakpoint('sm-xs') {
      max-width: 320px;
      width: 50%;
    }
    @include breakpoint('xs') {
      margin: 40px 0 20px;
      max-width: 202px;
    }
    @include breakpoint('xs-sml') {
      width: 100%;
      max-width: 280px;
    }
    .img-holder {
      background-color: $baseWhite;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      margin: -85px auto 0;
      width: 170px;
      height: 170px;
      @include flexbox();
      @include align(center);
      @include justify(center);
      @include breakpoint('xs') {
        margin: -40px auto 0;
        width: 80px;
        height: 80px;
      }
      img {
        display: block;
        max-width: 100%;
        @include breakpoint('xs') {
          max-width: 40px;
        }
      }
    }
    .text-holder {
      padding: 10px 50px 40px;
      @include breakpoint('xs') {
        padding: 10px 15px 20px;
      }
      .item-title {
        color: #34455f;
        font-family: $additionalFont_Semibold;
        font-size: 30px;
        @include breakpoint('xs') {
          font-size: 18px;
        }
      }
      p {
        color: #898989;
        font-size: 17px;
        line-height: 1.2;
        margin: 25px 0 0;
        @include breakpoint('xs') {
          font-size: 13px;
        }
      }
    }
    .link {
      color: #ff0000;
      font-family: $additionalFont_bold;
      font-size: 18px;
      text-decoration: underline;
      display: block;
      margin: 17px 0 0;
      @include breakpoint('xs') {
        font-size: 14px;
      }
    }
  }
}