// * + h2 format to only apply margin-top to headings that aren't a first-child
* {
    + ul, + ol, + dl, + p, + blockquote, + table {
        margin: 15px 0 0;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
}