.top-nav {
  background-color: $primaryColour;
  .top-container {
    font-size: 15px;
    ul {
      list-style-type: none;
      li {
        display: inline-block;
        &.current_page_item {
          background-color: #11a3ff;
        }
        @include breakpoint('sm-xs') {
          display: none;
          &:nth-child(1),
          &:nth-child(2) {
            display: inline-block;
          }
        }
      }
    }
    a {
      color: $baseWhite;
      display: inline-block;
      font-family: $additionalFont_bold;
      text-transform: uppercase;
      padding: 3px 15px;
      &.active {
        background-color: #11a3ff;
      }
    }
  }
}
.header-nav {
  background-image: linear-gradient(to right, #129eff, #00b1ff, #00c3ff, #00d3ff, #04e2fe);
  @include breakpoint('sm-xs') {
    padding: 15px 0;
  }
  .header-container {
    line-height: 1;
    @include flexbox();
    @include align(center);
    @include justify(space-between);
    .menu-side {
      @include flexbox();
      @include align(center);
    }
    .logo {
      max-width: 180px;
      min-width: 126px;
      padding: 8px 0 2px;
      @include breakpoint('sm-xs') {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      img {
        max-width: 100%;
      }
    }
    .main-nav{
      padding: 0 0 0 150px;
      @include flexbox();
      @include align(center);
      @include breakpoint('md') {
        padding: 0 0 0 15px;
      }
      @include breakpoint('sm-xs') {
        display: none;
      }
      ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          padding: 0 37px;
          position: relative;
          @include breakpoint('md') {
            padding: 0 10px;
          }
          a {
            color: $baseWhite;
            font-size: 20px;
            &.active {
              font-family: $additionalFont_bold;
            }
            @include breakpoint('md') {
              font-size: 18px;
            }
          }
        }
      }
    }
    .head-state {
      position: relative;
      @include flexbox();
      @include alignItems(center);
      .dropdown-toggle {
        color: #34455f;
        font-size: 17px;
        font-family: $additionalFont_Semibold;
        min-width: 160px;
        @include breakpoint('sm-xs') {
          text-align: right;
        }
        .state-name {
          @include breakpoint('sm-xs') {
            display: none;
          }
        }
        .flag-container {
          @include breakpoint('sm-xs') {
            margin-right: 0;
          }
        }
      }
      .flag-container {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        margin-right: 10px;
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: inline-block;
        }
      }
      .dropdown-menu {
        @include breakpoint('sm-xs') {
          right: 0;
        }
        &:after {
          display: inline-block;
          width: 0;
          height: 0;
          vertical-align: middle;
          content: "";
          border-bottom: 7px solid $baseWhite;
          border-right: 7px solid transparent;
          border-top: 0;
          border-left: 7px solid transparent;
          @include anim(opacity .15s linear);
          position: absolute;
          top: -7px;
          left: 10px;
          @include breakpoint('sm-xs') {
            right: 10px;
            left: auto;
          }
        }
        .drop-label {
          color: #aaaaaa;
          font-family: $additionalFont_Semibold;
          font-size: 12px;
          text-transform: uppercase;
          padding: 0 6px 7px;
        }
        .flag-container {
          height: 22px;
          width: 22px;
          padding: 3px;
        }
      }
    }
    .s-form {
      .dropdown-menu {
        &:after {
          left: 20px;
        }
      }
    }
  }
  /*mobile header btn*/
  .header_btn{
    position: relative;
    span{
      height: 5px;
      position: absolute;
    }
  }
  .bit-1{
    margin-top: -12px;
    width: 30px;
    margin-left: -14px;
    &:before{
      left: 0;
      transform-origin: left bottom;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:before,
    &:after{
      content: '';
      position: absolute;
      height: 100%;
      width: 50%;
      background: $baseWhite;
      top: 0;
      transition: transform .1s, top .1s;
    }
    &:after{
      right: 0;
      transform-origin: right bottom;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .bit-2 {
    left: 50%;
    margin-left: -14px;
    margin-top: -1px;
    text-align: center;
    &:before{
      content: '';
      width: 30px;
      height: 100%;
      background: $baseWhite;
      display: inline-block;
      vertical-align: top;
      transition: width .1s;
      transition-delay: 0.1s;
      border-radius: 10px;
    }
  }
  .bit-3{
    width: 30px;
    margin-left: -14px;
    margin-top: 10px;
    &:before{
      left: 0;
      transform-origin: left top;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:before,
    &:after{
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      background: $baseWhite;
      transition: transform .1s, bottom .1s;
      bottom: 0;
    }
    &:after{
      right: 0;
      transform-origin: right top;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .opened{
    .bit-1{
      width: 32px;
      &:before{
        width: 23px;
        transform: rotate(45deg);
        transition-delay: 0.2s;
      }
      &:before,
      &:after {
        top: -4px;
        background: $baseWhite;
      }
      &:after{
        width: 23px;
        transition-delay: 0.2s;
        transform: rotate(-45deg);
      }
    }
    .bit-2{
      margin-left: -3px;
      &:before{
        width: 0;
        transition-delay: 0;
      }
    }
    .bit-3{
      width: 32px;
      &:before{
        transform: rotate(-45deg);
      }
      &:before,
      &:after {
        width: 23px;
        transition-delay: 0.2s;
        bottom: -4px;
        background: $baseWhite;
      }
      &:after {
        transform: rotate(45deg);
      }
    }
    &.result-open {
      .bit-1,
      .bit-3 {
        &:before,
        &:after {
          background: $primaryColour;
        }
      }
    }
  }
  .navbar-toggler{
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 10;
    display: none;
    @include breakpoint('sm-xs') {
      display: block;
    }
  }
  .navbar-toggler:focus{
    outline: none;
  }
  .custom_navbar{
    display: inline-block;
  }
  /*END mobile header btn*/
}

//mobile menu
.mob-nav {
  background-color: $primaryColour;
  padding: 90px 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  right: 0;
  @include anim(all 0.5s);
  z-index: 9;
  display: none;
  &.menu-open {
    left: 0;
  }
  @include breakpoint('sm-xs') {
    display: block;
  }
  @include breakpoint('xs-sml') {
    padding: 75px 15px;
  }
  .logo {
    text-align: center;
  }
  .form {
    margin: 32px 0 0;
    position: relative;
    input {
      border: 0;
      border-radius: 5px;
      color: $baseWhite;
      padding: 10px 10px 10px 38px;
      background-color: rgba($baseWhite, 0.1);
      font-size: 20px;
      font-family: $additionalFont_Semibold;
      width: 100%;
      @include placeholder{
        color: $baseWhite;
        font-family: $additionalFont_Semibold;
        font-style:italic;
        font-size: 20px;
      }
    }
    .submit {
      position: absolute;
      top: 9px;
      left: 8px;
      .s-ico {
        height: 15px;
        width: 15px;
        fill: #fff;
      }
    }
    &.active {
      input {
        background-color: #fff;
        box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
        color: #494949;
        @include placeholder{
          color: #494949;
        }
      }
      .submit {
        .s-ico {
          fill: #2759a4;
        }
      }
    }
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      padding: 12px 0;
      margin: 0 -30px 7px;
      padding-left: 20%;
      right: 0;
      @include breakpoint($max: 575px) {
        padding-left: 60px;
      }
      @include breakpoint('xs-sml') {
        margin: 0 -15px 7px;
        padding: 5px 0 5px 60px;
      }
      &.show {
        min-height: 178px;
        @include flexbox();
        @include flex-wrap(wrap); 
        @include alignItems(center);
      }
      &:after {
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        border-top: 7px solid $baseWhite;
        border-right: 7px solid transparent;
        border-bottom: 0;
        border-left: 7px solid transparent;
        @include anim(opacity .15s linear);
        position: absolute;
        bottom: -7px;
        left: 44px;
        @include breakpoint('xs-sml') {
          left: 28px;
        }
      }
      .drop-label {
        color: #aaaaaa;
        font-family: $additionalFont_Semibold;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 6px 0;
      }
      .dropdown-item {
        font-size: 16px;
        padding: 5px 6px;
        display: none;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          display: block;
        }
      }
      .img-container {
        display: inline-block;
        margin: 0 5px 0 0;
        max-width: 60px;
        img {
          vertical-align: middle;
          vertical-align: middle;
          height: 40px;
          object-fit: cover;
        }
      }
    }
  }
  .main-menu {
    list-style-type: none;
    border-radius: 5px;
    color: $baseWhite;
    padding: 0;
    background-color: rgba($baseWhite, 0.1);
    font-size: 20px;
    font-family: $additionalFont_Semibold;
    margin: 7px 0 0;
    width: 100%;
    li {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 21px;
        width: 21px;
        position: absolute;
        top: 9px;
        left: 10px;
      }
      &.i-home {
        &:before {
          background-image: url("../img/i-home.png");
        }
      }
      &.i-slots {
        &:before {
          background-image: url("../img/i-slots.png");
        }
      }
      &.i-roulette {
        &:before {
          background-image: url("../img/i-roulette.png");
        }
      }
      &.i-blackjack {
        &:before {
          background-image: url("../img/i-blackjack.png");
        }
      }
      &.i-baccarat {
        &:before {
          background-image: url("../img/i-baccarat.png");
        }
      }
      &.i-poker {
        &:before {
          background-image: url("../img/i-poker.png");
        }
      }
      &.i-craps {
        &:before {
          background-image: url("../img/i-craps.png");
        }
      }
      &.i-vpocker {
        &:before {
          background-image: url("../img/i-vpocker.png");
        }
      }
      &:after {
        content: '';
        background-color: $baseWhite;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      a {
        color: $baseWhite;
        display: block;
        padding: 4px 10px 4px 43px;
        &:hover {
          color: rgba($baseWhite, 0.5);
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  .state {
    background-color: rgba($baseWhite, 0.1);
    border-radius: 5px;
    color: $baseWhite;
    font-size: 20px;
    font-family: $additionalFont_Semibold;
    padding: 6px 12px;
    margin: 7px 0 0;
    width: 100%;
    @include flexbox();
    @include alignItems(center);
    @include justify(space-between);
    .state-name {
      position: relative;
      line-height: 1;
      &:after {
        content: '';
        border-left: 9px solid $baseWhite;;
        border-right: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        display: inline-block;
        margin-left: 24px;
      }
    }
  }
}

.s-form {
  position: relative;
  margin: 0 29px 0 0;
  max-width: 210px;
  @include breakpoint('md') {
    margin: 0 15px 0 0;
    max-width: 160px;
  }
  @include breakpoint('sm-xs') {
    display: none;
  }
  .search{
    background: transparent;
    border-radius: 3px;
    border: 0;
    padding: 13px 5px 13px 50px;
    max-width: 100%;
    @include placeholder{
      color: $primaryColourDark;
    }
    @include breakpoint('md') {
      padding: 10px 5px 10px 35px;
    }
    &:focus {
      background-color: #fff;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
    }
  }
  .search-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    @include breakpoint('md') {
      top: 10px;
      left: 2px;
    }
    .s-ico {
      height: 30px;
      width: 30px;
      @include breakpoint('md') {
        height: 20px;
        width: 20px;
      }
    }
  }
  .dropdown-menu {
    width: 100%;
    margin: 6px 0 0;
    min-width: 190px;
    .dropdown-item {
      font-size: 16px;
      padding: 5px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .img-container {
    display: inline-block;
    margin: 0 5px 0 0;
    max-width: 60px;
    img {
      vertical-align: middle;
      height: 40px;
      object-fit: cover;
    }
  }
  &.active {
    .search  {
      background-color: #fff;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
    }
  }
}