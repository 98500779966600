.filters-widget {
  padding: 70px 0 70px;
  background-color: $widgetBackgrounGray;
  .filters-row{
    margin-bottom: 10px;
  }
  .main-filter {
    @include breakpoint('sm-xs') {
      @include flexOrder(2);
    }
  }
  .display-filter {
    @include breakpoint('sm-xs') {
      margin: 0 0 25px;
      @include flexOrder(1);
    }
  }
  .main-column {
    background-color: $baseWhite;
    margin-bottom: 10px;
    @include flexbox();
    @include justify(space-between);
    @include breakpoint('sm-xs') {
      @include flex-wrap(wrap);
    }
    .l-col {
      background-color: #e0e9f0;
      text-align: center;
      padding: 22px 10px;
      border-top: 2px solid #34455f;
      border-bottom: 2px solid #34455f;
      border-left: 2px solid #34455f;
      flex: 20%;
      width: 20%;
      @include breakpoint('sm-xs') {
        flex: 100%;
        width: 100%;
        border-bottom: 0;
        border-right: 2px solid #34455f;
        @include flexbox();
        @include flex-wrap(wrap);
      }
      .img-holder {
        @include breakpoint('sm-xs') {
          width: 100%;
        }
      }
      .text-holder {
        @include breakpoint('sm-xs') {
          width: 50%;
        }
        &+.text-holder {
          margin: 12px 0 0;
          @include breakpoint('sm-xs') {
            margin: 0;
          }
        }
      }
      .price {
        color: #de2a28;
        font-size: 35px;
        font-family: $additionalFont_bold;
        letter-spacing: -1.5px;
        display: block;
        line-height: 0.9;
        padding: 13px 0 0;
      }
      .bonus {
        color: #34455f;
        font-size: 20px;
        letter-spacing: -1px;
        display: block;
        line-height: 1;
      }
      .deposit {
        color: #34455f;
        font-size: 15px;
        letter-spacing: -0.75px;
        display: block;
        line-height: 1;
      }
    }
    .m-col {
      padding: 15px 47px 25px;
      border-top: 2px solid #34455f;
      border-bottom: 2px solid #34455f;
      flex: 50%;
      width: 50%;
      @include breakpoint('sm-xs') {
        border-top: 0;
        border-bottom: 0;
        border-left: 2px solid #34455f;
        border-right: 2px solid #34455f;
        flex: 100%;
        width: 100%;
        text-align: center;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
      }
      .title {
        color: #1493ff;
        font-family: $additionalFont_bold;
        font-size: 45px;
        text-transform: uppercase;
        letter-spacing: -2.25px;
        @include breakpoint('sm-xs') {
          width: 100%;
          @include flexOrder(1);
        }
        @include breakpoint('xs') {
          font-size: 32px;
        }
      }
      .list {
        color: #000000;
        font-size: 18px;
        letter-spacing: -0.75px;
        max-width: 320px;
        margin: 11px 0 0;
        list-style-type: none;
        flex-direction: column;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
        @include breakpoint('sm-xs') {
          margin: 11px auto 0;
          text-align: left;
          width: 100%;
          @include flexOrder(3);
        }
        li {
          position: relative;
          margin: 0;
          padding: 0 0 0 23px;
          line-height: 1.2;
          letter-spacing: -1.2px;
          font-family: $additionalFont_Semibold;
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            background-color: #34455f;
            height: 4px;
          }
          &:before {
            transform: rotate(45deg) translateX(-1px);
            width: 10px;
          }
          &:after {
            transform: rotate(-45deg) translateX(5px);
            width: 15px;
          }
          &+li {
            margin-top: 15px;
          }
        }
      }
      .rating {
        margin: 15px 0 0 25px;
        @include flexbox();
        @include align(center);
        @include breakpoint('sm-xs') {
          margin: 0;
          width: 100%;
          @include justify(center);
          @include flexOrder(2);
        }
      }
      .rating-title {
        color: #de2a28;
        font-family: $additionalFont_Semibold;
        font-size: 18px;
        text-decoration: underline;
        letter-spacing: -0.77px;
        display: inline-block;
        margin: 0 0 0 25px;
      }
      .btn-col {
        margin: 15px 0 0 0;
        @include breakpoint('sm-xs') {
          @include flexOrder(4);
        }
        .btn {
          display: inline-block;
          text-transform: uppercase;
          &+.btn {
            @include breakpoint('xl') {
              margin: 0 0 0 30px;
            }
            @include breakpoint($max: 1530px) {
              margin: 15px 0 0;
            }
            @include breakpoint('sm-xs') {
              margin: 0 0 0 30px;
            }
            @include breakpoint($max: 575px) {
              display: block;
              margin: 15px 0 0;
            }
          }
          &.green-btn {
            font-size: 20px;
            padding: 9px 37px;
            @include breakpoint('xs') {
              font-size: 16px;
              padding: 6px 33px;
            }
          }
          &.blue-gr {
            font-size: 20px;
            padding: 9px 30px;
            @include breakpoint('xs') {
              font-size: 16px;
              padding: 6px 49px;
            }
          }
        }
      }
    }
    .r-col {
      background-color: #34455f;
      color: $baseWhite;
      flex: 30%;
      width: 30%;
      padding: 22px 10px;
      .title {
        color: $baseWhite;
        font-family: $additionalFont_bold;
        font-size: 30px;
        letter-spacing: -1.5px;
        text-align: center;
        @include breakpoint('xs') {
          font-size: 24px;
          letter-spacing: -0.5px;
        }
      }
      .list {
        list-style-type: none;
        padding: 28px 0 0;
        margin: 0;
        width: 100%;
        @include flexbox();
        @include align(center);
        @include flex-wrap(wrap);
        li {
          width: 50%;
          margin-bottom: 10px;
          display: inline-block;
          color: #c2c2c2;
          font-size: 14px;
          position: relative;
          line-height: 1.2;
          letter-spacing: -0.75px;
          padding: 0 0 0 30px;
          @include flexbox();
          @include align(center);
          @include breakpoint($max: 1530px) {
            width: 100%;
          }
          @include breakpoint('sm') {
            width: 33.33%;
          }
          @include breakpoint('xs') {
            width: 50%;
          }
          &.active {
            color: $baseWhite;
            font-family: $additionalFont_Semibold;
            .i-name {
              position: relative;
              &:before,
              &:after {
                content: '';
                position: absolute;
                background-color: $baseWhite;
                height: 2px;
              }
              &:before {
                transform: rotate(45deg) translateX(-1px);
                width: 6px;
                top: 9px;
                left: -12px;
              }
              &:after {
                transform: rotate(-45deg) translateX(5px);
                width: 9px;
                left: -13px;
                top: 10px;
              }
            }
          }
          .i-ico {
            display: inline-block;
            margin: 0 19px 0 0;
            text-align: center;
            width: 20px;
          }
        }
      }
    }
  }
  .sidebar-column {
    background-color: $baseWhite;
    .title {
      background-color: #34455f;
      color: $baseWhite;
      padding: 19px 15px 19px;
      font-size: 30px;
      font-family: $additionalFont_regular;
      letter-spacing: -1.65px;
      text-align: center;
      @include breakpoint('xs') {
        padding: 15px 15px 15px;
        font-size: 18px;
        letter-spacing: 0;
      }
    }
    .checkbox-title {
      background-color: #299cff;
      border: 1px solid #34455f;
      color: $baseWhite;
      font-size: 22px;
      text-align: left;
      padding: 15px 30px;
      position: relative;
      width: 100%;
      @include breakpoint('xs') {
        font-size: 18px;
        padding: 8px 28px;
      }
      &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        vertical-align: middle;
        content: "";
        border-top: 12px solid #34455f;;
        border-right: 12px solid transparent;
        border-bottom: 0;
        border-left: 12px solid transparent;
        position: absolute;
        right: 40px;
        top: calc(50% - 6px);
        @include anim(opacity .15s linear);
        transform: rotate( 180deg );
        @include breakpoint('xs') {
          border-top: 8px solid #34455f;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          top: calc(50% - 2px);
          right: 27px;
        }
      }
      &.collapsed {
        &:after {
          transform: rotate( 0 );
        }
      }
    }
    .check-list {
      padding: 12px 10px 15px;
      @include breakpoint('xs') {
        padding: 12px 20px 15px;
      }
      & > form {
        @include flexbox();
        @include flex-wrap(wrap);
      }
    }
    .form-group {
      display: inline-block;
      padding: 0 7px;
      width: 50%;
      @include breakpoint($max: 1530px) {
        width: 100%;
      }
      @include breakpoint('sm-xs') {
        width: 50%;
      }
      label {
        font-family: $additionalFont_light;
      }
    }
    .btn-holder {
      background-color: #34455f;
      text-align: center;
      padding: 22px 10px;
      @include breakpoint('xs') {
        padding: 12px 10px;
      }
      .btn {
        text-transform: uppercase;
      }
    }
  }
}
