.casino-widget {
  padding: 70px 0 70px;
  background-color: $widgetBackgrounGray;
  @include breakpoint('xs') {
    padding: 20px 0 35px;
  }
  .text-container {
    @include flexbox();
    @include justify(space-between);
    margin: 0 0 40px;
    .title {
      color: $primaryColour;
      font-size: 54px;
      font-family: $additionalFont_bold;
      font-style: italic;
      text-transform: uppercase;
      &.underline {
        position: relative;
        &:after {
          content: '';
          display: block;
          margin: 3px 0 0;
          width: 100%;
          height: 4px;
          background-color: rgba(99, 99, 99, 0.44);
        }
      }
    }
    .text {
      color: #494949;
      font-family: $additionalFont_bold;
      font-size: 15px;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-align: center;
    }
  }

  .items-container {
    .item {
      @include flexbox();
      min-height: 202px;
      @include breakpoint('sm-xs') {
        @include flex-wrap(wrap);
      }
      &+.item {
        margin: 24px 0 0;
      }
      &.dark-item {
        .text-holder {
          background-color: $primaryColourDark;
          @include breakpoint('sm-xs') {
            background-color: #e0e9f0;
          }
        }
        .free-col {
          color: $baseWhite;
          font-family: $additionalFont_bold;
          @include breakpoint('sm-xs') {
            color: $primaryColourDark;
          }
          .price {
            color: $baseWhite;
            @include breakpoint('sm-xs') {
              color: $primaryColourDark;
            }
          }
        }
        .list-col {
          color: $baseWhite;
          @include breakpoint('sm-xs') {
            background-color: $primaryColourDark;
          }
          li {
            &:before,
            &:after {
              background-color: $baseWhite;
            }
          }
        }
        .price-col {
          color: $baseWhite;
          @include breakpoint('sm-xs') {
            color: $primaryColourDark;
          }
          .percent {
            color: $baseWhite;
            @include breakpoint('sm-xs') {
              color: $primaryColourDark;
            }
          }
          .price {
            color: #1493ff;
          }
        }
        .rating-col {
          .rest {
            color: $baseWhite;
            @include breakpoint('sm-xs') {
              color: $primaryColourDark;
            }
          }
        }
        .btn-col {
          @include breakpoint('sm-xs') {
            background-color: $primaryColourDark;
          }
          .rating-title {
            color: $baseWhite;
          }
        }
      }
      &.light-item {
        .text-holder {
          background-color: #c4ecff;
          @include breakpoint('sm-xs') {
            background-color: #e0e9f0;
          }
        }
        .list-col {
          color: #000000;
          @include breakpoint('sm-xs') {
            background-color: #c4ecff;
          }
          li {
            &:before,
            &:after {
              background-color: #000000;
            }
          }
        }
        .price-col {
          color: $textDark;
          .percent {
            color: $textDark;
          }
          .price {
            color: #1493ff;
          }
        }
        .btn-col {
          @include breakpoint('sm-xs') {
            background-color: #c4ecff;
          }
        }
      }
      .logo-holder {
        @include flexbox();
        @include align(center);
        @include breakpoint('sm-xs') {
          width: 100%;
        }
        @include breakpoint('sm-xs') {
          display: none;
        }

        .count-col {
          background-color: $primaryColourDark;
          color: $baseWhite;
          font-family: $additionalFont_bold;
          font-size: 80px;
          padding: 0 0 0 14px;
          height: 100%;
          @include flexbox();
          @include align(center);
          @include breakpoint('lg') {
            font-size: 75px;
            padding: 0 0 0 7px;
          }
          @include breakpoint('xl') {
            font-size: 80px;
            padding: 0 0 0 14px;
          }
          @include breakpoint('md') {
            display: none;
          }
          .count {
            margin-right: -5px;
            text-align: right;
            width: 50px;
          }
        }
        .img-holder {
          background-color: #e0e9f0;
          position: relative;
          padding: 0 24px;
          height: 100%;
          width: 160px;
          @include flexbox();
          @include align(center);
          @include justify(center);
          @include breakpoint('md') {
            width: 100px;
            padding: 0 10px;
          }
          .img-container {
            border-radius: 50%;
            background-color: $baseWhite;
            height: 100px;
            width: 100px;
            overflow: hidden;
            @include flexbox();
            @include align(center);
            @include justify(center);
            @include breakpoint('md') {
              height: 80px;
              width: 80px;
            }
          }
          .img-item {
            padding: 10px;
            max-width: 90px;
            @include breakpoint('md') {
              max-width: 70px;
            }
          }
        }
        .first-img-holder {
          background-image: url('../img/c_item-bg.png');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          @include breakpoint('md') {
            background-size: 80%;
          }
          .img-item {
            background-color: transparent;
            margin: 56% 0 0 25%;
            max-width: 60px;
            max-height: 60px;
            padding: 0;
            @include breakpoint('md') {
              max-width: 45px;
              max-height: 45px;
            }
            @include breakpoint('sm') {
              max-width: 55px;
              margin: 90% 0 0 34%;
              border-radius: 0;
            }
            @include breakpoint('xs') {
              max-width: 40px;
              margin: 87% 0 0 35%;
              border-radius: 0;
            }
            @include breakpoint('xs-sml') {
              max-width: 33px;
            }
          }
        }
        .triangle {
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-bottom-width: 15px;
          border-top-width: 15px;
          border-left: 15px solid #e0e9f0;
          position: absolute;
          right: -15px;
          top: calc( 50% - 7.5px);
        }
      }
      .mob-logo-holder {
        display: none;
        @include breakpoint('sm-xs') {
          padding: 10px;
          min-height: 130px;
          @include flexbox();
          @include align(center);
        }

        .img-container {
          @include breakpoint('sm-xs') {
            border-radius: 50%;
            background-color: $baseWhite;
            height: 100px;
            width: 100px;
            overflow: hidden;
            @include flexbox();
            @include align(center);
            @include justify(center);
            @include breakpoint('xs') {
              height: 80px;
              width: 80px;
            }
          }
          @include breakpoint($max: 350px) {
            height: 50px;
            width: 50px;
          }
        }

        .triangle {
          @include breakpoint('sm-xs') {
            display: none;
          }
        }
        .img-holder {
          .img-item {
            // border-radius: 50%;
            // background-color: $baseWhite;
            padding: 15px;
            @include breakpoint('xs') {
              max-height: 80px;
            }
            @include breakpoint('xs-sml') {
              // max-height: 100px;
              // max-width: 65px;
              padding: 10px;
            }
          }
        }
        .first-img-holder {
          background-image: url('../img/c_item-bg.png');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          @include breakpoint('sm') {
            min-height: 170px;
            min-width: 100px;
          }
          @include breakpoint('sm-xs') {
            background-size: contain;
          }
          @include breakpoint('xs') {
            min-height: 120px;
            min-width: 75px;
          }
          @include breakpoint('xs-sml') {
            min-height: 100px;
            min-width: 62px;
          }
          .img-item {
            background-color: transparent;
            @include breakpoint('sm') {
              max-width: 70px;
              margin: 83% 0 0 30%;
              padding: 10px;
            }
            @include breakpoint('xs') {
              max-width: 55px;
              margin: 76% 0 0 27%;
              padding: 10px;
            }
            @include breakpoint('xs-sml') {
              max-width: 45px;
              padding: 7px;
            }
          }
        }
      }
      .text-holder {
        @include flexbox();
        @include justify(space-between);
        flex: 1 1 0;
        margin: 0 0 0 15px;
        background: $baseWhite;
        @include breakpoint('lg') {
          padding: 0 15px 0 15px;
        }
        @include breakpoint('xl') {
          padding: 0 35px 0 35px;
        }
        @include breakpoint('md') {
          padding: 0 10px 0 10px;
        }
        @include breakpoint('sm-xs') {
          margin: 0;
          position: relative;
          @include flex-wrap(wrap);
          background-color: #e0e9f0;
        }
      }
      .free-col {
        color: $textColour;
        font-size: 35px;
        letter-spacing: -1.5px;
        text-align: center;
        flex-direction: column;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
        @include breakpoint('lg') {
          font-size: 30px;
        }
        @include breakpoint('xl') {
          font-size: 35px;
        }
        @include breakpoint('md') {
          font-size: 28px;
        }
        @include breakpoint('xs') {
          font-size: 21px;
          max-width: 26%;
        }
        @include breakpoint($max: 350px) {
          font-size: 18px;
        }
        .price {
          color: #de2a28;
        }
        .cupon {
          position: relative;
          display: block;
          background-color: #4587c6;
          border-radius: 5px;
          color: #fff;
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;
          padding: 12px 5px;
          margin-left: 30px;
          @include breakpoint('xs-sml') {
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            padding: 10px 5px;
            margin-left: 20px;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -35px;
            background-image: url('../img/cupon.png');
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            @include breakpoint('xs-sml') {
              background-size: contain;
              height: 33px;
              left: -25px;
            }
          }
        }
      }
      .list-col {
        color: #000000;
        font-size: 18px;
        letter-spacing: -0.75px;
        max-width: 320px;
        margin: 0;
        list-style-type: none;
        flex-direction: column;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
        @include breakpoint('lg') {
          padding: 0 10px;
          max-width: 280px;
        }
        @include breakpoint('xl') {
           max-width: 320px;
           padding: 0;
         }
        @include breakpoint('md') {
          max-width: 250px;
        }
        @include breakpoint('sm-xs') {
          width: 100%;
          max-width: 100%;
          position: relative;
          margin: 25px 0 0;
          padding: 20px 15px 10px;
          background-color: $baseWhite;
          @include align(center);
          @include flexOrder(2);
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -20px;
            height: 20px;
            width: 100%;
            background-color: $widgetBackgrounGray;;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: 35px;
            border-left-width: 35px;
            border-top: 15px solid #e0e9f0;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        li {
          position: relative;
          margin: 0;
          padding: 0 0 0 23px;
          line-height: 1.2;
          letter-spacing: -1.2px;
          font-family: $additionalFont_Semibold;
          @include breakpoint('xs') {
            font-size: 14px;
            letter-spacing: 0;
            padding: 0 0 0 18px;
          }
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            background-color: $primaryColourDark;
            height: 4px;
            @include breakpoint('xs') {
              top: 8px;
            }
          }
          &:before {
            transform: rotate(45deg) translateX(-1px);
            width: 10px;
            @include breakpoint('xs') {
              width: 8px;
            }
          }
          &:after {
            transform: rotate(-45deg) translateX(5px);
            width: 15px;
            @include breakpoint('xs') {
              width: 10px;
            }
          }
          &+li {
            margin-top: 15px;
          }
        }
      }
      .price-col{
        color: $textColour;
        font-size: 35px;
        line-height: 1.2;
        letter-spacing: -1.5px;
        text-align: center;
        flex-direction: column;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
        @include breakpoint('lg') {
          font-size: 30px;
        }
        @include breakpoint('xl') {
          font-size: 35px;
        }
        @include breakpoint('md') {
          font-size: 25px;
        }
        @include breakpoint('xs') {
          text-align: left;
          font-size: 20px;
          width: 130px;
          padding: 10px;
          height: 80px;
        }
        @include breakpoint($max: 350px) {
          font-size: 17px;
          text-align: center;
        }
        .percent {
          color: #de2a28;
        }
        .price {
          color: #de2a28;
        }
      }
      .rating-col {
        text-align: center;
        flex-direction: column;
        padding: 15px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify(center);
        @include breakpoint('xs') {
          text-align: left;
          position: absolute;
          right: 0;
          top: 58px;
          height: 82px;
          width: 130px;
          padding: 10px;
        }
        @include breakpoint($max: 350px) {
          text-align: center;
          padding: 5px;
        }
        .amount {
          color: #1493ff;
          font-size: 35px;
          letter-spacing: -1.5px;
          @include breakpoint('xs') {
            font-size: 20px;
          }
        }
        .rest {
          color: $textColour;
          font-size: 19px;
          letter-spacing: -0.75px;
          @include breakpoint('xs') {
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
        .rating {
          display: block;
        }
      }
      .btn-col {
        flex-direction: column;
        @include flexbox();
        @include align(center);
        @include justify(center);
        @include breakpoint('sm-xs') {
          background-color: $baseWhite;
          padding: 0 15px 10px;
          width: 100%;
          @include flexOrder(3);
        }
        .btn {
          text-transform: uppercase;
          padding: 12px 35px;
          font-size: 22px;
          border-radius: 34px;
          @include breakpoint('lg') {
            padding: 12px 25px;
            font-size: 18px;
          }
          @include breakpoint('xl') {
            padding: 12px 35px;
            font-size: 22px;
          }
          @include breakpoint('md') {
            padding: 12px 25px;
            font-size: 18px;
          }
          .arrow {
            @include breakpoint('md') {
              display: none;
            }
          }
          @include breakpoint('xs') {
            padding: 7px 50px;
            font-size: 15px;
          }
        }
        .rating-title {
          color: #de2a28;
          font-size: 20px;
          line-height: 1.2;
          text-decoration: underline;
          margin: 10px 0 0;
          font-family: $additionalFont_Semibold;
          @include breakpoint('xs') {
            font-size: 16px;
            margin: 5px 0 0;
          }
        }
      }
    }
  }
  .content-title {
    color: $primaryColourDark;
    font-size: 30px;
    font-family: $additionalFont_Semibold;
    margin: 0 0 20px;
  }
  .text-content {
    color: #494949;
    font-size: 18px;
    letter-spacing: 0.36px;
    margin: 0 0 40px;
    @include breakpoint('xs') {
      font-size: 16px;
    }
    h2 {
      color: #34455f;
      font-family: $additionalFont_bold;
      font-size: 35px;
      padding: 25px 0 0;
      @include breakpoint('xs') {
        font-size: 20px;
        padding: 10px 0 0;
      }
    }
   h3 {
      color: $primaryColourDark;
      font-size: 22px;
      margin: 25px 0 15px;
      @include breakpoint('xs') {
        font-size: 17px;
        margin: 10px 0 10px;
      }
    }
    ul {
      padding-left: 20px;
      margin: 15px 0 25px;
      @include breakpoint('xs') {
        margin: 10px 0 15px;
      }
    }
  }
}