.list-categories-widget {
  background-color: $widgetBackgrounGray;
  padding: 62px 0 62px;
  @include breakpoint('xs') {
    padding: 27px 0 27px;
  }
  .categories-row {
    &+.categories-row {
      margin-top: 15px;
    }
  }
  .main-column,
  .sidebar-column {
    position: relative;
  }
  .main-column {
    padding: 0 35px 30px;
    height: 100%;

    flex-direction: column;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify(space-between);

    .btn-holder {
      padding: 20px 0 0;
      @include flexbox();
      @include alignItems(flex-end);
    }

    @include breakpoint('xs') {
      padding: 0 20px 30px;
    }
    h3 {
      color: $primaryColourDark;
      font-size: 22px;
      margin: 25px 0 15px;
      @include breakpoint('xs') {
        font-size: 17px;
        margin: 10px 0 10px;
      }
    }
    p {
      color: $textDark;
      font-size: 20px;
      line-height: 1.2;
      margin: 0 0 25px;
      @include breakpoint('xs') {
        font-size: 15px;
        margin: 0 0 10px;
      }
      &:last-child {
        margin: 0;
      }
    }
    ul {
      padding-left: 20px;
      margin: 0 0 25px;
      @include breakpoint('xs') {
        margin: 0 0 10px;
      }
    }
    .text-in {
      margin: 25px 0 0;
      @include breakpoint('xs') {
        margin: 10px 0 0;
      }
    }
    .collapse {
      margin: 25px 0 0;
      @include breakpoint('xs') {
        margin: 15px 0 0;
      }
      p {
        margin: 0 0 25px;
        &:last-child {
          margin: 0;
        }
        @include breakpoint('xs') {
          margin: 0 0 10px;
        }
      }
    }
  }
  .title {
    color: #34455f;
    font-family: $additionalFont_bold;
    font-size: 35px;
    padding: 25px 0 0;
    @include breakpoint('xs') {
      font-size: 20px;
      padding: 10px 0 0;
    }
  }
  .read-more-link {
    color: #e70021;
    font-family: $additionalFont_Semibold;
    font-size: 17px;
    text-decoration: underline;
    padding: 0;
    .more {
      display: none;
    }
    .less {
      display: block;
    }
    &.collapsed {
      .more {
        display: block;
      }
      .less {
        display: none;
      }
    }
  }
  .read-more-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 20px;
    width: 20px;
    padding: 0;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #34455f;
      @include anim(all 0.3s ease-in-out 0s);
    }
    &:before {
      transform: rotate( 0deg );
    }
    &:after {
      transform: rotate( 90deg );
      display: none;
    }
    &.collapsed {
      &:after{
        display: block;
      }
    }
  }

  .sidebar-column {
    overflow: hidden;
    padding: 0;
    @include breakpoint('sm-xs') {
      margin-top: 15px;
      margin-right: 15px;
      margin-left: 15px;
    }
    .title {
      background-color: $primaryColour;
      color: $baseWhite;
      padding: 19px 15px 19px;
      font-size: 25px;
      @include breakpoint('xs') {
        padding: 13px 10px 13px;
        font-size: 18px;
      }
    }
    .sidebar-inner {
      padding: 0;
      //height: 100%;
      @include flexbox();
      @include align(flex-start);
      @include flex-wrap(wrap);
      .list {
        list-style-type: none;
        margin: 0;
        li {
          display: inline-block;
          color: $textDark;
          font-size: 15px;
          position: relative;
          line-height: 1.2;
          a {
            color: $textDark;
            display: block;
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            filter: grayscale(100%);
            @include anim(all .25s ease);
            &.active {
              -webkit-filter: grayscale(0);
              -moz-filter: grayscale(0);
              filter: grayscale(0);
            }
            @include breakpoint('xs') {
              font-size: 14px;
            }
          }
          .plus-ico,
          .minus-ico {
            position: absolute;
            height: 9px;
            width: 9px;
            left: 0;
            top: 8px;
          }
        }
        &.overview-list {
          li {
            font-family: $additionalFont_light;
          }
        }
        &.games-list {
          padding: 35px 7px 0;
          width: 100%;
          @include flexbox();
          @include align(center);
          @include flex-wrap(wrap);
          @include justify(space-between);
          @include breakpoint('md-xs') {
            padding: 15px 7px 0;
          }
          @include breakpoint('sm-xs') {
            @include justify(flex-start);
          }
          li {
            text-align: center;
            padding: 0 4px 0;
            margin-bottom: 25px;
            width: 20%;
            @include breakpoint('md') {
              width: 50%;
            }
            @include breakpoint('xs-sml') {
              width: 50%;
            }
            .i-ico {
              margin: 17px auto 0;
              min-height: 40px;
              max-width: 40px;
              @include flexbox();
              @include align(center);
              @include flex-wrap(wrap);
              @include justify(center);
            }
          }
        }
        &.promotions-list {
          padding: 40px 25px 0;
          width: 100%;
          @include flexbox();
          @include align(center);
          @include flex-wrap(wrap);
          @include breakpoint('md-xs') {
            padding: 20px 25px 0;
          }
          li {
            width: 50%;
            margin-bottom: 15px;
            padding-right: 10px;
            @include breakpoint('md') {
              width: 100%;
              padding-right: 0;
            }
            @include breakpoint('xs-sml') {
              width: 100%;
              padding-right: 0;
            }
            a {
              @include flexbox();
              @include align(center);
            }
          }
          .i-ico {
            margin-right: 12px;
            max-width: 40px;
          }
        }
        &.platforms-list {
          padding: 40px 25px 0;
          width: 100%;
          @include flexbox();
          @include align(center);
          @include flex-wrap(wrap);
          @include justify(space-around);
          @include breakpoint('md-xs') {
            padding: 20px 25px 0;
          }
          li {
            width: 33.333%;
            text-align: center;
            margin-bottom: 50px;
            @include breakpoint('xs') {
              margin-bottom: 25px;
            }
          }
          .i-ico {
            margin-bottom: 8px;
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify(center);
            @include alignItems(center);
          }
        }
        &.payment-list {
          padding: 40px 25px 0;
          width: 100%;
          @include flexbox();
          @include flex-wrap(wrap);
          @include breakpoint('md-xs') {
            padding: 20px 25px 11px;
          }
          @include breakpoint('sm-xs') {
            max-width: 400px;
            margin: 0 auto;
          }
          li {
            margin-bottom: 9px;
            text-align: center;
            padding: 0 5px;
            width: 25%;

            @include flexbox();
            @include align(center);
            @include justify(center);

            @include breakpoint('md') {
              width: 33.33%;
            }
            @include breakpoint('sm-xs') {
              width: 25%;
            }
            .i-ico {
              display: inline-block;
              max-width: 61px;
            }
          }
        }
        &.support-list {
          padding: 40px 25px 0;
          width: 100%;
          @include breakpoint('md-xs') {
            padding: 20px 25px 0;
          }
          li {
            width: 100%;
            margin-bottom: 40px;
            @include breakpoint('xs') {
              margin-bottom: 30px;
            }
            a {
              @include flexbox();
              @include align(center);
                pointer-events: none;
                .list-title {
                  color: #818181;
                }
              &.active {
                pointer-events: unset;
                .list-title {
                  color: #299cff;
                }
              }
              &:hover {

              }
            }
          }
          .i-ico {
            max-width: 52px;
          }
          .text-holder {
            color: $textDark;
            font-size: 12px;
            margin: 0 0 0 22px;
          }
          .list-title {
            color: #299cff;
            display: block;
            font-size: 14px;
          }
        }
      }
      .half {
        flex: 50%;
        width: 50%;
        height: 100%;
        padding: 25px 10px;
        & > li {
          padding: 0 0 0 15px;
        }
      }
      .border-right {
        border-right: 2px solid $widgetBackgrounGray;
      }
    }
    .video-holder {
      padding: 25px 7px 15px;
      width: 100%;
    }
    .html-place {
      padding: 25px 7px 15px;
      text-align: center;
      width: 100%;
    }
    .verdict-holder {
      padding: 50px 24px 20px;
      width: 100%;
      @include flexbox();
      @include align(center);
      @include breakpoint('md') {
        @include flex-wrap(wrap);
      }
      @include breakpoint('md-xs') {
        padding: 28px 20px 28px;
        @include justify(center);
      }
      .i-ico {
        position: relative;
        @include breakpoint('md') {
          text-align: center;
          width: 100%;
        }
      }
      .rating {
        display: block;
        padding: 40px 0 0 30px;
        @include breakpoint('md') {
          padding: 20px 0 0 0;
          text-align: center;
          width: 100%;
        }
        @include breakpoint('sm-xs') {
          padding: 70px 0 0 40px;
        }
        @include breakpoint('xs') {
          padding: 60px 0 0 40px;
        }
      }
      .digit {
        position: absolute;
        right: 0;
        bottom: 50px;
        @include breakpoint($max: 1530px) {
          right: -20px;
          bottom: 28px;
        }
        @include breakpoint('md') {
          right: 30px;
          bottom: 50px;
        }
        @include breakpoint('sm-xs') {
          right: 0;
          bottom: 50px;
        }
        @include breakpoint($max: 370px) {
          bottom: 10vw;
        }
      }
      .rating-title {
        color: $textColour;
        font-family: $additionalFont_Semibold;
        font-size: 24px;
      }
      .amount {
        color: #1493ff;
        font-size: 46px;
        letter-spacing: -1.5px;
        font-family: $additionalFont_Semibold;
        @include breakpoint($max: 1530px) {
          font-size: 35px;
        }
        @include breakpoint('md-xs') {
          font-size: 46px;
        }
        @include breakpoint($max: 370px) {
          font-size: 10vw;
        }
      }
      .rest {
        color: $textColour;
        font-size: 22px;
        letter-spacing: -0.75px;
        font-family: $additionalFont_Semibold;
        @include breakpoint('xs') {
          font-size: 25px;
          letter-spacing: 1px;
        }
        @include breakpoint($max: 370px) {
          font-size: 5vw;
        }
      }
    }
  }
  .plus-ico{
    display: inline-block;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #60b511;
    }
    &:before {
      transform: rotate( 0deg );
    }
    &:after {
      transform: rotate( 90deg );
    }
  }
  .minus-ico {
    display: inline-block;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #e70021;
    }
    &:before {
      transform: rotate( 0deg );
    }
  }
}