// clearfix
@mixin clearfix() {
  &:before, &:after{ display: table; content: " "; }
  &:after{ clear:both; }
}

// generic anim
@mixin anim($transition) {
	   -moz-transition: $transition;
	     -o-transition: $transition;
	    -ms-transition: $transition;
	-webkit-transition: $transition;transition: $transition;
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
	backface-visibility: hidden;
}

// scale
@mixin scale($x,$y) {
	 @include transform(scale($x,$y));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
	backface-visibility: hidden;
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}
//transition delay origin
@mixin transition-delay ($delay) {
    -moz-transition-delay: $delay;
	     -o-transition-delay: $delay;
	    -ms-transition-delay: $delay;
	-webkit-transition-delay: $delay;
          transition-delay: $delay;
}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin gradient-block {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba($primaryColour, 0.7) 0, rgba($backgroundColour, 0) 100%);
    z-index: 1;
  }
}

@mixin underlined-link {
	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: $primaryColour;
		visibility: hidden;
		transition: all 0.3s ease-in-out 0s;
	}
}

@mixin gradient-dark-block {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
      background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.65) 35%, rgba(125,185,232,0) 100%);
      background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.65) 35%,rgba(125,185,232,0) 100%);
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.65) 35%,rgba(125,185,232,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 );
		z-index: 1;
	}
}

@mixin gradient-reddark-block {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(45deg, rgba($primaryColourDark, 0.57) 0%, rgba($boxBackgroundDark, 0.57) 100%);
		z-index: 1;
	}
}

@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex($value) {
	-webkit-flex: $value;
	-moz-flex: $value;
	-ms-flex: $value;
	flex: $value;
}

@mixin justify($value) {
  -webkit-box-pack: $value;
  -webkit-justify-content: $value;
  -moz-box-pack: $value;
  -ms-flex-pack: $value;
  justify-content: $value;
}

@mixin spaceBetween {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin align($value) {
  -webkit-box-align: $value;
  -moz-box-align: $value;
  -ms-flex-align: $value;
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin flexOrder($value) {
	order: $value;
	-webkit-order: $value;
	-ms-flex-order: $value;
}

@mixin alignContent($value) {
	-webkit-align-content: $value;
	-moz-align-content: $value;
	-ms-align-content: $value;
	align-content: $value;
}


@mixin alignSelf($align: auto) {
	-webkit-align-self: $align;
	-moz-align-self: $align;
	-ms-align-self: $align;
	align-self: $align;
}

@mixin alignItems($value) {
	-webkit-align-items: $value;
	-moz-align-items: $value;
	-ms-align-items: $value;
	align-items: $value;
}




// RESPONSIVE BREAKPOINTS

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    @if $min == xs {
        @media (max-width: 767px) { @content; }                           // Mobile Devices
    }

    @else if $min == xs-lrg {
        @media (min-width: 481px) and (max-width: 767px) { @content; }    // Mobile Large
    }

    @else if $min == xs-sml {
        @media (max-width: 480px) { @content; }                           // Mobile Small
    }

    @else if $min == sm-xs {
        @media (max-width: 991px) { @content; }                           // Mobile & Tablet Devices
    }

    @else if $min == sm {
        @media (min-width: 768px) and (max-width: 991px) { @content; }    // Tablet Devices
    }

    @else if $min == md-xs {
        @media (max-width: 1199px) { @content; }                          // Mobile, Tablet & Desktops
    }

    @else if $min == md-sm {
        @media (min-width: 768px) and (max-width: 1199px) { @content; }   // Tablet Devices & Desktops
    }

    @else if $min == md {
        @media (min-width: 992px) and (max-width: 1199px) { @content; }   // Desktops
    }

    @else if $min == lg {
        @media (min-width: 1200px) { @content; }                          // Widescreen Desktops
    }

    @else if $min == xl {
      @media (min-width: 1530px) { @content; }                          // Widescreen Desktops
    }

    // Otherwise pass a warning to the compiler as to the appropriate options
    @else {
      @warn "The breakpoint mixin supports the following attributes: xs, xs-sml, xs-lrg, sm-xs, sm, md, lg";
    }
  }
  @else if $type == number {
    // Allow for custom parameters for min and max size
    $query: "all" !default;
    @if      $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; } // set both min and max
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; } // set just min
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; } // set just max
    @media #{$query} { @content; }
  }
}


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}



@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
