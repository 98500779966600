.nav-cta-widget {
  background-color: $primaryColour;
  padding: 10px 0;
  @include breakpoint('sm-xs') {
    padding: 13px 0 35px;
  }

  &.position-f {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: -100%;
    @include anim(all 0.3s);
    @include breakpoint('sm-xs') {
      padding: 10px 0 10px;
      top: auto;
      bottom: -100%;
    }

    .cta-container {
      @include breakpoint('sm-xs') {
        @include flex-wrap(nowrap);
      }
    }
    .logo {
      @include breakpoint('sm-xs') {
        flex: 0 0 85px;
        margin-left: 0;
        margin-bottom: 0;
      }
      @include breakpoint('xs') {
        flex: 0 0 60px;
      }
    }
    .text-holder {
      @include breakpoint('sm-xs') {
        margin: 0;
      }
    }
    p {
      @include breakpoint('sm') {
        padding: 0 15px;
      }
      @include breakpoint('xs') {
        text-align: left;
        font-size: 12px;
        margin: 0;
        padding: 0 10px;
      }
    }
    .btn {
      @include breakpoint('sm-xs') {
        flex: 0 0 auto;
        font-size: 11px;
        padding: 6px 25px;
      }
    }
  }
  &.sticky {
    top: 0;
    @include breakpoint('sm-xs') {
      top: auto;
      bottom: 0;
    }
  }
  .cta-container {
    @include flexbox();
    @include align(center);
    @include justify(space-between);
    @include breakpoint('sm-xs') {
      @include flex-wrap(wrap);
      @include justify(center);
    }
  }
  .logo {
    // flex: 0 0 125px;
    @include breakpoint('sm-xs') {
      // flex: 0 0 175px;
      // margin-left: -35px;
      text-align: center;
      margin: 0 0 23px;
    }
    img {
      display: block;
      max-height: 88px;
      @include breakpoint('sm-xs') {
        
      }
    }
  }
  .text-holder {
    @include breakpoint('sm-xs') {
      margin: 0 0 23px;
    }
  }
  p {
    color: $baseWhite;
    font-family: $additionalFont_bold;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    padding: 0 20px;
    line-height: 1.2;
    //max-width: 65%;
    @include breakpoint('lg') {
      font-size: 22px;
    }
    @include breakpoint('sm-xs') {
      text-align: center;
      // margin: 23px 0 29px;
      // max-width: calc(100% - 100px);
      padding: 0 15px;
      max-width: 100%;
      width: 100%;
    }
    @include breakpoint('xs') {
      font-size: 16px;
    }
    @include breakpoint($max: 575px) {
      font-size: 14px;
    }
    &+.btn {
      @include breakpoint('sm-xs') {
        margin: 29px 0 0;
      }
    }
  }
  .btn {
    text-transform: uppercase;
    padding: 12px 55px;
    flex: 0 0 236px;
  }
}