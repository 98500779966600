.info-cta-widget {
  background: url('../img/info-cta.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -20px;
  padding: 60px 0 60px;
  @include breakpoint('xs') {
    padding: 20px 0 20px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 24, 64, 0.2);
    z-index: 1;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .info-cta-container {
    @include flexbox();
    @include align(center);
    @include justify(space-between);
    @include breakpoint('sm-xs') {
      @include flex-wrap(wrap);
    }
  }
  .img-holder {
    position: relative;
    @include breakpoint('sm-xs') {
      text-align: center;
      width: 100%;
      @include flexOrder(2);
    }
    &.devicetype-laptop {
      .game_image{
        @include breakpoint($max: 560px) {
          max-width: 40vw;
          top: 4vw;
        }
      }
    }
    &.devicetype-tablet {
      transform: rotate(-15deg);
      .game_image {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 75%;
        @include breakpoint('sm-xs') {
          max-width: 170px;
        }
      }
    }
    .game_image {
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: 0 auto;
    }
  }
  .text-holder {
    width: 57%;
    text-align: right;
    @include breakpoint('sm-xs') {
      text-align: center;
      width: 100%;
      @include flexOrder(1);
    }
    .title {
      color: #fefefe;
      font-family: $additionalFont_bold;
      font-size: 49px;
      font-style: italic;
      letter-spacing: 1.75px;
      @include breakpoint('xs') {
        font-size: 24px;
        margin: 0 0 10px;
      }
    }
    .text {
      color: #fefefe;
      font-size: 20px;
      font-style: italic;
      letter-spacing: 0.9px;
      line-height: 1.2;
      @include breakpoint('xs') {
        font-size: 13px;
      }
    }
    .link {
      color: #299cff;
      font-family: $additionalFont_bold;
      letter-spacing: 1px;
      text-decoration: underline;
      @include breakpoint('sm-xs') {
        display: block;
      }
      @include breakpoint('xs') {
        font-size: 16px;
      }
    }
    .btn {
      display: inline-block;
      margin: 25px 0 0;
      padding: 10px 38px;
      font-size: 27px;
      border-radius: 34px;
      @include breakpoint('sm-xs') {
        margin: 25px 0 25px;
      }
      @include breakpoint('xs') {
        font-size: 18px;
        padding: 6px 25px;
      }
    }
  }
}