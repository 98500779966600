/* Theme specific variables */
/* SASS guidelines

.element{
	// Normal element styles here e.g. position: relative etc.

	@include breakpoint for this element

	.inner-elements{
		// Styles for inner elements here
	}

	&.modifier {
		// Styles for modifier of element here
	}
}
*/
/*!
 * Bootstrap Grid v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1160px; } }
  @media (min-width: 1530px) {
    .container {
      max-width: 1500px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm,
.col-xsm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-xsm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xsm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xsm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xsm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xsm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xsm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xsm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xsm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xsm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xsm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xsm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xsm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xsm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xsm-first {
    order: -1; }
  .order-xsm-last {
    order: 13; }
  .order-xsm-0 {
    order: 0; }
  .order-xsm-1 {
    order: 1; }
  .order-xsm-2 {
    order: 2; }
  .order-xsm-3 {
    order: 3; }
  .order-xsm-4 {
    order: 4; }
  .order-xsm-5 {
    order: 5; }
  .order-xsm-6 {
    order: 6; }
  .order-xsm-7 {
    order: 7; }
  .order-xsm-8 {
    order: 8; }
  .order-xsm-9 {
    order: 9; }
  .order-xsm-10 {
    order: 10; }
  .order-xsm-11 {
    order: 11; }
  .order-xsm-12 {
    order: 12; }
  .offset-xsm-0 {
    margin-left: 0; }
  .offset-xsm-1 {
    margin-left: 8.33333%; }
  .offset-xsm-2 {
    margin-left: 16.66667%; }
  .offset-xsm-3 {
    margin-left: 25%; }
  .offset-xsm-4 {
    margin-left: 33.33333%; }
  .offset-xsm-5 {
    margin-left: 41.66667%; }
  .offset-xsm-6 {
    margin-left: 50%; }
  .offset-xsm-7 {
    margin-left: 58.33333%; }
  .offset-xsm-8 {
    margin-left: 66.66667%; }
  .offset-xsm-9 {
    margin-left: 75%; }
  .offset-xsm-10 {
    margin-left: 83.33333%; }
  .offset-xsm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1530px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-xsm-none {
    display: none !important; }
  .d-xsm-inline {
    display: inline !important; }
  .d-xsm-inline-block {
    display: inline-block !important; }
  .d-xsm-block {
    display: block !important; }
  .d-xsm-table {
    display: table !important; }
  .d-xsm-table-row {
    display: table-row !important; }
  .d-xsm-table-cell {
    display: table-cell !important; }
  .d-xsm-flex {
    display: flex !important; }
  .d-xsm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1530px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-xsm-row {
    flex-direction: row !important; }
  .flex-xsm-column {
    flex-direction: column !important; }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xsm-wrap {
    flex-wrap: wrap !important; }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xsm-fill {
    flex: 1 1 auto !important; }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important; }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important; }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xsm-start {
    justify-content: flex-start !important; }
  .justify-content-xsm-end {
    justify-content: flex-end !important; }
  .justify-content-xsm-center {
    justify-content: center !important; }
  .justify-content-xsm-between {
    justify-content: space-between !important; }
  .justify-content-xsm-around {
    justify-content: space-around !important; }
  .align-items-xsm-start {
    align-items: flex-start !important; }
  .align-items-xsm-end {
    align-items: flex-end !important; }
  .align-items-xsm-center {
    align-items: center !important; }
  .align-items-xsm-baseline {
    align-items: baseline !important; }
  .align-items-xsm-stretch {
    align-items: stretch !important; }
  .align-content-xsm-start {
    align-content: flex-start !important; }
  .align-content-xsm-end {
    align-content: flex-end !important; }
  .align-content-xsm-center {
    align-content: center !important; }
  .align-content-xsm-between {
    align-content: space-between !important; }
  .align-content-xsm-around {
    align-content: space-around !important; }
  .align-content-xsm-stretch {
    align-content: stretch !important; }
  .align-self-xsm-auto {
    align-self: auto !important; }
  .align-self-xsm-start {
    align-self: flex-start !important; }
  .align-self-xsm-end {
    align-self: flex-end !important; }
  .align-self-xsm-center {
    align-self: center !important; }
  .align-self-xsm-baseline {
    align-self: baseline !important; }
  .align-self-xsm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1530px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-xsm-0 {
    margin: 0 !important; }
  .mt-xsm-0,
  .my-xsm-0 {
    margin-top: 0 !important; }
  .mr-xsm-0,
  .mx-xsm-0 {
    margin-right: 0 !important; }
  .mb-xsm-0,
  .my-xsm-0 {
    margin-bottom: 0 !important; }
  .ml-xsm-0,
  .mx-xsm-0 {
    margin-left: 0 !important; }
  .m-xsm-1 {
    margin: 0.25rem !important; }
  .mt-xsm-1,
  .my-xsm-1 {
    margin-top: 0.25rem !important; }
  .mr-xsm-1,
  .mx-xsm-1 {
    margin-right: 0.25rem !important; }
  .mb-xsm-1,
  .my-xsm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xsm-1,
  .mx-xsm-1 {
    margin-left: 0.25rem !important; }
  .m-xsm-2 {
    margin: 0.5rem !important; }
  .mt-xsm-2,
  .my-xsm-2 {
    margin-top: 0.5rem !important; }
  .mr-xsm-2,
  .mx-xsm-2 {
    margin-right: 0.5rem !important; }
  .mb-xsm-2,
  .my-xsm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xsm-2,
  .mx-xsm-2 {
    margin-left: 0.5rem !important; }
  .m-xsm-3 {
    margin: 1rem !important; }
  .mt-xsm-3,
  .my-xsm-3 {
    margin-top: 1rem !important; }
  .mr-xsm-3,
  .mx-xsm-3 {
    margin-right: 1rem !important; }
  .mb-xsm-3,
  .my-xsm-3 {
    margin-bottom: 1rem !important; }
  .ml-xsm-3,
  .mx-xsm-3 {
    margin-left: 1rem !important; }
  .m-xsm-4 {
    margin: 1.5rem !important; }
  .mt-xsm-4,
  .my-xsm-4 {
    margin-top: 1.5rem !important; }
  .mr-xsm-4,
  .mx-xsm-4 {
    margin-right: 1.5rem !important; }
  .mb-xsm-4,
  .my-xsm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xsm-4,
  .mx-xsm-4 {
    margin-left: 1.5rem !important; }
  .m-xsm-5 {
    margin: 3rem !important; }
  .mt-xsm-5,
  .my-xsm-5 {
    margin-top: 3rem !important; }
  .mr-xsm-5,
  .mx-xsm-5 {
    margin-right: 3rem !important; }
  .mb-xsm-5,
  .my-xsm-5 {
    margin-bottom: 3rem !important; }
  .ml-xsm-5,
  .mx-xsm-5 {
    margin-left: 3rem !important; }
  .p-xsm-0 {
    padding: 0 !important; }
  .pt-xsm-0,
  .py-xsm-0 {
    padding-top: 0 !important; }
  .pr-xsm-0,
  .px-xsm-0 {
    padding-right: 0 !important; }
  .pb-xsm-0,
  .py-xsm-0 {
    padding-bottom: 0 !important; }
  .pl-xsm-0,
  .px-xsm-0 {
    padding-left: 0 !important; }
  .p-xsm-1 {
    padding: 0.25rem !important; }
  .pt-xsm-1,
  .py-xsm-1 {
    padding-top: 0.25rem !important; }
  .pr-xsm-1,
  .px-xsm-1 {
    padding-right: 0.25rem !important; }
  .pb-xsm-1,
  .py-xsm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xsm-1,
  .px-xsm-1 {
    padding-left: 0.25rem !important; }
  .p-xsm-2 {
    padding: 0.5rem !important; }
  .pt-xsm-2,
  .py-xsm-2 {
    padding-top: 0.5rem !important; }
  .pr-xsm-2,
  .px-xsm-2 {
    padding-right: 0.5rem !important; }
  .pb-xsm-2,
  .py-xsm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xsm-2,
  .px-xsm-2 {
    padding-left: 0.5rem !important; }
  .p-xsm-3 {
    padding: 1rem !important; }
  .pt-xsm-3,
  .py-xsm-3 {
    padding-top: 1rem !important; }
  .pr-xsm-3,
  .px-xsm-3 {
    padding-right: 1rem !important; }
  .pb-xsm-3,
  .py-xsm-3 {
    padding-bottom: 1rem !important; }
  .pl-xsm-3,
  .px-xsm-3 {
    padding-left: 1rem !important; }
  .p-xsm-4 {
    padding: 1.5rem !important; }
  .pt-xsm-4,
  .py-xsm-4 {
    padding-top: 1.5rem !important; }
  .pr-xsm-4,
  .px-xsm-4 {
    padding-right: 1.5rem !important; }
  .pb-xsm-4,
  .py-xsm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xsm-4,
  .px-xsm-4 {
    padding-left: 1.5rem !important; }
  .p-xsm-5 {
    padding: 3rem !important; }
  .pt-xsm-5,
  .py-xsm-5 {
    padding-top: 3rem !important; }
  .pr-xsm-5,
  .px-xsm-5 {
    padding-right: 3rem !important; }
  .pb-xsm-5,
  .py-xsm-5 {
    padding-bottom: 3rem !important; }
  .pl-xsm-5,
  .px-xsm-5 {
    padding-left: 3rem !important; }
  .m-xsm-auto {
    margin: auto !important; }
  .mt-xsm-auto,
  .my-xsm-auto {
    margin-top: auto !important; }
  .mr-xsm-auto,
  .mx-xsm-auto {
    margin-right: auto !important; }
  .mb-xsm-auto,
  .my-xsm-auto {
    margin-bottom: auto !important; }
  .ml-xsm-auto,
  .mx-xsm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1530px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Variables */
/*----------------------------------------------------------------
------------------------------------------------------------------
--------------------------- MAIN + RESETS ------------------------
------------------------------------------------------------------
----------------------------------------------------------------*/
* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

::-moz-selection {
  background: #2759a4;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #2759a4;
  color: #fff;
  text-shadow: none; }

.chromeframe,
li.graphic-only {
  display: none !important; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden; }

body {
  margin: 0;
  line-height: 1.6;
  font-size: 17px;
  color: #000;
  background: #fff;
  min-height: 100%;
  font-weight: normal;
  position: relative;
  overflow-x: hidden; }
  @media (max-width: 767px) {
    body {
      font-size: 14px; } }

#wrap {
  position: relative;
  background: #fff;
  min-height: 100% !important; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

/* Embedded content */
img {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

svg:not(:root) {
  overflow: hidden; }

/* Grouping content */
figure {
  margin: 15px 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 100%; }

/* Forms */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  outline: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

/* HTML5 / MISC */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

ins {
  background-color: #2759a4;
  color: #fff;
  text-decoration: none; }

del {
  text-decoration: line-through; }

.f-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.f-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.text-center {
  text-align: center; }

.m-0 {
  margin: 0 !important; }

.tab-content .tab-pane {
  display: none; }
  .tab-content .tab-pane.active {
    display: block; }

.fade {
  opacity: 0;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

.collapse:not(.show) {
  display: none; }

.dropdown.show .dropdown-toggle:after {
  border-bottom: 7px solid;
  border-right: 7px solid transparent;
  border-top: 0;
  border-left: 7px solid transparent; }

.dropdown-toggle {
  padding: 0; }
  .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    vertical-align: middle;
    content: "";
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 0;
    border-left: 7px solid transparent;
    -moz-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    -ms-transition: opacity 0.15s linear;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear; }
    @media (max-width: 991px) {
      .dropdown-toggle:after {
        display: none; } }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 144px;
  padding: 10px 0;
  margin: 10px 0 0;
  font-size: 12px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6); }
  .dropdown-menu.show {
    display: block; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 6px;
  color: #494949;
  font-family: "ProximaNova-Semibold";
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover {
    background-color: #e1e1e1;
    color: #494949; }
  .dropdown-item.active {
    background-color: #e1e1e1; }

.red-text {
  color: #e70021; }

.grayscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.d-none {
  display: none !important; }

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }
  .custom-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }
    @media (max-width: 767px) {
      .custom-checkbox input:checked + label:after {
        top: 1px; } }

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  color: #34455f;
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 1; }
  @media (max-width: 767px) {
    .custom-checkbox label {
      font-size: 20px; } }
  .custom-checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px; }

.pagination {
  text-align: center;
  list-style-type: none;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .pagination .page-item {
    display: inline-block; }
    .pagination .page-item.active .page-link {
      color: #ffffff;
      background: #2759a4; }
    .pagination .page-item .page-link {
      width: 32px;
      height: 32px;
      text-align: center;
      font-weight: 600;
      transition: all 250ms ease-in-out;
      border-radius: 50%;
      font-size: 24px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .pagination .page-item .page-link:hover {
        color: #ffffff;
        background: #2759a4; }
        .pagination .page-item .page-link:hover .next:after, .pagination .page-item .page-link:hover .next:before,
        .pagination .page-item .page-link:hover .prev:after,
        .pagination .page-item .page-link:hover .prev:before {
          background: #ffffff; }
      .pagination .page-item .page-link .next {
        position: relative; }
        .pagination .page-item .page-link .next:after, .pagination .page-item .page-link .next:before {
          content: '';
          background-color: #2759a4;
          position: absolute;
          left: -7px;
          height: 4px;
          width: 18px;
          border-radius: 3px; }
        .pagination .page-item .page-link .next:before {
          top: -7px; }
        .pagination .page-item .page-link .next:after {
          top: 4px; }
        .pagination .page-item .page-link .next:before {
          transform: rotate(45deg) translateX(0); }
        .pagination .page-item .page-link .next:after {
          transform: rotate(-45deg) translateX(0); }
      .pagination .page-item .page-link .prev {
        position: relative; }
        .pagination .page-item .page-link .prev:after, .pagination .page-item .page-link .prev:before {
          content: '';
          background-color: #2759a4;
          position: absolute;
          left: -11px;
          height: 4px;
          width: 18px;
          border-radius: 3px; }
        .pagination .page-item .page-link .prev:before {
          top: -7px; }
        .pagination .page-item .page-link .prev:after {
          top: 4px; }
        .pagination .page-item .page-link .prev:before {
          transform: rotate(-45deg) translateX(0); }
        .pagination .page-item .page-link .prev:after {
          transform: rotate(45deg) translateX(0); }
  .pagination li + li {
    margin-left: 5px; }

.sitemap-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media (max-width: 575px) {
    .sitemap-row {
      max-width: 300px;
      margin: auto; } }
  .sitemap-row .sitemap-col {
    width: 33.33%; }
    @media (max-width: 575px) {
      .sitemap-row .sitemap-col {
        width: 100%; } }
    .sitemap-row .sitemap-col ul > li ul {
      padding: 0 0 0 20px;
      margin: 0; }

.hide-slider {
  opacity: 0; }

/* SLIDERS */
.slick-slider .slide {
  display: block;
  padding: 0 0 10px; }

.slick-slider .slick-arrow {
  position: absolute;
  top: calc( 50% - 58px);
  width: 50px;
  height: 50px;
  z-index: 5;
  cursor: pointer;
  text-indent: -9999px;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }
  .slick-slider .slick-arrow:hover {
    opacity: 0.75; }
    @media (max-width: 991px) {
      .slick-slider .slick-arrow:hover {
        opacity: 0; } }
  .slick-slider .slick-arrow:after, .slick-slider .slick-arrow:before {
    content: '';
    background-color: #2759a4;
    position: absolute;
    left: 0;
    height: 12px;
    width: 30px;
    border-radius: 3px; }
  .slick-slider .slick-arrow:before {
    top: 12px; }
  .slick-slider .slick-arrow:after {
    top: 26px; }

.slick-slider .slick-prev {
  left: -5px; }
  @media (min-width: 1200px) {
    .slick-slider .slick-prev {
      left: -45px; } }
  @media (min-width: 1530px) {
    .slick-slider .slick-prev {
      left: -120px; } }
  @media (max-width: 991px) {
    .slick-slider .slick-prev {
      height: 100%;
      top: 0;
      left: 0;
      width: 100px;
      opacity: 0; } }
  @media (max-width: 500px) {
    .slick-slider .slick-prev {
      width: 65px; } }
  .slick-slider .slick-prev:before {
    transform: rotate(-45deg) translateX(0); }
  .slick-slider .slick-prev:after {
    transform: rotate(45deg) translateX(0); }

.slick-slider .slick-next {
  right: -20px; }
  @media (min-width: 1200px) {
    .slick-slider .slick-next {
      right: -65px; } }
  @media (min-width: 1530px) {
    .slick-slider .slick-next {
      right: -120px; } }
  @media (max-width: 991px) {
    .slick-slider .slick-next {
      height: 100%;
      top: 0;
      right: 0;
      width: 100px;
      opacity: 0; } }
  @media (max-width: 500px) {
    .slick-slider .slick-next {
      width: 65px; } }
  .slick-slider .slick-next:before {
    transform: rotate(45deg) translateX(0); }
  .slick-slider .slick-next:after {
    transform: rotate(-45deg) translateX(0); }

.slick-slider .slick-dots {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
  margin: 27px 0 0; }
  .slick-slider .slick-dots li {
    display: inline-block;
    margin: 0 15px; }
  .slick-slider .slick-dots button {
    background: none;
    border: 0;
    box-shadow: none !important;
    text-indent: -9999px;
    position: relative;
    width: 15px;
    height: 15px;
    outline: 0;
    padding: 0;
    background-color: #b7b7b7;
    border-radius: 50%; }
    @media (max-width: 480px) {
      .slick-slider .slick-dots button {
        width: 12px;
        height: 12px; } }
  .slick-slider .slick-dots .slick-active button {
    background: #2759a4; }

.guides-slider .slide {
  display: inline-block; }

@media (max-width: 991px) {
  .guides-slider .slick-current {
    position: relative;
    transform: scale(1.1);
    z-index: 1; }
    .guides-slider .slick-current .slide-caption .text-holder {
      background-color: #ffffff; } }

@media (max-width: 991px) {
  .guides-slider .slide {
    padding: 30px 0 30px; } }

.guides-slider .slide-caption {
  margin: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  max-width: 350px; }
  @media (min-width: 1200px) {
    .guides-slider .slide-caption {
      max-width: 300px; } }
  @media (min-width: 1530px) {
    .guides-slider .slide-caption {
      max-width: 407px; } }
  @media (max-width: 991px) {
    .guides-slider .slide-caption {
      max-width: 100%; } }
  .guides-slider .slide-caption .img-holder {
    height: 257px;
    position: relative; }
    @media (max-width: 767px) {
      .guides-slider .slide-caption .img-holder {
        height: 162px; } }
    .guides-slider .slide-caption .img-holder img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
  .guides-slider .slide-caption .text-holder {
    text-align: center;
    padding: 16px 35px 20px; }
    @media (max-width: 767px) {
      .guides-slider .slide-caption .text-holder {
        background-color: rgba(39, 89, 164, 0.1);
        padding: 10px 30px 10px; } }
    .guides-slider .slide-caption .text-holder .title {
      color: #34455f;
      font-family: "ProximaNova-Semibold";
      font-size: 22px; }
      @media (max-width: 767px) {
        .guides-slider .slide-caption .text-holder .title {
          font-size: 16px; } }
    .guides-slider .slide-caption .text-holder .text {
      color: #828282;
      font-size: 16px;
      margin: 25px 0 39px;
      line-height: 1.2; }
      @media (max-width: 767px) {
        .guides-slider .slide-caption .text-holder .text {
          font-size: 13px;
          margin: 13px 0 13px; } }
    .guides-slider .slide-caption .text-holder .link {
      color: #e70021;
      font-family: "ProximaNova-Semibold";
      font-size: 17px; }
      @media (max-width: 767px) {
        .guides-slider .slide-caption .text-holder .link {
          font-size: 13px; } }

@font-face {
  font-family: ProximaNova-Regular;
  src: url("../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Regular.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: ProximaNova-Light;
  src: url("../fonts/proxima-nova/ProximaNova-Light.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Light.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Light.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Light.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: ProximaNova-Thin;
  src: url("../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Thin.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Thin.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: ProximaNova-Semibold;
  src: url("../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Semibold.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: ProximaNova-Bold;
  src: url("../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Bold.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: ProximaNova-Extrabold;
  src: url("../fonts/proxima-nova/ProximaNova-Extrabold.eot?#iefix");
  src: url("../fonts/proxima-nova/ProximaNova-Extrabold.eot?#iefix") format("eot"), url("../fonts/proxima-nova/ProximaNova-Extrabold.woff2") format("woff2"), url("../fonts/proxima-nova/ProximaNova-Extrabold.woff") format("woff"), url("../fonts/proxima-nova/ProximaNova-Extrabold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*----------------------------------------------------------------
------------------------------------------------------------------
------------------------------ FONTS -----------------------------
------------------------------------------------------------------
----------------------------------------------------------------*/
.container-fluid {
  max-width: 1360px; }

body {
  font-family: ProximaNova-Regular;
  font-size: 17px; }
  @media (max-width: 767px) {
    body {
      font-size: 14px; } }

.right,
.left {
  float: none; }

.clearfix {
  clear: both;
  height: 0;
  width: 0; }

button {
  background: none;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.stop-scrolling {
  overflow: hidden; }

.no-anim,
.no-anim * {
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important; }

.no-anim-single {
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important; }

a {
  color: #2759a4;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  outline: 0 !important; }
  a:hover, a:focus {
    text-decoration: none; }

/* amending the default bootstrap 15px gutters */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%; } }

.main-body {
  padding: 0 0 105px; }
  @media (max-width: 767px) {
    .main-body {
      padding: 0 0 50px; } }
  .wb-standard-page .main-body {
    padding: 0 0 80px; }
    @media (max-width: 1024px) {
      .wb-standard-page .main-body {
        padding: 0 0 60px; } }
    @media (max-width: 767px) {
      .wb-standard-page .main-body {
        padding: 0 0 40px; } }

.badge {
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center; }
  .badge.badge-primary {
    background-color: #2759a4; }

.spinner {
  display: none; }

.top-nav {
  background-color: #2759a4; }
  .top-nav .top-container {
    font-size: 15px; }
    .top-nav .top-container ul {
      list-style-type: none; }
      .top-nav .top-container ul li {
        display: inline-block; }
        .top-nav .top-container ul li.current_page_item {
          background-color: #11a3ff; }
        @media (max-width: 991px) {
          .top-nav .top-container ul li {
            display: none; }
            .top-nav .top-container ul li:nth-child(1), .top-nav .top-container ul li:nth-child(2) {
              display: inline-block; } }
    .top-nav .top-container a {
      color: #ffffff;
      display: inline-block;
      font-family: "ProximaNova-Bold";
      text-transform: uppercase;
      padding: 3px 15px; }
      .top-nav .top-container a.active {
        background-color: #11a3ff; }

.header-nav {
  background-image: linear-gradient(to right, #129eff, #00b1ff, #00c3ff, #00d3ff, #04e2fe);
  /*mobile header btn*/
  /*END mobile header btn*/ }
  @media (max-width: 991px) {
    .header-nav {
      padding: 15px 0; } }
  .header-nav .header-container {
    line-height: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    .header-nav .header-container .menu-side {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
    .header-nav .header-container .logo {
      max-width: 180px;
      min-width: 126px;
      padding: 8px 0 2px; }
      @media (max-width: 991px) {
        .header-nav .header-container .logo {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center; } }
      .header-nav .header-container .logo img {
        max-width: 100%; }
    .header-nav .header-container .main-nav {
      padding: 0 0 0 150px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 992px) and (max-width: 1199px) {
        .header-nav .header-container .main-nav {
          padding: 0 0 0 15px; } }
      @media (max-width: 991px) {
        .header-nav .header-container .main-nav {
          display: none; } }
      .header-nav .header-container .main-nav ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        .header-nav .header-container .main-nav ul li {
          display: inline-block;
          padding: 0 37px;
          position: relative; }
          @media (min-width: 992px) and (max-width: 1199px) {
            .header-nav .header-container .main-nav ul li {
              padding: 0 10px; } }
          .header-nav .header-container .main-nav ul li a {
            color: #ffffff;
            font-size: 20px; }
            .header-nav .header-container .main-nav ul li a.active {
              font-family: "ProximaNova-Bold"; }
            @media (min-width: 992px) and (max-width: 1199px) {
              .header-nav .header-container .main-nav ul li a {
                font-size: 18px; } }
    .header-nav .header-container .head-state {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .header-nav .header-container .head-state .dropdown-toggle {
        color: #34455f;
        font-size: 17px;
        font-family: "ProximaNova-Semibold";
        min-width: 160px; }
        @media (max-width: 991px) {
          .header-nav .header-container .head-state .dropdown-toggle {
            text-align: right; } }
        @media (max-width: 991px) {
          .header-nav .header-container .head-state .dropdown-toggle .state-name {
            display: none; } }
        @media (max-width: 991px) {
          .header-nav .header-container .head-state .dropdown-toggle .flag-container {
            margin-right: 0; } }
      .header-nav .header-container .head-state .flag-container {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        margin-right: 10px;
        padding: 5px; }
        .header-nav .header-container .head-state .flag-container img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: inline-block; }
      @media (max-width: 991px) {
        .header-nav .header-container .head-state .dropdown-menu {
          right: 0; } }
      .header-nav .header-container .head-state .dropdown-menu:after {
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        border-bottom: 7px solid #ffffff;
        border-right: 7px solid transparent;
        border-top: 0;
        border-left: 7px solid transparent;
        -moz-transition: opacity 0.15s linear;
        -o-transition: opacity 0.15s linear;
        -ms-transition: opacity 0.15s linear;
        -webkit-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
        position: absolute;
        top: -7px;
        left: 10px; }
        @media (max-width: 991px) {
          .header-nav .header-container .head-state .dropdown-menu:after {
            right: 10px;
            left: auto; } }
      .header-nav .header-container .head-state .dropdown-menu .drop-label {
        color: #aaaaaa;
        font-family: "ProximaNova-Semibold";
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 6px 7px; }
      .header-nav .header-container .head-state .dropdown-menu .flag-container {
        height: 22px;
        width: 22px;
        padding: 3px; }
    .header-nav .header-container .s-form .dropdown-menu:after {
      left: 20px; }
  .header-nav .header_btn {
    position: relative; }
    .header-nav .header_btn span {
      height: 5px;
      position: absolute; }
  .header-nav .bit-1 {
    margin-top: -12px;
    width: 30px;
    margin-left: -14px; }
    .header-nav .bit-1:before {
      left: 0;
      transform-origin: left bottom;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .header-nav .bit-1:before, .header-nav .bit-1:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 50%;
      background: #ffffff;
      top: 0;
      transition: transform .1s, top .1s; }
    .header-nav .bit-1:after {
      right: 0;
      transform-origin: right bottom;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px; }
  .header-nav .bit-2 {
    left: 50%;
    margin-left: -14px;
    margin-top: -1px;
    text-align: center; }
    .header-nav .bit-2:before {
      content: '';
      width: 30px;
      height: 100%;
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      transition: width .1s;
      transition-delay: 0.1s;
      border-radius: 10px; }
  .header-nav .bit-3 {
    width: 30px;
    margin-left: -14px;
    margin-top: 10px; }
    .header-nav .bit-3:before {
      left: 0;
      transform-origin: left top;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .header-nav .bit-3:before, .header-nav .bit-3:after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      background: #ffffff;
      transition: transform .1s, bottom .1s;
      bottom: 0; }
    .header-nav .bit-3:after {
      right: 0;
      transform-origin: right top;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px; }
  .header-nav .opened .bit-1 {
    width: 32px; }
    .header-nav .opened .bit-1:before {
      width: 23px;
      transform: rotate(45deg);
      transition-delay: 0.2s; }
    .header-nav .opened .bit-1:before, .header-nav .opened .bit-1:after {
      top: -4px;
      background: #ffffff; }
    .header-nav .opened .bit-1:after {
      width: 23px;
      transition-delay: 0.2s;
      transform: rotate(-45deg); }
  .header-nav .opened .bit-2 {
    margin-left: -3px; }
    .header-nav .opened .bit-2:before {
      width: 0;
      transition-delay: 0; }
  .header-nav .opened .bit-3 {
    width: 32px; }
    .header-nav .opened .bit-3:before {
      transform: rotate(-45deg); }
    .header-nav .opened .bit-3:before, .header-nav .opened .bit-3:after {
      width: 23px;
      transition-delay: 0.2s;
      bottom: -4px;
      background: #ffffff; }
    .header-nav .opened .bit-3:after {
      transform: rotate(45deg); }
  .header-nav .opened.result-open .bit-1:before, .header-nav .opened.result-open .bit-1:after,
  .header-nav .opened.result-open .bit-3:before,
  .header-nav .opened.result-open .bit-3:after {
    background: #2759a4; }
  .header-nav .navbar-toggler {
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 10;
    display: none; }
    @media (max-width: 991px) {
      .header-nav .navbar-toggler {
        display: block; } }
  .header-nav .navbar-toggler:focus {
    outline: none; }
  .header-nav .custom_navbar {
    display: inline-block; }

.mob-nav {
  background-color: #2759a4;
  padding: 90px 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  right: 0;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 9;
  display: none; }
  .mob-nav.menu-open {
    left: 0; }
  @media (max-width: 991px) {
    .mob-nav {
      display: block; } }
  @media (max-width: 480px) {
    .mob-nav {
      padding: 75px 15px; } }
  .mob-nav .logo {
    text-align: center; }
  .mob-nav .form {
    margin: 32px 0 0;
    position: relative; }
    .mob-nav .form input {
      border: 0;
      border-radius: 5px;
      color: #ffffff;
      padding: 10px 10px 10px 38px;
      background-color: rgba(255, 255, 255, 0.1);
      font-size: 20px;
      font-family: "ProximaNova-Semibold";
      width: 100%; }
      .mob-nav .form input::-webkit-input-placeholder {
        color: #ffffff;
        font-family: "ProximaNova-Semibold";
        font-style: italic;
        font-size: 20px; }
      .mob-nav .form input:-moz-placeholder {
        color: #ffffff;
        font-family: "ProximaNova-Semibold";
        font-style: italic;
        font-size: 20px; }
      .mob-nav .form input::-moz-placeholder {
        color: #ffffff;
        font-family: "ProximaNova-Semibold";
        font-style: italic;
        font-size: 20px; }
      .mob-nav .form input:-ms-input-placeholder {
        color: #ffffff;
        font-family: "ProximaNova-Semibold";
        font-style: italic;
        font-size: 20px; }
    .mob-nav .form .submit {
      position: absolute;
      top: 9px;
      left: 8px; }
      .mob-nav .form .submit .s-ico {
        height: 15px;
        width: 15px;
        fill: #fff; }
    .mob-nav .form.active input {
      background-color: #fff;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
      color: #494949; }
      .mob-nav .form.active input::-webkit-input-placeholder {
        color: #494949; }
      .mob-nav .form.active input:-moz-placeholder {
        color: #494949; }
      .mob-nav .form.active input::-moz-placeholder {
        color: #494949; }
      .mob-nav .form.active input:-ms-input-placeholder {
        color: #494949; }
    .mob-nav .form.active .submit .s-ico {
      fill: #2759a4; }
    .mob-nav .form .dropdown-menu {
      top: auto;
      bottom: 100%;
      padding: 12px 0;
      margin: 0 -30px 7px;
      padding-left: 20%;
      right: 0; }
      @media (max-width: 575px) {
        .mob-nav .form .dropdown-menu {
          padding-left: 60px; } }
      @media (max-width: 480px) {
        .mob-nav .form .dropdown-menu {
          margin: 0 -15px 7px;
          padding: 5px 0 5px 60px; } }
      .mob-nav .form .dropdown-menu.show {
        min-height: 178px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center; }
      .mob-nav .form .dropdown-menu:after {
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        border-top: 7px solid #ffffff;
        border-right: 7px solid transparent;
        border-bottom: 0;
        border-left: 7px solid transparent;
        -moz-transition: opacity 0.15s linear;
        -o-transition: opacity 0.15s linear;
        -ms-transition: opacity 0.15s linear;
        -webkit-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
        position: absolute;
        bottom: -7px;
        left: 44px; }
        @media (max-width: 480px) {
          .mob-nav .form .dropdown-menu:after {
            left: 28px; } }
      .mob-nav .form .dropdown-menu .drop-label {
        color: #aaaaaa;
        font-family: "ProximaNova-Semibold";
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 6px 0; }
      .mob-nav .form .dropdown-menu .dropdown-item {
        font-size: 16px;
        padding: 5px 6px;
        display: none; }
        .mob-nav .form .dropdown-menu .dropdown-item:nth-child(1), .mob-nav .form .dropdown-menu .dropdown-item:nth-child(2), .mob-nav .form .dropdown-menu .dropdown-item:nth-child(3) {
          display: block; }
      .mob-nav .form .dropdown-menu .img-container {
        display: inline-block;
        margin: 0 5px 0 0;
        max-width: 60px; }
        .mob-nav .form .dropdown-menu .img-container img {
          vertical-align: middle;
          vertical-align: middle;
          height: 40px;
          object-fit: cover; }
  .mob-nav .main-menu {
    list-style-type: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 20px;
    font-family: "ProximaNova-Semibold";
    margin: 7px 0 0;
    width: 100%; }
    .mob-nav .main-menu li {
      position: relative; }
      .mob-nav .main-menu li:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 21px;
        width: 21px;
        position: absolute;
        top: 9px;
        left: 10px; }
      .mob-nav .main-menu li.i-home:before {
        background-image: url("../img/i-home.png"); }
      .mob-nav .main-menu li.i-slots:before {
        background-image: url("../img/i-slots.png"); }
      .mob-nav .main-menu li.i-roulette:before {
        background-image: url("../img/i-roulette.png"); }
      .mob-nav .main-menu li.i-blackjack:before {
        background-image: url("../img/i-blackjack.png"); }
      .mob-nav .main-menu li.i-baccarat:before {
        background-image: url("../img/i-baccarat.png"); }
      .mob-nav .main-menu li.i-poker:before {
        background-image: url("../img/i-poker.png"); }
      .mob-nav .main-menu li.i-craps:before {
        background-image: url("../img/i-craps.png"); }
      .mob-nav .main-menu li.i-vpocker:before {
        background-image: url("../img/i-vpocker.png"); }
      .mob-nav .main-menu li:after {
        content: '';
        background-color: #ffffff;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0; }
      .mob-nav .main-menu li a {
        color: #ffffff;
        display: block;
        padding: 4px 10px 4px 43px; }
        .mob-nav .main-menu li a:hover {
          color: rgba(255, 255, 255, 0.5); }
      .mob-nav .main-menu li:last-child:after {
        display: none; }
  .mob-nav .state {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: #ffffff;
    font-size: 20px;
    font-family: "ProximaNova-Semibold";
    padding: 6px 12px;
    margin: 7px 0 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    .mob-nav .state .state-name {
      position: relative;
      line-height: 1; }
      .mob-nav .state .state-name:after {
        content: '';
        border-left: 9px solid #ffffff;
        border-right: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        display: inline-block;
        margin-left: 24px; }

.s-form {
  position: relative;
  margin: 0 29px 0 0;
  max-width: 210px; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .s-form {
      margin: 0 15px 0 0;
      max-width: 160px; } }
  @media (max-width: 991px) {
    .s-form {
      display: none; } }
  .s-form .search {
    background: transparent;
    border-radius: 3px;
    border: 0;
    padding: 13px 5px 13px 50px;
    max-width: 100%; }
    .s-form .search::-webkit-input-placeholder {
      color: #34455f; }
    .s-form .search:-moz-placeholder {
      color: #34455f; }
    .s-form .search::-moz-placeholder {
      color: #34455f; }
    .s-form .search:-ms-input-placeholder {
      color: #34455f; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .s-form .search {
        padding: 10px 5px 10px 35px; } }
    .s-form .search:focus {
      background-color: #fff;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6); }
  .s-form .search-btn {
    position: absolute;
    top: 8px;
    left: 8px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .s-form .search-btn {
        top: 10px;
        left: 2px; } }
    .s-form .search-btn .s-ico {
      height: 30px;
      width: 30px; }
      @media (min-width: 992px) and (max-width: 1199px) {
        .s-form .search-btn .s-ico {
          height: 20px;
          width: 20px; } }
  .s-form .dropdown-menu {
    width: 100%;
    margin: 6px 0 0;
    min-width: 190px; }
    .s-form .dropdown-menu .dropdown-item {
      font-size: 16px;
      padding: 5px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .s-form .img-container {
    display: inline-block;
    margin: 0 5px 0 0;
    max-width: 60px; }
    .s-form .img-container img {
      vertical-align: middle;
      height: 40px;
      object-fit: cover; }
  .s-form.active .search {
    background-color: #fff;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6); }

.page-footer {
  background-color: #299cff;
  color: #ffffff;
  padding: 70px 0 40px; }
  @media (max-width: 991px) {
    .page-footer {
      padding: 30px 0 100px; } }
  .page-footer .flex-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 1199px) {
      .page-footer .flex-row {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .page-footer .list-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    width: 59%; }
    @media (max-width: 1199px) {
      .page-footer .list-section {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%; } }
    @media (max-width: 991px) {
      .page-footer .list-section {
        padding: 0 15px; } }
    @media (max-width: 400px) {
      .page-footer .list-section {
        padding: 0; } }
    @media (max-width: 991px) {
      .page-footer .list-section .footer-nav {
        padding: 0 15px;
        margin: 0 0 30px;
        width: 50%; } }
    @media (max-width: 400px) {
      .page-footer .list-section .footer-nav {
        padding: 5px; } }
    .page-footer .list-section .footer-nav .list-title {
      font-size: 20px;
      font-family: "ProximaNova-Semibold";
      margin: 0 0 23px; }
      @media (max-width: 767px) {
        .page-footer .list-section .footer-nav .list-title {
          font-size: 18px;
          margin: 0 0 5px; } }
    .page-footer .list-section .footer-nav ul {
      list-style-type: none;
      padding: 0; }
      .page-footer .list-section .footer-nav ul li {
        line-height: 1.2; }
        @media (max-width: 767px) {
          .page-footer .list-section .footer-nav ul li {
            line-height: 1.4; } }
        .page-footer .list-section .footer-nav ul li a {
          color: #ffffff;
          font-size: 20px; }
          @media (max-width: 767px) {
            .page-footer .list-section .footer-nav ul li a {
              font-size: 18px; } }
          .page-footer .list-section .footer-nav ul li a:hover {
            color: #2759a4; }
  .page-footer .contact-section {
    text-align: right; }
    @media (max-width: 1199px) {
      .page-footer .contact-section {
        text-align: center;
        margin: 40px 0 0;
        width: 100%; } }
    @media (max-width: 991px) {
      .page-footer .contact-section {
        margin: 5px 0 0; } }
    .page-footer .contact-section .list-title {
      margin: 0 0 15px; }
      @media (max-width: 991px) {
        .page-footer .contact-section .list-title {
          margin: 0 0 15px;
          font-size: 18px; } }
    .page-footer .contact-section .foot-copy {
      display: block;
      font-size: 15px;
      margin: 17px 0 0; }
  .page-footer .social-list {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .page-footer .social-list li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0 3px; }
      .page-footer .social-list li.fb a {
        padding: 0;
        background: #299cff; }
        .page-footer .social-list li.fb a:hover svg {
          fill: #ffffff;
          background: #2759a4; }
      .page-footer .social-list li.fb svg {
        height: 49px;
        width: 49px;
        fill: #ffffff; }
      .page-footer .social-list li a {
        background: #ffffff;
        border-radius: 50%;
        padding: 8px;
        display: block;
        overflow: hidden; }
        .page-footer .social-list li a:hover svg {
          fill: #2759a4; }
    .page-footer .social-list svg {
      height: 33px;
      width: 33px;
      fill: #299cff;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease; }

.btn {
  color: #ffffff;
  font-family: "ProximaNova-Bold";
  font-size: 18px;
  border: 2px solid #ffffff;
  box-shadow: 3px 3px 0 #214400;
  border-radius: 29px;
  padding: 12px 20px;
  position: relative; }
  .btn:hover {
    color: #ffffff; }
  .btn.green-btn {
    background-color: #60b511; }
    .btn.green-btn:hover {
      background-color: #6ac714; }
  .btn.blue-gr {
    background-image: linear-gradient(-253deg, #3ccaff 0%, #0089ff 100%);
    position: relative;
    z-index: 1; }
    .btn.blue-gr > span {
      position: relative;
      z-index: 3; }
    .btn.blue-gr:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 29px;
      background-image: linear-gradient(-253deg, #0089ff 0%, #3ccaff 100%);
      transition: opacity 0.25s ease-out;
      z-index: 2;
      opacity: 0; }
    .btn.blue-gr:hover:after {
      opacity: 1; }
    .btn.blue-gr .arrow {
      display: inline-block;
      width: 25px;
      height: 15px;
      position: relative; }
      .btn.blue-gr .arrow:before, .btn.blue-gr .arrow:after {
        content: '';
        position: absolute;
        right: 0;
        background-color: #ffffff;
        height: 3px;
        width: 13px; }
        @media (max-width: 767px) {
          .btn.blue-gr .arrow:before, .btn.blue-gr .arrow:after {
            width: 10px; } }
      .btn.blue-gr .arrow:before {
        transform: rotate(45deg) translateX(0px);
        top: calc(50% - 5px); }
        @media (max-width: 767px) {
          .btn.blue-gr .arrow:before {
            top: calc(50% - 1px); } }
      .btn.blue-gr .arrow:after {
        transform: rotate(-45deg) translateX(0px);
        top: 10px; }
        @media (max-width: 767px) {
          .btn.blue-gr .arrow:after {
            top: 11px; } }

.rating .stars {
  position: relative;
  display: inline-block;
  height: 23px;
  width: 120px;
  background-image: url("../img/stars.png");
  background-position: 0 0;
  background-repeat: repeat-x; }
  .rating .stars .on {
    height: 23px;
    background-image: url("../img/stars.png");
    background-position: 0 -23px;
    background-repeat-y: no-repeat; }

.pagenumber-container {
  margin: 0; }
  .wb-case-studies .pagenumber-container {
    margin: 60px 0 0; }
    @media (max-width: 767px) {
      .wb-case-studies .pagenumber-container {
        margin: 30px 0 0; } }
  @media (max-width: 767px) {
    .wb-news .pagenumber-container {
      margin: 20px 0 0; } }

ul.page-numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style: none; }
  ul.page-numbers li {
    float: left; }
    @media (max-width: 767px) {
      ul.page-numbers li {
        display: none; }
        ul.page-numbers li.current-li, ul.page-numbers li.prev, ul.page-numbers li.next {
          display: block !important; } }
    ul.page-numbers li + li {
      margin-left: 5px; }
    ul.page-numbers li .page-numbers {
      width: 32px;
      height: 32px;
      text-align: center;
      color: #000;
      font-weight: 600;
      -moz-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 24px; }
      @media (max-width: 767px) {
        ul.page-numbers li .page-numbers {
          width: 30px;
          height: 30px; } }
      ul.page-numbers li .page-numbers i {
        line-height: 30px;
        font-size: 16px;
        font-weight: 600; }
      ul.page-numbers li .page-numbers:hover, ul.page-numbers li .page-numbers:focus {
        background: #2759a4; }
        ul.page-numbers li .page-numbers:hover , ul.page-numbers li .page-numbers:focus {
          color: #fff; }
      ul.page-numbers li .page-numbers.next, ul.page-numbers li .page-numbers.prev {
        background: none;
        color: #2759a4;
        background: transparent;
        border: 1px solid #2759a4; }
        ul.page-numbers li .page-numbers.next:hover, ul.page-numbers li .page-numbers.next:focus, ul.page-numbers li .page-numbers.prev:hover, ul.page-numbers li .page-numbers.prev:focus {
          background: #2759a4;
          color: #fff; }
        ul.page-numbers li .page-numbers.next:not(a), ul.page-numbers li .page-numbers.prev:not(a) {
          color: #838383;
          border: 1px solid #838383; }
          ul.page-numbers li .page-numbers.next:not(a):hover, ul.page-numbers li .page-numbers.next:not(a):focus, ul.page-numbers li .page-numbers.prev:not(a):hover, ul.page-numbers li .page-numbers.prev:not(a):focus {
            background: transparent;
            color: #838383; }
        @media (max-width: 767px) {
          ul.page-numbers li .page-numbers.next, ul.page-numbers li .page-numbers.prev {
            padding: 0 5px; } }
      ul.page-numbers li .page-numbers.prev {
        margin: 0 13px 0 0; }
        ul.page-numbers li .page-numbers.prev i {
          padding: 0 3px 0 0; }
      ul.page-numbers li .page-numbers.next {
        margin: 0 0 0 13px; }
        ul.page-numbers li .page-numbers.next i {
          padding: 0 0 0 3px; }
      ul.page-numbers li .page-numbers.current {
        color: #fff;
        background: #2759a4; }
      ul.page-numbers li .page-numbers.dots:hover {
        background: transparent;
        color: #000; }

* + ul, * + ol, * + dl, * + p, * + blockquote, * + table {
  margin: 15px 0 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1; }

.loader-widget .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  .loader-widget .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -25%); }
  .loader-widget .modal.show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .loader-widget .modal.show .modal-dialog {
      transform: translate(0, 0); }
  .loader-widget .modal .modal-dialog {
    position: relative;
    margin: auto;
    max-width: 470px; }
    @media (max-width: 991px) {
      .loader-widget .modal .modal-dialog {
        max-width: 370px; } }
  .loader-widget .modal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    outline: 0;
    padding: 60px 30px; }
    @media (max-width: 991px) {
      .loader-widget .modal .modal-content {
        padding: 50px 30px; } }
  .loader-widget .modal .modal-title {
    font-size: 20px;
    color: #2759a4;
    margin: 0 0 20px; }
    @media (max-width: 991px) {
      .loader-widget .modal .modal-title {
        font-size: 18px; } }
  .loader-widget .modal .img-holder {
    position: relative; }
    .loader-widget .modal .img-holder img {
      display: block;
      max-height: 223px;
      margin: 0 auto; }
      @media (max-width: 991px) {
        .loader-widget .modal .img-holder img {
          max-height: 175px; } }
    .loader-widget .modal .img-holder .lds-spinner {
      position: absolute;
      bottom: 40px;
      right: 0;
      left: 60px;
      margin: auto; }
      @media (max-width: 991px) {
        .loader-widget .modal .img-holder .lds-spinner {
          bottom: 22px;
          left: 45px; } }

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px; }

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite; }

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 29px;
  width: 2px;
  height: 12px;
  border-radius: 20%;
  background: #2759a4; }

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s; }

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s; }

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s; }

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s; }

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s; }

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s; }

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s; }

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s; }

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s; }

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s; }

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s; }

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s; }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.intro-inner-widget {
  color: #ffffff;
  padding: 60px 0 23px;
  background-repeat: no-repeat;
  background-color: #000;
  background-position: 100% 0;
  background-size: contain; }
  @media (max-width: 767px) {
    .intro-inner-widget {
      padding: 30px 0 23px; } }
  @media (max-width: 575px) {
    .intro-inner-widget {
      background-size: unset; } }
  @media (max-width: 991px) {
    .intro-inner-widget:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1; } }
  .intro-inner-widget .container {
    position: relative;
    z-index: 2; }
  .intro-inner-widget .logo-casino {
    display: none; }
    @media (max-width: 575px) {
      .intro-inner-widget .logo-casino {
        display: block;
        text-align: right;
        position: absolute;
        right: 15px;
        bottom: 15%;
        max-width: 80px; } }
  .intro-inner-widget .text-holder {
    position: relative;
    max-width: 57%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    @media (max-width: 991px) {
      .intro-inner-widget .text-holder {
        max-width: 100%; } }
  .intro-inner-widget .info-col {
    flex: 50%; }
    @media (max-width: 575px) {
      .intro-inner-widget .info-col {
        flex: 100%;
        order: 2;
        -webkit-order: 2;
        -ms-flex-order: 2; } }
    .intro-inner-widget .info-col .name {
      font-size: 35px; }
    .intro-inner-widget .info-col .rating-row {
      font-size: 18px;
      font-family: "ProximaNova-Thin";
      text-transform: uppercase;
      margin: 8px 0 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .intro-inner-widget .info-col .rating-row .rating {
        margin: 0 0 0 15px; }
    .intro-inner-widget .info-col .btn {
      display: inline-block;
      margin: 35px 0 0;
      padding: 4px 60px;
      text-transform: uppercase;
      font-size: 21px; }
      .intro-inner-widget .info-col .btn:before, .intro-inner-widget .info-col .btn:after {
        display: none; }
    .intro-inner-widget .info-col .players-position {
      margin: 40px 0 0;
      font-size: 15px;
      display: block;
      color: #ffffff; }
      .intro-inner-widget .info-col .players-position .flag-container {
        margin: 0 3px 0 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        padding: 5px; }
  .intro-inner-widget .list-col {
    font-size: 18px;
    letter-spacing: -0.75px;
    margin: 0;
    list-style-type: none;
    flex-direction: column;
    flex: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media (max-width: 575px) {
      .intro-inner-widget .list-col {
        flex: 100%;
        margin: 0 0 30px;
        order: 1;
        -webkit-order: 1;
        -ms-flex-order: 1; } }
    .intro-inner-widget .list-col li {
      position: relative;
      margin: 0;
      padding: 0 0 0 30px;
      line-height: 1.2;
      letter-spacing: 0;
      font-size: 15px; }
      .intro-inner-widget .list-col li:before, .intro-inner-widget .list-col li:after {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        background-color: #ffffff;
        height: 4px; }
      .intro-inner-widget .list-col li:before {
        transform: rotate(45deg) translateX(-1px);
        width: 10px; }
      .intro-inner-widget .list-col li:after {
        transform: rotate(-45deg) translateX(5px);
        width: 15px; }
      .intro-inner-widget .list-col li + li {
        margin-top: 15px; }

.error404 .alert-warning {
  text-align: center;
  min-height: 65vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column; }
  .error404 .alert-warning .title {
    color: #2759a4;
    font-family: "ProximaNova-Bold";
    font-size: 98px;
    text-transform: uppercase; }
  .error404 .alert-warning .subtitle {
    color: #1493ff;
    font-size: 35px;
    margin: 15px 0 0; }

.main-content,
aside {
  float: left; }
  @media (max-width: 991px) {
    .main-content,
    aside {
      width: 100%; } }

.widget-container {
  position: relative; }

@media (max-width: 991px) {
  .main-content {
    margin-bottom: 40px; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .main-content {
      margin-bottom: 25px; } }

.main-content .widget-container {
  margin-top: 40px; }
  @media (max-width: 767px) {
    .main-content .widget-container {
      margin-top: 25px; } }
  .main-content .widget-container h3 {
    margin-bottom: 25px; }
    @media (max-width: 767px) {
      .main-content .widget-container h3 {
        margin-bottom: 15px; } }

aside .widget-container {
  margin-top: 25px; }
  @media (max-width: 767px) {
    aside .widget-container {
      margin-top: 25px; } }
  aside .widget-container h4 {
    margin-bottom: 18px; }
    @media (max-width: 767px) {
      aside .widget-container h4 {
        margin-bottom: 15px; } }

aside .widget-container:first-child {
  margin-top: 0 !important; }

h1.page-title + .standard-widget {
  margin-top: 20px; }

.intro-widget {
  background: url("../img/intro.jpg");
  color: #ffffff;
  padding: 30px 0 35px;
  background-size: cover;
  background-position: 50% 50%; }
  @media (max-width: 767px) {
    .intro-widget {
      padding: 18px 0 35px; } }
  .intro-widget:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 24, 64, 0.5);
    z-index: 1; }
  .intro-widget .text-holder {
    position: relative;
    z-index: 2;
    max-width: 62%; }
    @media (max-width: 991px) {
      .intro-widget .text-holder {
        max-width: 100%; } }
  .intro-widget .title {
    font-size: 60px;
    font-family: "ProximaNova-Bold";
    font-style: italic;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .intro-widget .title {
        font-size: 45px; } }
    @media (max-width: 767px) {
      .intro-widget .title {
        font-size: 25px; } }
  .intro-widget p {
    font-size: 20px;
    line-height: 1; }
    @media (max-width: 767px) {
      .intro-widget p {
        font-size: 16px;
        line-height: 0.95;
        margin: 9px 0 0; } }

.nav-cta-widget {
  background-color: #2759a4;
  padding: 10px 0; }
  @media (max-width: 991px) {
    .nav-cta-widget {
      padding: 13px 0 35px; } }
  .nav-cta-widget.position-f {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: -100%;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    @media (max-width: 991px) {
      .nav-cta-widget.position-f {
        padding: 10px 0 10px;
        top: auto;
        bottom: -100%; } }
    @media (max-width: 991px) {
      .nav-cta-widget.position-f .cta-container {
        -webkit-flex-wrap: nowrap;
        -moz-flex-wrap: nowrap;
        -ms-flex-wrap: none;
        flex-wrap: nowrap; } }
    @media (max-width: 991px) {
      .nav-cta-widget.position-f .logo {
        flex: 0 0 85px;
        margin-left: 0;
        margin-bottom: 0; } }
    @media (max-width: 767px) {
      .nav-cta-widget.position-f .logo {
        flex: 0 0 60px; } }
    @media (max-width: 991px) {
      .nav-cta-widget.position-f .text-holder {
        margin: 0; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nav-cta-widget.position-f p {
        padding: 0 15px; } }
    @media (max-width: 767px) {
      .nav-cta-widget.position-f p {
        text-align: left;
        font-size: 12px;
        margin: 0;
        padding: 0 10px; } }
    @media (max-width: 991px) {
      .nav-cta-widget.position-f .btn {
        flex: 0 0 auto;
        font-size: 11px;
        padding: 6px 25px; } }
  .nav-cta-widget.sticky {
    top: 0; }
    @media (max-width: 991px) {
      .nav-cta-widget.sticky {
        top: auto;
        bottom: 0; } }
  .nav-cta-widget .cta-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .nav-cta-widget .cta-container {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; } }
  @media (max-width: 991px) {
    .nav-cta-widget .logo {
      text-align: center;
      margin: 0 0 23px; } }
  .nav-cta-widget .logo img {
    display: block;
    max-height: 88px; }
  @media (max-width: 991px) {
    .nav-cta-widget .text-holder {
      margin: 0 0 23px; } }
  .nav-cta-widget p {
    color: #ffffff;
    font-family: "ProximaNova-Bold";
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    padding: 0 20px;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .nav-cta-widget p {
        font-size: 22px; } }
    @media (max-width: 991px) {
      .nav-cta-widget p {
        text-align: center;
        padding: 0 15px;
        max-width: 100%;
        width: 100%; } }
    @media (max-width: 767px) {
      .nav-cta-widget p {
        font-size: 16px; } }
    @media (max-width: 575px) {
      .nav-cta-widget p {
        font-size: 14px; } }
    @media (max-width: 991px) {
      .nav-cta-widget p + .btn {
        margin: 29px 0 0; } }
  .nav-cta-widget .btn {
    text-transform: uppercase;
    padding: 12px 55px;
    flex: 0 0 236px; }

.casino-widget {
  padding: 70px 0 70px;
  background-color: #f0f3f8; }
  @media (max-width: 767px) {
    .casino-widget {
      padding: 20px 0 35px; } }
  .casino-widget .text-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    margin: 0 0 40px; }
    .casino-widget .text-container .title {
      color: #2759a4;
      font-size: 54px;
      font-family: "ProximaNova-Bold";
      font-style: italic;
      text-transform: uppercase; }
      .casino-widget .text-container .title.underline {
        position: relative; }
        .casino-widget .text-container .title.underline:after {
          content: '';
          display: block;
          margin: 3px 0 0;
          width: 100%;
          height: 4px;
          background-color: rgba(99, 99, 99, 0.44); }
    .casino-widget .text-container .text {
      color: #494949;
      font-family: "ProximaNova-Bold";
      font-size: 15px;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-align: center; }
  .casino-widget .items-container .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 202px; }
    @media (max-width: 991px) {
      .casino-widget .items-container .item {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .casino-widget .items-container .item + .item {
      margin: 24px 0 0; }
    .casino-widget .items-container .item.dark-item .text-holder {
      background-color: #34455f; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.dark-item .text-holder {
          background-color: #e0e9f0; } }
    .casino-widget .items-container .item.dark-item .free-col {
      color: #ffffff;
      font-family: "ProximaNova-Bold"; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.dark-item .free-col {
          color: #34455f; } }
      .casino-widget .items-container .item.dark-item .free-col .price {
        color: #ffffff; }
        @media (max-width: 991px) {
          .casino-widget .items-container .item.dark-item .free-col .price {
            color: #34455f; } }
    .casino-widget .items-container .item.dark-item .list-col {
      color: #ffffff; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.dark-item .list-col {
          background-color: #34455f; } }
      .casino-widget .items-container .item.dark-item .list-col li:before, .casino-widget .items-container .item.dark-item .list-col li:after {
        background-color: #ffffff; }
    .casino-widget .items-container .item.dark-item .price-col {
      color: #ffffff; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.dark-item .price-col {
          color: #34455f; } }
      .casino-widget .items-container .item.dark-item .price-col .percent {
        color: #ffffff; }
        @media (max-width: 991px) {
          .casino-widget .items-container .item.dark-item .price-col .percent {
            color: #34455f; } }
      .casino-widget .items-container .item.dark-item .price-col .price {
        color: #1493ff; }
    .casino-widget .items-container .item.dark-item .rating-col .rest {
      color: #ffffff; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.dark-item .rating-col .rest {
          color: #34455f; } }
    @media (max-width: 991px) {
      .casino-widget .items-container .item.dark-item .btn-col {
        background-color: #34455f; } }
    .casino-widget .items-container .item.dark-item .btn-col .rating-title {
      color: #ffffff; }
    .casino-widget .items-container .item.light-item .text-holder {
      background-color: #c4ecff; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.light-item .text-holder {
          background-color: #e0e9f0; } }
    .casino-widget .items-container .item.light-item .list-col {
      color: #000000; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item.light-item .list-col {
          background-color: #c4ecff; } }
      .casino-widget .items-container .item.light-item .list-col li:before, .casino-widget .items-container .item.light-item .list-col li:after {
        background-color: #000000; }
    .casino-widget .items-container .item.light-item .price-col {
      color: #000; }
      .casino-widget .items-container .item.light-item .price-col .percent {
        color: #000; }
      .casino-widget .items-container .item.light-item .price-col .price {
        color: #1493ff; }
    @media (max-width: 991px) {
      .casino-widget .items-container .item.light-item .btn-col {
        background-color: #c4ecff; } }
    .casino-widget .items-container .item .logo-holder {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .logo-holder {
          width: 100%; } }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .logo-holder {
          display: none; } }
      .casino-widget .items-container .item .logo-holder .count-col {
        background-color: #34455f;
        color: #ffffff;
        font-family: "ProximaNova-Bold";
        font-size: 80px;
        padding: 0 0 0 14px;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; }
        @media (min-width: 1200px) {
          .casino-widget .items-container .item .logo-holder .count-col {
            font-size: 75px;
            padding: 0 0 0 7px; } }
        @media (min-width: 1530px) {
          .casino-widget .items-container .item .logo-holder .count-col {
            font-size: 80px;
            padding: 0 0 0 14px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .casino-widget .items-container .item .logo-holder .count-col {
            display: none; } }
        .casino-widget .items-container .item .logo-holder .count-col .count {
          margin-right: -5px;
          text-align: right;
          width: 50px; }
      .casino-widget .items-container .item .logo-holder .img-holder {
        background-color: #e0e9f0;
        position: relative;
        padding: 0 24px;
        height: 100%;
        width: 160px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .casino-widget .items-container .item .logo-holder .img-holder {
            width: 100px;
            padding: 0 10px; } }
        .casino-widget .items-container .item .logo-holder .img-holder .img-container {
          border-radius: 50%;
          background-color: #ffffff;
          height: 100px;
          width: 100px;
          overflow: hidden;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          @media (min-width: 992px) and (max-width: 1199px) {
            .casino-widget .items-container .item .logo-holder .img-holder .img-container {
              height: 80px;
              width: 80px; } }
        .casino-widget .items-container .item .logo-holder .img-holder .img-item {
          padding: 10px;
          max-width: 90px; }
          @media (min-width: 992px) and (max-width: 1199px) {
            .casino-widget .items-container .item .logo-holder .img-holder .img-item {
              max-width: 70px; } }
      .casino-widget .items-container .item .logo-holder .first-img-holder {
        background-image: url("../img/c_item-bg.png");
        background-repeat: no-repeat;
        background-position: 50% 50%; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .casino-widget .items-container .item .logo-holder .first-img-holder {
            background-size: 80%; } }
        .casino-widget .items-container .item .logo-holder .first-img-holder .img-item {
          background-color: transparent;
          margin: 56% 0 0 25%;
          max-width: 60px;
          max-height: 60px;
          padding: 0; }
          @media (min-width: 992px) and (max-width: 1199px) {
            .casino-widget .items-container .item .logo-holder .first-img-holder .img-item {
              max-width: 45px;
              max-height: 45px; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .casino-widget .items-container .item .logo-holder .first-img-holder .img-item {
              max-width: 55px;
              margin: 90% 0 0 34%;
              border-radius: 0; } }
          @media (max-width: 767px) {
            .casino-widget .items-container .item .logo-holder .first-img-holder .img-item {
              max-width: 40px;
              margin: 87% 0 0 35%;
              border-radius: 0; } }
          @media (max-width: 480px) {
            .casino-widget .items-container .item .logo-holder .first-img-holder .img-item {
              max-width: 33px; } }
      .casino-widget .items-container .item .logo-holder .triangle {
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-bottom-width: 15px;
        border-top-width: 15px;
        border-left: 15px solid #e0e9f0;
        position: absolute;
        right: -15px;
        top: calc( 50% - 7.5px); }
    .casino-widget .items-container .item .mob-logo-holder {
      display: none; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .mob-logo-holder {
          padding: 10px;
          min-height: 130px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; } }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .mob-logo-holder .img-container {
          border-radius: 50%;
          background-color: #ffffff;
          height: 100px;
          width: 100px;
          overflow: hidden;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .casino-widget .items-container .item .mob-logo-holder .img-container {
      height: 80px;
      width: 80px; } }
      @media (max-width: 350px) {
        .casino-widget .items-container .item .mob-logo-holder .img-container {
          height: 50px;
          width: 50px; } }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .mob-logo-holder .triangle {
          display: none; } }
      .casino-widget .items-container .item .mob-logo-holder .img-holder .img-item {
        padding: 15px; }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .mob-logo-holder .img-holder .img-item {
            max-height: 80px; } }
        @media (max-width: 480px) {
          .casino-widget .items-container .item .mob-logo-holder .img-holder .img-item {
            padding: 10px; } }
      .casino-widget .items-container .item .mob-logo-holder .first-img-holder {
        background-image: url("../img/c_item-bg.png");
        background-repeat: no-repeat;
        background-position: 50% 50%; }
        @media (min-width: 768px) and (max-width: 991px) {
          .casino-widget .items-container .item .mob-logo-holder .first-img-holder {
            min-height: 170px;
            min-width: 100px; } }
        @media (max-width: 991px) {
          .casino-widget .items-container .item .mob-logo-holder .first-img-holder {
            background-size: contain; } }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .mob-logo-holder .first-img-holder {
            min-height: 120px;
            min-width: 75px; } }
        @media (max-width: 480px) {
          .casino-widget .items-container .item .mob-logo-holder .first-img-holder {
            min-height: 100px;
            min-width: 62px; } }
        .casino-widget .items-container .item .mob-logo-holder .first-img-holder .img-item {
          background-color: transparent; }
          @media (min-width: 768px) and (max-width: 991px) {
            .casino-widget .items-container .item .mob-logo-holder .first-img-holder .img-item {
              max-width: 70px;
              margin: 83% 0 0 30%;
              padding: 10px; } }
          @media (max-width: 767px) {
            .casino-widget .items-container .item .mob-logo-holder .first-img-holder .img-item {
              max-width: 55px;
              margin: 76% 0 0 27%;
              padding: 10px; } }
          @media (max-width: 480px) {
            .casino-widget .items-container .item .mob-logo-holder .first-img-holder .img-item {
              max-width: 45px;
              padding: 7px; } }
    .casino-widget .items-container .item .text-holder {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: space-between;
      -webkit-justify-content: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      justify-content: space-between;
      flex: 1 1 0;
      margin: 0 0 0 15px;
      background: #ffffff; }
      @media (min-width: 1200px) {
        .casino-widget .items-container .item .text-holder {
          padding: 0 15px 0 15px; } }
      @media (min-width: 1530px) {
        .casino-widget .items-container .item .text-holder {
          padding: 0 35px 0 35px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .casino-widget .items-container .item .text-holder {
          padding: 0 10px 0 10px; } }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .text-holder {
          margin: 0;
          position: relative;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          background-color: #e0e9f0; } }
    .casino-widget .items-container .item .free-col {
      color: #000;
      font-size: 35px;
      letter-spacing: -1.5px;
      text-align: center;
      flex-direction: column;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (min-width: 1200px) {
        .casino-widget .items-container .item .free-col {
          font-size: 30px; } }
      @media (min-width: 1530px) {
        .casino-widget .items-container .item .free-col {
          font-size: 35px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .casino-widget .items-container .item .free-col {
          font-size: 28px; } }
      @media (max-width: 767px) {
        .casino-widget .items-container .item .free-col {
          font-size: 21px;
          max-width: 26%; } }
      @media (max-width: 350px) {
        .casino-widget .items-container .item .free-col {
          font-size: 18px; } }
      .casino-widget .items-container .item .free-col .price {
        color: #de2a28; }
      .casino-widget .items-container .item .free-col .cupon {
        position: relative;
        display: block;
        background-color: #4587c6;
        border-radius: 5px;
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 12px 5px;
        margin-left: 30px; }
        @media (max-width: 480px) {
          .casino-widget .items-container .item .free-col .cupon {
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            padding: 10px 5px;
            margin-left: 20px; } }
        .casino-widget .items-container .item .free-col .cupon:before {
          content: '';
          position: absolute;
          top: 0;
          left: -35px;
          background-image: url("../img/cupon.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%; }
          @media (max-width: 480px) {
            .casino-widget .items-container .item .free-col .cupon:before {
              background-size: contain;
              height: 33px;
              left: -25px; } }
    .casino-widget .items-container .item .list-col {
      color: #000000;
      font-size: 18px;
      letter-spacing: -0.75px;
      max-width: 320px;
      margin: 0;
      list-style-type: none;
      flex-direction: column;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (min-width: 1200px) {
        .casino-widget .items-container .item .list-col {
          padding: 0 10px;
          max-width: 280px; } }
      @media (min-width: 1530px) {
        .casino-widget .items-container .item .list-col {
          max-width: 320px;
          padding: 0; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .casino-widget .items-container .item .list-col {
          max-width: 250px; } }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .list-col {
          width: 100%;
          max-width: 100%;
          position: relative;
          margin: 25px 0 0;
          padding: 20px 15px 10px;
          background-color: #ffffff;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          order: 2;
          -webkit-order: 2;
          -ms-flex-order: 2; }
          .casino-widget .items-container .item .list-col:before {
            content: '';
            position: absolute;
            left: 0;
            top: -20px;
            height: 20px;
            width: 100%;
            background-color: #f0f3f8; }
          .casino-widget .items-container .item .list-col:after {
            content: '';
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: 35px;
            border-left-width: 35px;
            border-top: 15px solid #e0e9f0;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto; } }
      .casino-widget .items-container .item .list-col li {
        position: relative;
        margin: 0;
        padding: 0 0 0 23px;
        line-height: 1.2;
        letter-spacing: -1.2px;
        font-family: "ProximaNova-Semibold"; }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .list-col li {
            font-size: 14px;
            letter-spacing: 0;
            padding: 0 0 0 18px; } }
        .casino-widget .items-container .item .list-col li:before, .casino-widget .items-container .item .list-col li:after {
          content: '';
          position: absolute;
          left: 0;
          top: 15px;
          background-color: #34455f;
          height: 4px; }
          @media (max-width: 767px) {
            .casino-widget .items-container .item .list-col li:before, .casino-widget .items-container .item .list-col li:after {
              top: 8px; } }
        .casino-widget .items-container .item .list-col li:before {
          transform: rotate(45deg) translateX(-1px);
          width: 10px; }
          @media (max-width: 767px) {
            .casino-widget .items-container .item .list-col li:before {
              width: 8px; } }
        .casino-widget .items-container .item .list-col li:after {
          transform: rotate(-45deg) translateX(5px);
          width: 15px; }
          @media (max-width: 767px) {
            .casino-widget .items-container .item .list-col li:after {
              width: 10px; } }
        .casino-widget .items-container .item .list-col li + li {
          margin-top: 15px; }
    .casino-widget .items-container .item .price-col {
      color: #000;
      font-size: 35px;
      line-height: 1.2;
      letter-spacing: -1.5px;
      text-align: center;
      flex-direction: column;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (min-width: 1200px) {
        .casino-widget .items-container .item .price-col {
          font-size: 30px; } }
      @media (min-width: 1530px) {
        .casino-widget .items-container .item .price-col {
          font-size: 35px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .casino-widget .items-container .item .price-col {
          font-size: 25px; } }
      @media (max-width: 767px) {
        .casino-widget .items-container .item .price-col {
          text-align: left;
          font-size: 20px;
          width: 130px;
          padding: 10px;
          height: 80px; } }
      @media (max-width: 350px) {
        .casino-widget .items-container .item .price-col {
          font-size: 17px;
          text-align: center; } }
      .casino-widget .items-container .item .price-col .percent {
        color: #de2a28; }
      .casino-widget .items-container .item .price-col .price {
        color: #de2a28; }
    .casino-widget .items-container .item .rating-col {
      text-align: center;
      flex-direction: column;
      padding: 15px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (max-width: 767px) {
        .casino-widget .items-container .item .rating-col {
          text-align: left;
          position: absolute;
          right: 0;
          top: 58px;
          height: 82px;
          width: 130px;
          padding: 10px; } }
      @media (max-width: 350px) {
        .casino-widget .items-container .item .rating-col {
          text-align: center;
          padding: 5px; } }
      .casino-widget .items-container .item .rating-col .amount {
        color: #1493ff;
        font-size: 35px;
        letter-spacing: -1.5px; }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .rating-col .amount {
            font-size: 20px; } }
      .casino-widget .items-container .item .rating-col .rest {
        color: #000;
        font-size: 19px;
        letter-spacing: -0.75px; }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .rating-col .rest {
            font-size: 12px;
            letter-spacing: 1px; } }
      .casino-widget .items-container .item .rating-col .rating {
        display: block; }
    .casino-widget .items-container .item .btn-col {
      flex-direction: column;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (max-width: 991px) {
        .casino-widget .items-container .item .btn-col {
          background-color: #ffffff;
          padding: 0 15px 10px;
          width: 100%;
          order: 3;
          -webkit-order: 3;
          -ms-flex-order: 3; } }
      .casino-widget .items-container .item .btn-col .btn {
        text-transform: uppercase;
        padding: 12px 35px;
        font-size: 22px;
        border-radius: 34px; }
        @media (min-width: 1200px) {
          .casino-widget .items-container .item .btn-col .btn {
            padding: 12px 25px;
            font-size: 18px; } }
        @media (min-width: 1530px) {
          .casino-widget .items-container .item .btn-col .btn {
            padding: 12px 35px;
            font-size: 22px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .casino-widget .items-container .item .btn-col .btn {
            padding: 12px 25px;
            font-size: 18px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .casino-widget .items-container .item .btn-col .btn .arrow {
            display: none; } }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .btn-col .btn {
            padding: 7px 50px;
            font-size: 15px; } }
      .casino-widget .items-container .item .btn-col .rating-title {
        color: #de2a28;
        font-size: 20px;
        line-height: 1.2;
        text-decoration: underline;
        margin: 10px 0 0;
        font-family: "ProximaNova-Semibold"; }
        @media (max-width: 767px) {
          .casino-widget .items-container .item .btn-col .rating-title {
            font-size: 16px;
            margin: 5px 0 0; } }
  .casino-widget .content-title {
    color: #34455f;
    font-size: 30px;
    font-family: "ProximaNova-Semibold";
    margin: 0 0 20px; }
  .casino-widget .text-content {
    color: #494949;
    font-size: 18px;
    letter-spacing: 0.36px;
    margin: 0 0 40px; }
    @media (max-width: 767px) {
      .casino-widget .text-content {
        font-size: 16px; } }
    .casino-widget .text-content h2 {
      color: #34455f;
      font-family: "ProximaNova-Bold";
      font-size: 35px;
      padding: 25px 0 0; }
      @media (max-width: 767px) {
        .casino-widget .text-content h2 {
          font-size: 20px;
          padding: 10px 0 0; } }
    .casino-widget .text-content h3 {
      color: #34455f;
      font-size: 22px;
      margin: 25px 0 15px; }
      @media (max-width: 767px) {
        .casino-widget .text-content h3 {
          font-size: 17px;
          margin: 10px 0 10px; } }
    .casino-widget .text-content ul {
      padding-left: 20px;
      margin: 15px 0 25px; }
      @media (max-width: 767px) {
        .casino-widget .text-content ul {
          margin: 10px 0 15px; } }

.casino-title-widget {
  padding: 0; }
  .casino-title-widget .text-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    margin: 0 0 40px; }
    .casino-title-widget .text-container .text-holder {
      padding-right: 8%; }
      @media (max-width: 991px) {
        .casino-title-widget .text-container .text-holder {
          padding-right: 0; } }
    .casino-title-widget .text-container .title {
      color: #2759a4;
      font-size: 54px;
      font-family: "ProximaNova-Bold";
      font-style: italic;
      text-transform: uppercase; }
      @media (max-width: 991px) {
        .casino-title-widget .text-container .title {
          font-size: 40px; } }
      @media (max-width: 767px) {
        .casino-title-widget .text-container .title {
          font-size: 30px; } }
      .casino-title-widget .text-container .title.underline {
        position: relative; }
        .casino-title-widget .text-container .title.underline:after {
          content: '';
          display: block;
          margin: 3px 0 0;
          width: 100%;
          height: 4px;
          background-color: rgba(99, 99, 99, 0.44); }
    .casino-title-widget .text-container .text {
      color: #494949;
      font-family: "ProximaNova-Bold";
      font-size: 15px;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-align: center; }
    .casino-title-widget .text-container .logo {
      min-width: 13%; }
      @media (max-width: 991px) {
        .casino-title-widget .text-container .logo {
          display: none; } }
    .casino-title-widget .text-container .logo_mobile {
      display: none; }
      @media (max-width: 991px) {
        .casino-title-widget .text-container .logo_mobile {
          display: inline-block;
          max-width: 100px;
          margin-left: 30px;
          vertical-align: middle; } }
      @media (max-width: 767px) {
        .casino-title-widget .text-container .logo_mobile {
          max-width: 68px; } }

.slider-widget {
  padding: 0 0 54px; }
  .slider-widget .tab-nav {
    background-color: #299cff; }
    .slider-widget .tab-nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center; }
      .slider-widget .tab-nav ul li {
        display: inline-block; }
        .slider-widget .tab-nav ul li a {
          color: #ffffff;
          font-size: 24px;
          padding: 15px 25px;
          display: block; }
          .slider-widget .tab-nav ul li a.active {
            font-family: "ProximaNova-Bold";
            background-color: #2759a4; }
          @media (max-width: 767px) {
            .slider-widget .tab-nav ul li a {
              font-size: 18px;
              padding: 15px 20px; } }
  .slider-widget .tab-content {
    padding: 56px 0 0; }
    @media (max-width: 767px) {
      .slider-widget .tab-content {
        padding: 30px 0 0; } }
    @media (min-width: 1200px) {
      .slider-widget .tab-content .container {
        max-width: 1015px; } }
    @media (min-width: 1530px) {
      .slider-widget .tab-content .container {
        max-width: 1315px; } }
    @media (max-width: 767px) {
      .slider-widget .tab-content .container {
        padding: 0; } }
    .slider-widget .tab-content .tab-pane {
      overflow-y: hidden;
      height: 0;
      display: block; }
      .slider-widget .tab-content .tab-pane.active {
        overflow-y: visible;
        height: auto; }

.info-cta-widget {
  background: url("../img/info-cta.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -20px;
  padding: 60px 0 60px; }
  @media (max-width: 767px) {
    .info-cta-widget {
      padding: 20px 0 20px; } }
  .info-cta-widget:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 24, 64, 0.2);
    z-index: 1; }
  .info-cta-widget .container {
    position: relative;
    z-index: 2; }
  .info-cta-widget .info-cta-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .info-cta-widget .info-cta-container {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .info-cta-widget .img-holder {
    position: relative; }
    @media (max-width: 991px) {
      .info-cta-widget .img-holder {
        text-align: center;
        width: 100%;
        order: 2;
        -webkit-order: 2;
        -ms-flex-order: 2; } }
    @media (max-width: 560px) {
      .info-cta-widget .img-holder.devicetype-laptop .game_image {
        max-width: 40vw;
        top: 4vw; } }
    .info-cta-widget .img-holder.devicetype-tablet {
      transform: rotate(-15deg); }
      .info-cta-widget .img-holder.devicetype-tablet .game_image {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 75%; }
        @media (max-width: 991px) {
          .info-cta-widget .img-holder.devicetype-tablet .game_image {
            max-width: 170px; } }
    .info-cta-widget .img-holder .game_image {
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: 0 auto; }
  .info-cta-widget .text-holder {
    width: 57%;
    text-align: right; }
    @media (max-width: 991px) {
      .info-cta-widget .text-holder {
        text-align: center;
        width: 100%;
        order: 1;
        -webkit-order: 1;
        -ms-flex-order: 1; } }
    .info-cta-widget .text-holder .title {
      color: #fefefe;
      font-family: "ProximaNova-Bold";
      font-size: 49px;
      font-style: italic;
      letter-spacing: 1.75px; }
      @media (max-width: 767px) {
        .info-cta-widget .text-holder .title {
          font-size: 24px;
          margin: 0 0 10px; } }
    .info-cta-widget .text-holder .text {
      color: #fefefe;
      font-size: 20px;
      font-style: italic;
      letter-spacing: 0.9px;
      line-height: 1.2; }
      @media (max-width: 767px) {
        .info-cta-widget .text-holder .text {
          font-size: 13px; } }
    .info-cta-widget .text-holder .link {
      color: #299cff;
      font-family: "ProximaNova-Bold";
      letter-spacing: 1px;
      text-decoration: underline; }
      @media (max-width: 991px) {
        .info-cta-widget .text-holder .link {
          display: block; } }
      @media (max-width: 767px) {
        .info-cta-widget .text-holder .link {
          font-size: 16px; } }
    .info-cta-widget .text-holder .btn {
      display: inline-block;
      margin: 25px 0 0;
      padding: 10px 38px;
      font-size: 27px;
      border-radius: 34px; }
      @media (max-width: 991px) {
        .info-cta-widget .text-holder .btn {
          margin: 25px 0 25px; } }
      @media (max-width: 767px) {
        .info-cta-widget .text-holder .btn {
          font-size: 18px;
          padding: 6px 25px; } }

.info-hold-widget {
  background: url("../img/info-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px 0 0; }
  .info-hold-widget .info-cta-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .info-hold-widget .info-cta-container {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .info-hold-widget .img-holder {
    max-height: 475px;
    overflow: hidden; }
    @media (max-width: 991px) {
      .info-hold-widget .img-holder {
        text-align: center;
        width: 100%; } }
    .info-hold-widget .img-holder img {
      max-width: 100%; }
    .info-hold-widget .img-holder .label {
      position: absolute;
      right: 0; }
      .info-hold-widget .img-holder .label.top {
        top: 0; }
        @media (max-width: 767px) {
          .info-hold-widget .img-holder .label.top img {
            max-width: 60px; } }
      .info-hold-widget .img-holder .label.center {
        top: 50%;
        margin-top: -45px; }
        .info-hold-widget .img-holder .label.center img {
          max-height: 90px; }
  .info-hold-widget .text-holder {
    width: 48%; }
    @media (max-width: 991px) {
      .info-hold-widget .text-holder {
        text-align: center;
        width: 100%; } }
    .info-hold-widget .text-holder .title {
      color: #1f375b;
      font-family: "ProximaNova-Bold";
      font-size: 45px;
      font-style: italic;
      text-transform: uppercase;
      margin: 0 0 45px; }
      @media (max-width: 767px) {
        .info-hold-widget .text-holder .title {
          font-size: 24px;
          margin: 0 0 10px;
          padding: 0 40px; } }
    .info-hold-widget .text-holder .text {
      color: #595756;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 1.2;
      margin: 25px 0 0; }
      @media (max-width: 767px) {
        .info-hold-widget .text-holder .text {
          font-size: 13px;
          margin: 0; } }
    .info-hold-widget .text-holder .link {
      color: #1f375b;
      font-family: "ProximaNova-Semibold";
      font-size: 20px;
      text-decoration: underline;
      text-transform: uppercase;
      display: block;
      margin: 40px 0 0; }
      @media (max-width: 767px) {
        .info-hold-widget .text-holder .link {
          font-size: 14px;
          margin: 7px 0 0; } }

.gambling-widget {
  background: url("../img/gambling-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #2759a4;
  padding: 55px 0 0; }
  @media (max-width: 767px) {
    .gambling-widget {
      padding: 22px 0 0; } }
  .gambling-widget.gamecat {
    background-image: url("../img/gambling_category.jpg");
    background-size: 100%;
    background-color: #34455f;
    margin-top: 3px; }
    .gambling-widget.gamecat .items-container {
      max-width: 1125px;
      margin: 0 auto; }
    .gambling-widget.gamecat .item {
      width: 50%; }
      @media (min-width: 1200px) {
        .gambling-widget.gamecat .item {
          max-width: 440px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .gambling-widget.gamecat .item {
          max-width: 380px; } }
      @media (max-width: 480px) {
        .gambling-widget.gamecat .item {
          width: 100%;
          max-width: 280px; } }
  .gambling-widget .text-holder {
    color: #ffffff;
    text-align: center;
    max-width: 1075px;
    margin: auto; }
    .gambling-widget .text-holder p {
      font-size: 15px;
      line-height: 1.2; }
      @media (max-width: 767px) {
        .gambling-widget .text-holder p {
          margin: 10px 0 0; } }
  .gambling-widget .title {
    font-family: "ProximaNova-Bold";
    font-size: 50px;
    font-style: italic;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .gambling-widget .title {
        font-size: 35px; } }
    @media (max-width: 767px) {
      .gambling-widget .title {
        font-size: 24px; } }
  .gambling-widget .items-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -moz-box-pack: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 25px 0 80px; }
    @media (max-width: 767px) {
      .gambling-widget .items-container {
        padding: 25px 0 20px; } }
  .gambling-widget .item {
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    width: 33%;
    margin: 125px 0 0; }
    @media (min-width: 1200px) {
      .gambling-widget .item {
        max-width: 350px; } }
    @media (min-width: 1530px) {
      .gambling-widget .item {
        max-width: 440px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .gambling-widget .item {
        max-width: 280px; } }
    @media (max-width: 991px) {
      .gambling-widget .item {
        max-width: 320px;
        width: 50%; } }
    @media (max-width: 767px) {
      .gambling-widget .item {
        margin: 40px 0 20px;
        max-width: 202px; } }
    @media (max-width: 480px) {
      .gambling-widget .item {
        width: 100%;
        max-width: 280px; } }
    .gambling-widget .item .img-holder {
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      margin: -85px auto 0;
      width: 170px;
      height: 170px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (max-width: 767px) {
        .gambling-widget .item .img-holder {
          margin: -40px auto 0;
          width: 80px;
          height: 80px; } }
      .gambling-widget .item .img-holder img {
        display: block;
        max-width: 100%; }
        @media (max-width: 767px) {
          .gambling-widget .item .img-holder img {
            max-width: 40px; } }
    .gambling-widget .item .text-holder {
      padding: 10px 50px 40px; }
      @media (max-width: 767px) {
        .gambling-widget .item .text-holder {
          padding: 10px 15px 20px; } }
      .gambling-widget .item .text-holder .item-title {
        color: #34455f;
        font-family: "ProximaNova-Semibold";
        font-size: 30px; }
        @media (max-width: 767px) {
          .gambling-widget .item .text-holder .item-title {
            font-size: 18px; } }
      .gambling-widget .item .text-holder p {
        color: #898989;
        font-size: 17px;
        line-height: 1.2;
        margin: 25px 0 0; }
        @media (max-width: 767px) {
          .gambling-widget .item .text-holder p {
            font-size: 13px; } }
    .gambling-widget .item .link {
      color: #ff0000;
      font-family: "ProximaNova-Bold";
      font-size: 18px;
      text-decoration: underline;
      display: block;
      margin: 17px 0 0; }
      @media (max-width: 767px) {
        .gambling-widget .item .link {
          font-size: 14px; } }

.faq-widget {
  background-color: #f0f3f8;
  padding: 80px 0 80px; }
  @media (max-width: 767px) {
    .faq-widget {
      padding: 30px 0 40px; } }
  .faq-widget .title {
    font-family: "ProximaNova-Bold";
    font-size: 35px;
    text-align: center;
    margin: 0 0 43px; }
    @media (max-width: 767px) {
      .faq-widget .title {
        font-size: 20px;
        margin: 0 0 15px; } }
  .faq-widget .card {
    border-top: 2px solid #a1a1a1;
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    margin: 0 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 767px) {
      .faq-widget .card {
        margin: 0 10px; } }
    .faq-widget .card:first-child {
      border-top: 0; }
    .faq-widget .card:last-child {
      border-bottom: 2px solid #a1a1a1; }
    .faq-widget .card.active {
      background-color: #e1e4e7;
      border-radius: 10px;
      border-color: transparent;
      margin: 0;
      padding: 0 50px 30px; }
      @media (max-width: 767px) {
        .faq-widget .card.active {
          padding: 0 10px 20px; } }
      .faq-widget .card.active + .card {
        border-top: 0; }
      .faq-widget .card.active .accordion-arrow {
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
      .faq-widget .card.active .card-link {
        padding: 22px 0 10px; }
        @media (max-width: 767px) {
          .faq-widget .card.active .card-link {
            padding: 15px 0 10px; } }
  .faq-widget .accordion {
    max-width: 1200px;
    margin: auto; }
  .faq-widget .card-header .card-link {
    color: #299cff;
    font-family: "ProximaNova-Semibold";
    font-size: 23px;
    padding: 27px 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .faq-widget .card-header .card-link {
        font-size: 18px;
        padding: 20px 0; } }
  .faq-widget .card-header .accordion-arrow {
    display: inline-block;
    position: relative;
    height: 35px;
    min-width: 35px;
    margin-left: 15px;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease; }
    @media (max-width: 767px) {
      .faq-widget .card-header .accordion-arrow {
        height: 25px;
        min-width: 25px; } }
    .faq-widget .card-header .accordion-arrow:before, .faq-widget .card-header .accordion-arrow:after {
      content: '';
      top: 50%;
      height: 3px;
      width: 13px;
      position: absolute;
      background-color: #5b5b5b; }
      @media (max-width: 767px) {
        .faq-widget .card-header .accordion-arrow:before, .faq-widget .card-header .accordion-arrow:after {
          width: 8px; } }
    .faq-widget .card-header .accordion-arrow:before {
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 7px; }
    .faq-widget .card-header .accordion-arrow:after {
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      right: 7px; }
      @media (max-width: 767px) {
        .faq-widget .card-header .accordion-arrow:after {
          right: 6px; } }
  .faq-widget .card-body {
    color: #545454;
    font-size: 16px;
    line-height: 1.2; }
    @media (max-width: 767px) {
      .faq-widget .card-body {
        font-size: 15px; } }
  .faq-widget .link-more {
    color: #2759a4;
    font-family: "ProximaNova-Semibold";
    font-size: 23px;
    text-decoration: underline;
    display: block;
    text-align: center;
    margin: 55px 0 0; }
    @media (max-width: 767px) {
      .faq-widget .link-more {
        font-size: 18px;
        margin: 40px 0 0; } }

.payment-widget {
  background-color: #2759a4;
  padding: 25px 0;
  text-align: center; }
  @media (max-width: 767px) {
    .payment-widget {
      padding: 13px 0; } }
  .payment-widget .payment-container {
    list-style-type: none;
    padding: 0; }
    .payment-widget .payment-container li {
      display: inline-block;
      padding: 0 15px; }
      @media (max-width: 767px) {
        .payment-widget .payment-container li {
          padding: 0 8px; } }
      .payment-widget .payment-container li a {
        display: inline-block; }
        @media (max-width: 767px) {
          .payment-widget .payment-container li a {
            max-width: 40px; } }
  .payment-widget .grayscale:hover {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%); }

.anchor-nav-widget {
  background-color: #f0f3f8; }
  .anchor-nav-widget .list {
    list-style-type: none;
    padding: 0; }
    @media (max-width: 991px) {
      .anchor-nav-widget .list {
        text-align: center; } }
    .anchor-nav-widget .list li {
      display: inline-block;
      text-align: center; }
      .anchor-nav-widget .list li a {
        color: #34455f;
        display: block;
        padding: 10px 18px 0;
        position: relative;
        line-height: 1.5;
        font-size: 17px; }
        @media (max-width: 767px) {
          .anchor-nav-widget .list li a {
            padding: 10px 10px 0; } }
        .anchor-nav-widget .list li a:after {
          content: "";
          position: absolute;
          width: 0;
          height: 3px;
          left: 0;
          bottom: -1px;
          background-color: #39a3fe;
          visibility: hidden;
          -moz-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          -ms-transition: all 0.3s ease-in-out 0s;
          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s; }
        .anchor-nav-widget .list li a:hover {
          color: #39a3fe; }
          .anchor-nav-widget .list li a:hover:after {
            visibility: visible;
            width: 100%; }
    .anchor-nav-widget .list .img-holder img {
      vertical-align: middle; }

.anchor-nav-category .list .img-holder img {
  max-height: 30px; }

.list-categories-widget {
  background-color: #f0f3f8;
  padding: 62px 0 62px; }
  @media (max-width: 767px) {
    .list-categories-widget {
      padding: 27px 0 27px; } }
  .list-categories-widget .categories-row + .categories-row {
    margin-top: 15px; }
  .list-categories-widget .main-column,
  .list-categories-widget .sidebar-column {
    position: relative; }
  .list-categories-widget .main-column {
    padding: 0 35px 30px;
    height: 100%;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    .list-categories-widget .main-column .btn-holder {
      padding: 20px 0 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end; }
    @media (max-width: 767px) {
      .list-categories-widget .main-column {
        padding: 0 20px 30px; } }
    .list-categories-widget .main-column h3 {
      color: #34455f;
      font-size: 22px;
      margin: 25px 0 15px; }
      @media (max-width: 767px) {
        .list-categories-widget .main-column h3 {
          font-size: 17px;
          margin: 10px 0 10px; } }
    .list-categories-widget .main-column p {
      color: #000;
      font-size: 20px;
      line-height: 1.2;
      margin: 0 0 25px; }
      @media (max-width: 767px) {
        .list-categories-widget .main-column p {
          font-size: 15px;
          margin: 0 0 10px; } }
      .list-categories-widget .main-column p:last-child {
        margin: 0; }
    .list-categories-widget .main-column ul {
      padding-left: 20px;
      margin: 0 0 25px; }
      @media (max-width: 767px) {
        .list-categories-widget .main-column ul {
          margin: 0 0 10px; } }
    .list-categories-widget .main-column .text-in {
      margin: 25px 0 0; }
      @media (max-width: 767px) {
        .list-categories-widget .main-column .text-in {
          margin: 10px 0 0; } }
    .list-categories-widget .main-column .collapse {
      margin: 25px 0 0; }
      @media (max-width: 767px) {
        .list-categories-widget .main-column .collapse {
          margin: 15px 0 0; } }
      .list-categories-widget .main-column .collapse p {
        margin: 0 0 25px; }
        .list-categories-widget .main-column .collapse p:last-child {
          margin: 0; }
        @media (max-width: 767px) {
          .list-categories-widget .main-column .collapse p {
            margin: 0 0 10px; } }
  .list-categories-widget .title {
    color: #34455f;
    font-family: "ProximaNova-Bold";
    font-size: 35px;
    padding: 25px 0 0; }
    @media (max-width: 767px) {
      .list-categories-widget .title {
        font-size: 20px;
        padding: 10px 0 0; } }
  .list-categories-widget .read-more-link {
    color: #e70021;
    font-family: "ProximaNova-Semibold";
    font-size: 17px;
    text-decoration: underline;
    padding: 0; }
    .list-categories-widget .read-more-link .more {
      display: none; }
    .list-categories-widget .read-more-link .less {
      display: block; }
    .list-categories-widget .read-more-link.collapsed .more {
      display: block; }
    .list-categories-widget .read-more-link.collapsed .less {
      display: none; }
  .list-categories-widget .read-more-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 20px;
    width: 20px;
    padding: 0; }
    .list-categories-widget .read-more-btn:before, .list-categories-widget .read-more-btn:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #34455f;
      -moz-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      -ms-transition: all 0.3s ease-in-out 0s;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s; }
    .list-categories-widget .read-more-btn:before {
      transform: rotate(0deg); }
    .list-categories-widget .read-more-btn:after {
      transform: rotate(90deg);
      display: none; }
    .list-categories-widget .read-more-btn.collapsed:after {
      display: block; }
  .list-categories-widget .sidebar-column {
    overflow: hidden;
    padding: 0; }
    @media (max-width: 991px) {
      .list-categories-widget .sidebar-column {
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 15px; } }
    .list-categories-widget .sidebar-column .title {
      background-color: #2759a4;
      color: #ffffff;
      padding: 19px 15px 19px;
      font-size: 25px; }
      @media (max-width: 767px) {
        .list-categories-widget .sidebar-column .title {
          padding: 13px 10px 13px;
          font-size: 18px; } }
    .list-categories-widget .sidebar-column .sidebar-inner {
      padding: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: flex-start;
      -moz-box-align: flex-start;
      -ms-flex-align: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .list-categories-widget .sidebar-column .sidebar-inner .list {
        list-style-type: none;
        margin: 0; }
        .list-categories-widget .sidebar-column .sidebar-inner .list li {
          display: inline-block;
          color: #000;
          font-size: 15px;
          position: relative;
          line-height: 1.2; }
          .list-categories-widget .sidebar-column .sidebar-inner .list li a {
            color: #000;
            display: block;
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            filter: grayscale(100%);
            -moz-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease; }
            .list-categories-widget .sidebar-column .sidebar-inner .list li a.active {
              -webkit-filter: grayscale(0);
              -moz-filter: grayscale(0);
              filter: grayscale(0); }
            @media (max-width: 767px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list li a {
                font-size: 14px; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list li .plus-ico,
          .list-categories-widget .sidebar-column .sidebar-inner .list li .minus-ico {
            position: absolute;
            height: 9px;
            width: 9px;
            left: 0;
            top: 8px; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.overview-list li {
          font-family: "ProximaNova-Light"; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.games-list {
          padding: 35px 7px 0;
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: space-between;
          -webkit-justify-content: space-between;
          -moz-box-pack: space-between;
          -ms-flex-pack: space-between;
          justify-content: space-between; }
          @media (max-width: 1199px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.games-list {
              padding: 15px 7px 0; } }
          @media (max-width: 991px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.games-list {
              -webkit-box-pack: flex-start;
              -webkit-justify-content: flex-start;
              -moz-box-pack: flex-start;
              -ms-flex-pack: flex-start;
              justify-content: flex-start; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.games-list li {
            text-align: center;
            padding: 0 4px 0;
            margin-bottom: 25px;
            width: 20%; }
            @media (min-width: 992px) and (max-width: 1199px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.games-list li {
                width: 50%; } }
            @media (max-width: 480px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.games-list li {
                width: 50%; } }
            .list-categories-widget .sidebar-column .sidebar-inner .list.games-list li .i-ico {
              margin: 17px auto 0;
              min-height: 40px;
              max-width: 40px;
              display: -webkit-box;
              display: -webkit-flex;
              display: -moz-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              -webkit-flex-wrap: wrap;
              -moz-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              -webkit-box-pack: center;
              -webkit-justify-content: center;
              -moz-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list {
          padding: 40px 25px 0;
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
          @media (max-width: 1199px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list {
              padding: 20px 25px 0; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list li {
            width: 50%;
            margin-bottom: 15px;
            padding-right: 10px; }
            @media (min-width: 992px) and (max-width: 1199px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list li {
                width: 100%;
                padding-right: 0; } }
            @media (max-width: 480px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list li {
                width: 100%;
                padding-right: 0; } }
            .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list li a {
              display: -webkit-box;
              display: -webkit-flex;
              display: -moz-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; }
          .list-categories-widget .sidebar-column .sidebar-inner .list.promotions-list .i-ico {
            margin-right: 12px;
            max-width: 40px; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.platforms-list {
          padding: 40px 25px 0;
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: space-around;
          -webkit-justify-content: space-around;
          -moz-box-pack: space-around;
          -ms-flex-pack: space-around;
          justify-content: space-around; }
          @media (max-width: 1199px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.platforms-list {
              padding: 20px 25px 0; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.platforms-list li {
            width: 33.333%;
            text-align: center;
            margin-bottom: 50px; }
            @media (max-width: 767px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.platforms-list li {
                margin-bottom: 25px; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.platforms-list .i-ico {
            margin-bottom: 8px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list {
          padding: 40px 25px 0;
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
          @media (max-width: 1199px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list {
              padding: 20px 25px 11px; } }
          @media (max-width: 991px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list {
              max-width: 400px;
              margin: 0 auto; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list li {
            margin-bottom: 9px;
            text-align: center;
            padding: 0 5px;
            width: 25%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center; }
            @media (min-width: 992px) and (max-width: 1199px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list li {
                width: 33.33%; } }
            @media (max-width: 991px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list li {
                width: 25%; } }
            .list-categories-widget .sidebar-column .sidebar-inner .list.payment-list li .i-ico {
              display: inline-block;
              max-width: 61px; }
        .list-categories-widget .sidebar-column .sidebar-inner .list.support-list {
          padding: 40px 25px 0;
          width: 100%; }
          @media (max-width: 1199px) {
            .list-categories-widget .sidebar-column .sidebar-inner .list.support-list {
              padding: 20px 25px 0; } }
          .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li {
            width: 100%;
            margin-bottom: 40px; }
            @media (max-width: 767px) {
              .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li {
                margin-bottom: 30px; } }
            .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li a {
              display: -webkit-box;
              display: -webkit-flex;
              display: -moz-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              pointer-events: none; }
              .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li a .list-title {
                color: #818181; }
              .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li a.active {
                pointer-events: unset; }
                .list-categories-widget .sidebar-column .sidebar-inner .list.support-list li a.active .list-title {
                  color: #299cff; }
          .list-categories-widget .sidebar-column .sidebar-inner .list.support-list .i-ico {
            max-width: 52px; }
          .list-categories-widget .sidebar-column .sidebar-inner .list.support-list .text-holder {
            color: #000;
            font-size: 12px;
            margin: 0 0 0 22px; }
          .list-categories-widget .sidebar-column .sidebar-inner .list.support-list .list-title {
            color: #299cff;
            display: block;
            font-size: 14px; }
      .list-categories-widget .sidebar-column .sidebar-inner .half {
        flex: 50%;
        width: 50%;
        height: 100%;
        padding: 25px 10px; }
        .list-categories-widget .sidebar-column .sidebar-inner .half > li {
          padding: 0 0 0 15px; }
      .list-categories-widget .sidebar-column .sidebar-inner .border-right {
        border-right: 2px solid #f0f3f8; }
    .list-categories-widget .sidebar-column .video-holder {
      padding: 25px 7px 15px;
      width: 100%; }
    .list-categories-widget .sidebar-column .html-place {
      padding: 25px 7px 15px;
      text-align: center;
      width: 100%; }
    .list-categories-widget .sidebar-column .verdict-holder {
      padding: 50px 24px 20px;
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 992px) and (max-width: 1199px) {
        .list-categories-widget .sidebar-column .verdict-holder {
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
      @media (max-width: 1199px) {
        .list-categories-widget .sidebar-column .verdict-holder {
          padding: 28px 20px 28px;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; } }
      .list-categories-widget .sidebar-column .verdict-holder .i-ico {
        position: relative; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .list-categories-widget .sidebar-column .verdict-holder .i-ico {
            text-align: center;
            width: 100%; } }
      .list-categories-widget .sidebar-column .verdict-holder .rating {
        display: block;
        padding: 40px 0 0 30px; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .list-categories-widget .sidebar-column .verdict-holder .rating {
            padding: 20px 0 0 0;
            text-align: center;
            width: 100%; } }
        @media (max-width: 991px) {
          .list-categories-widget .sidebar-column .verdict-holder .rating {
            padding: 70px 0 0 40px; } }
        @media (max-width: 767px) {
          .list-categories-widget .sidebar-column .verdict-holder .rating {
            padding: 60px 0 0 40px; } }
      .list-categories-widget .sidebar-column .verdict-holder .digit {
        position: absolute;
        right: 0;
        bottom: 50px; }
        @media (max-width: 1530px) {
          .list-categories-widget .sidebar-column .verdict-holder .digit {
            right: -20px;
            bottom: 28px; } }
        @media (min-width: 992px) and (max-width: 1199px) {
          .list-categories-widget .sidebar-column .verdict-holder .digit {
            right: 30px;
            bottom: 50px; } }
        @media (max-width: 991px) {
          .list-categories-widget .sidebar-column .verdict-holder .digit {
            right: 0;
            bottom: 50px; } }
        @media (max-width: 370px) {
          .list-categories-widget .sidebar-column .verdict-holder .digit {
            bottom: 10vw; } }
      .list-categories-widget .sidebar-column .verdict-holder .rating-title {
        color: #000;
        font-family: "ProximaNova-Semibold";
        font-size: 24px; }
      .list-categories-widget .sidebar-column .verdict-holder .amount {
        color: #1493ff;
        font-size: 46px;
        letter-spacing: -1.5px;
        font-family: "ProximaNova-Semibold"; }
        @media (max-width: 1530px) {
          .list-categories-widget .sidebar-column .verdict-holder .amount {
            font-size: 35px; } }
        @media (max-width: 1199px) {
          .list-categories-widget .sidebar-column .verdict-holder .amount {
            font-size: 46px; } }
        @media (max-width: 370px) {
          .list-categories-widget .sidebar-column .verdict-holder .amount {
            font-size: 10vw; } }
      .list-categories-widget .sidebar-column .verdict-holder .rest {
        color: #000;
        font-size: 22px;
        letter-spacing: -0.75px;
        font-family: "ProximaNova-Semibold"; }
        @media (max-width: 767px) {
          .list-categories-widget .sidebar-column .verdict-holder .rest {
            font-size: 25px;
            letter-spacing: 1px; } }
        @media (max-width: 370px) {
          .list-categories-widget .sidebar-column .verdict-holder .rest {
            font-size: 5vw; } }
  .list-categories-widget .plus-ico {
    display: inline-block; }
    .list-categories-widget .plus-ico:before, .list-categories-widget .plus-ico:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #60b511; }
    .list-categories-widget .plus-ico:before {
      transform: rotate(0deg); }
    .list-categories-widget .plus-ico:after {
      transform: rotate(90deg); }
  .list-categories-widget .minus-ico {
    display: inline-block; }
    .list-categories-widget .minus-ico:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #e70021; }
    .list-categories-widget .minus-ico:before {
      transform: rotate(0deg); }

.filters-widget {
  padding: 70px 0 70px;
  background-color: #f0f3f8; }
  .filters-widget .filters-row {
    margin-bottom: 10px; }
  @media (max-width: 991px) {
    .filters-widget .main-filter {
      order: 2;
      -webkit-order: 2;
      -ms-flex-order: 2; } }
  @media (max-width: 991px) {
    .filters-widget .display-filter {
      margin: 0 0 25px;
      order: 1;
      -webkit-order: 1;
      -ms-flex-order: 1; } }
  .filters-widget .main-column {
    background-color: #ffffff;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .filters-widget .main-column {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .filters-widget .main-column .l-col {
      background-color: #e0e9f0;
      text-align: center;
      padding: 22px 10px;
      border-top: 2px solid #34455f;
      border-bottom: 2px solid #34455f;
      border-left: 2px solid #34455f;
      flex: 20%;
      width: 20%; }
      @media (max-width: 991px) {
        .filters-widget .main-column .l-col {
          flex: 100%;
          width: 100%;
          border-bottom: 0;
          border-right: 2px solid #34455f;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
      @media (max-width: 991px) {
        .filters-widget .main-column .l-col .img-holder {
          width: 100%; } }
      @media (max-width: 991px) {
        .filters-widget .main-column .l-col .text-holder {
          width: 50%; } }
      .filters-widget .main-column .l-col .text-holder + .text-holder {
        margin: 12px 0 0; }
        @media (max-width: 991px) {
          .filters-widget .main-column .l-col .text-holder + .text-holder {
            margin: 0; } }
      .filters-widget .main-column .l-col .price {
        color: #de2a28;
        font-size: 35px;
        font-family: "ProximaNova-Bold";
        letter-spacing: -1.5px;
        display: block;
        line-height: 0.9;
        padding: 13px 0 0; }
      .filters-widget .main-column .l-col .bonus {
        color: #34455f;
        font-size: 20px;
        letter-spacing: -1px;
        display: block;
        line-height: 1; }
      .filters-widget .main-column .l-col .deposit {
        color: #34455f;
        font-size: 15px;
        letter-spacing: -0.75px;
        display: block;
        line-height: 1; }
    .filters-widget .main-column .m-col {
      padding: 15px 47px 25px;
      border-top: 2px solid #34455f;
      border-bottom: 2px solid #34455f;
      flex: 50%;
      width: 50%; }
      @media (max-width: 991px) {
        .filters-widget .main-column .m-col {
          border-top: 0;
          border-bottom: 0;
          border-left: 2px solid #34455f;
          border-right: 2px solid #34455f;
          flex: 100%;
          width: 100%;
          text-align: center;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; } }
      .filters-widget .main-column .m-col .title {
        color: #1493ff;
        font-family: "ProximaNova-Bold";
        font-size: 45px;
        text-transform: uppercase;
        letter-spacing: -2.25px; }
        @media (max-width: 991px) {
          .filters-widget .main-column .m-col .title {
            width: 100%;
            order: 1;
            -webkit-order: 1;
            -ms-flex-order: 1; } }
        @media (max-width: 767px) {
          .filters-widget .main-column .m-col .title {
            font-size: 32px; } }
      .filters-widget .main-column .m-col .list {
        color: #000000;
        font-size: 18px;
        letter-spacing: -0.75px;
        max-width: 320px;
        margin: 11px 0 0;
        list-style-type: none;
        flex-direction: column;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        @media (max-width: 991px) {
          .filters-widget .main-column .m-col .list {
            margin: 11px auto 0;
            text-align: left;
            width: 100%;
            order: 3;
            -webkit-order: 3;
            -ms-flex-order: 3; } }
        .filters-widget .main-column .m-col .list li {
          position: relative;
          margin: 0;
          padding: 0 0 0 23px;
          line-height: 1.2;
          letter-spacing: -1.2px;
          font-family: "ProximaNova-Semibold"; }
          .filters-widget .main-column .m-col .list li:before, .filters-widget .main-column .m-col .list li:after {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            background-color: #34455f;
            height: 4px; }
          .filters-widget .main-column .m-col .list li:before {
            transform: rotate(45deg) translateX(-1px);
            width: 10px; }
          .filters-widget .main-column .m-col .list li:after {
            transform: rotate(-45deg) translateX(5px);
            width: 15px; }
          .filters-widget .main-column .m-col .list li + li {
            margin-top: 15px; }
      .filters-widget .main-column .m-col .rating {
        margin: 15px 0 0 25px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; }
        @media (max-width: 991px) {
          .filters-widget .main-column .m-col .rating {
            margin: 0;
            width: 100%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            order: 2;
            -webkit-order: 2;
            -ms-flex-order: 2; } }
      .filters-widget .main-column .m-col .rating-title {
        color: #de2a28;
        font-family: "ProximaNova-Semibold";
        font-size: 18px;
        text-decoration: underline;
        letter-spacing: -0.77px;
        display: inline-block;
        margin: 0 0 0 25px; }
      .filters-widget .main-column .m-col .btn-col {
        margin: 15px 0 0 0; }
        @media (max-width: 991px) {
          .filters-widget .main-column .m-col .btn-col {
            order: 4;
            -webkit-order: 4;
            -ms-flex-order: 4; } }
        .filters-widget .main-column .m-col .btn-col .btn {
          display: inline-block;
          text-transform: uppercase; }
          @media (min-width: 1530px) {
            .filters-widget .main-column .m-col .btn-col .btn + .btn {
              margin: 0 0 0 30px; } }
          @media (max-width: 1530px) {
            .filters-widget .main-column .m-col .btn-col .btn + .btn {
              margin: 15px 0 0; } }
          @media (max-width: 991px) {
            .filters-widget .main-column .m-col .btn-col .btn + .btn {
              margin: 0 0 0 30px; } }
          @media (max-width: 575px) {
            .filters-widget .main-column .m-col .btn-col .btn + .btn {
              display: block;
              margin: 15px 0 0; } }
          .filters-widget .main-column .m-col .btn-col .btn.green-btn {
            font-size: 20px;
            padding: 9px 37px; }
            @media (max-width: 767px) {
              .filters-widget .main-column .m-col .btn-col .btn.green-btn {
                font-size: 16px;
                padding: 6px 33px; } }
          .filters-widget .main-column .m-col .btn-col .btn.blue-gr {
            font-size: 20px;
            padding: 9px 30px; }
            @media (max-width: 767px) {
              .filters-widget .main-column .m-col .btn-col .btn.blue-gr {
                font-size: 16px;
                padding: 6px 49px; } }
    .filters-widget .main-column .r-col {
      background-color: #34455f;
      color: #ffffff;
      flex: 30%;
      width: 30%;
      padding: 22px 10px; }
      .filters-widget .main-column .r-col .title {
        color: #ffffff;
        font-family: "ProximaNova-Bold";
        font-size: 30px;
        letter-spacing: -1.5px;
        text-align: center; }
        @media (max-width: 767px) {
          .filters-widget .main-column .r-col .title {
            font-size: 24px;
            letter-spacing: -0.5px; } }
      .filters-widget .main-column .r-col .list {
        list-style-type: none;
        padding: 28px 0 0;
        margin: 0;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .filters-widget .main-column .r-col .list li {
          width: 50%;
          margin-bottom: 10px;
          display: inline-block;
          color: #c2c2c2;
          font-size: 14px;
          position: relative;
          line-height: 1.2;
          letter-spacing: -0.75px;
          padding: 0 0 0 30px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; }
          @media (max-width: 1530px) {
            .filters-widget .main-column .r-col .list li {
              width: 100%; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .filters-widget .main-column .r-col .list li {
              width: 33.33%; } }
          @media (max-width: 767px) {
            .filters-widget .main-column .r-col .list li {
              width: 50%; } }
          .filters-widget .main-column .r-col .list li.active {
            color: #ffffff;
            font-family: "ProximaNova-Semibold"; }
            .filters-widget .main-column .r-col .list li.active .i-name {
              position: relative; }
              .filters-widget .main-column .r-col .list li.active .i-name:before, .filters-widget .main-column .r-col .list li.active .i-name:after {
                content: '';
                position: absolute;
                background-color: #ffffff;
                height: 2px; }
              .filters-widget .main-column .r-col .list li.active .i-name:before {
                transform: rotate(45deg) translateX(-1px);
                width: 6px;
                top: 9px;
                left: -12px; }
              .filters-widget .main-column .r-col .list li.active .i-name:after {
                transform: rotate(-45deg) translateX(5px);
                width: 9px;
                left: -13px;
                top: 10px; }
          .filters-widget .main-column .r-col .list li .i-ico {
            display: inline-block;
            margin: 0 19px 0 0;
            text-align: center;
            width: 20px; }
  .filters-widget .sidebar-column {
    background-color: #ffffff; }
    .filters-widget .sidebar-column .title {
      background-color: #34455f;
      color: #ffffff;
      padding: 19px 15px 19px;
      font-size: 30px;
      font-family: "ProximaNova-Regular";
      letter-spacing: -1.65px;
      text-align: center; }
      @media (max-width: 767px) {
        .filters-widget .sidebar-column .title {
          padding: 15px 15px 15px;
          font-size: 18px;
          letter-spacing: 0; } }
    .filters-widget .sidebar-column .checkbox-title {
      background-color: #299cff;
      border: 1px solid #34455f;
      color: #ffffff;
      font-size: 22px;
      text-align: left;
      padding: 15px 30px;
      position: relative;
      width: 100%; }
      @media (max-width: 767px) {
        .filters-widget .sidebar-column .checkbox-title {
          font-size: 18px;
          padding: 8px 28px; } }
      .filters-widget .sidebar-column .checkbox-title:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        vertical-align: middle;
        content: "";
        border-top: 12px solid #34455f;
        border-right: 12px solid transparent;
        border-bottom: 0;
        border-left: 12px solid transparent;
        position: absolute;
        right: 40px;
        top: calc(50% - 6px);
        -moz-transition: opacity 0.15s linear;
        -o-transition: opacity 0.15s linear;
        -ms-transition: opacity 0.15s linear;
        -webkit-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
        transform: rotate(180deg); }
        @media (max-width: 767px) {
          .filters-widget .sidebar-column .checkbox-title:after {
            border-top: 8px solid #34455f;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            top: calc(50% - 2px);
            right: 27px; } }
      .filters-widget .sidebar-column .checkbox-title.collapsed:after {
        transform: rotate(0); }
    .filters-widget .sidebar-column .check-list {
      padding: 12px 10px 15px; }
      @media (max-width: 767px) {
        .filters-widget .sidebar-column .check-list {
          padding: 12px 20px 15px; } }
      .filters-widget .sidebar-column .check-list > form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .filters-widget .sidebar-column .form-group {
      display: inline-block;
      padding: 0 7px;
      width: 50%; }
      @media (max-width: 1530px) {
        .filters-widget .sidebar-column .form-group {
          width: 100%; } }
      @media (max-width: 991px) {
        .filters-widget .sidebar-column .form-group {
          width: 50%; } }
      .filters-widget .sidebar-column .form-group label {
        font-family: "ProximaNova-Light"; }
    .filters-widget .sidebar-column .btn-holder {
      background-color: #34455f;
      text-align: center;
      padding: 22px 10px; }
      @media (max-width: 767px) {
        .filters-widget .sidebar-column .btn-holder {
          padding: 12px 10px; } }
      .filters-widget .sidebar-column .btn-holder .btn {
        text-transform: uppercase; }

.intro-game-widget .heading-holder {
  margin: 40px 0 15px; }
  @media (max-width: 767px) {
    .intro-game-widget .heading-holder {
      margin: 25px 0 15px; } }
  .intro-game-widget .heading-holder .title {
    color: #34455f;
    font-size: 40px;
    font-family: "ProximaNova-Semibold"; }
    @media (max-width: 767px) {
      .intro-game-widget .heading-holder .title {
        font-size: 28px; } }
  .intro-game-widget .heading-holder .date {
    color: #34455f;
    font-size: 16px;
    margin: 5px 0 0;
    line-height: 1.2; }

.intro-game-widget .img-holder {
  margin: 0 0 40px; }
  @media (max-width: 767px) {
    .intro-game-widget .img-holder {
      margin: 0 0 25px; } }
  .intro-game-widget .img-holder img {
    width: 100%; }

.intro-game-widget h2 {
  color: #34455f;
  font-family: "ProximaNova-Bold";
  font-size: 25px;
  padding: 25px 0 0; }
  @media (max-width: 767px) {
    .intro-game-widget h2 {
      font-size: 20px;
      padding: 10px 0 0; } }

.intro-game-widget h3 {
  color: #34455f;
  font-size: 20px;
  margin: 25px 0 15px; }
  @media (max-width: 767px) {
    .intro-game-widget h3 {
      font-size: 17px;
      margin: 10px 0 10px; } }

.category .heading-holder {
  margin: 40px 0 57px; }

.category .heading-text {
  line-height: 1.3;
  font-style: italic; }

.category-list-items-widget {
  padding: 40px 0 40px; }
  .category-list-items-widget .category-holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .category-list-items-widget .category-holder .post-caption {
      margin: 0 0 30px;
      max-width: 33.33%; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .category-list-items-widget .category-holder .post-caption {
          max-width: 50%; } }
      @media (max-width: 767px) {
        .category-list-items-widget .category-holder .post-caption {
          max-width: 100%; } }
    .category-list-items-widget .category-holder .post-item {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin: 0 15px;
      overflow: hidden; }
      .category-list-items-widget .category-holder .post-item .img-holder {
        height: 257px;
        position: relative; }
        @media (max-width: 767px) {
          .category-list-items-widget .category-holder .post-item .img-holder {
            height: 162px; } }
        .category-list-items-widget .category-holder .post-item .img-holder img {
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%; }
      .category-list-items-widget .category-holder .post-item .text-holder {
        text-align: center;
        padding: 16px 35px 20px; }
        @media (max-width: 767px) {
          .category-list-items-widget .category-holder .post-item .text-holder {
            background-color: rgba(39, 89, 164, 0.1);
            padding: 10px 30px 10px; } }
        .category-list-items-widget .category-holder .post-item .text-holder .title {
          color: #34455f;
          font-family: "ProximaNova-Semibold";
          font-size: 22px; }
          @media (max-width: 767px) {
            .category-list-items-widget .category-holder .post-item .text-holder .title {
              font-size: 16px; } }
        .category-list-items-widget .category-holder .post-item .text-holder .text p {
          color: #828282;
          font-size: 16px;
          margin: 25px 0 39px;
          line-height: 1.2; }
          @media (max-width: 767px) {
            .category-list-items-widget .category-holder .post-item .text-holder .text p {
              font-size: 13px;
              margin: 13px 0 13px; } }
        .category-list-items-widget .category-holder .post-item .text-holder .link {
          color: #e70021;
          font-family: "ProximaNova-Semibold";
          font-size: 17px; }
          @media (max-width: 767px) {
            .category-list-items-widget .category-holder .post-item .text-holder .link {
              font-size: 13px; } }
