.category-list-items-widget {
  padding: 40px 0 40px;
  .category-holder {
    @include flexbox();
    @include flex-wrap(wrap);
    .post-caption {
      margin: 0 0 30px;
      max-width: 33.33%;
      @include breakpoint('md-sm') {
        max-width: 50%;
      }
      @include breakpoint('xs') {
        max-width: 100%;
      }
    }
    .post-item {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin: 0 15px;
      overflow: hidden;
      .img-holder {
        height: 257px;
        position: relative;
        @include breakpoint('xs') {
          height: 162px;
        }
        img {
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      .text-holder {
        text-align: center;
        padding: 16px 35px 20px;
        @include breakpoint('xs') {
          background-color: rgba(39, 89, 164, 0.1);
          padding: 10px 30px 10px;
        }
        .title {
          color: #34455f;
          font-family: $additionalFont_Semibold;
          font-size: 22px;
          @include breakpoint('xs') {
            font-size: 16px;
          }
        }
        .text {
          p {
            color: #828282;
            font-size: 16px;
            margin: 25px 0 39px;
            line-height: 1.2;
            @include breakpoint('xs') {
              font-size: 13px;
              margin: 13px 0 13px;
            }
          }
        }
        .link {
          color: #e70021;
          font-family: $additionalFont_Semibold;
          font-size: 17px;
          @include breakpoint('xs') {
            font-size: 13px;
          }
        }
      }

    }
  }
}