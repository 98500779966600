.info-hold-widget {
  background: url('../img/info-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px 0 0;
  .info-cta-container {
    @include flexbox();
    @include align(center);
    @include justify(space-between);
    @include breakpoint('sm-xs') {
      @include flex-wrap(wrap);
    }
  }
  .img-holder {
    max-height: 475px;
    overflow: hidden;
    @include breakpoint('sm-xs') {
      text-align: center;
      width: 100%;
    }
    img {
      max-width: 100%;
    }
    .label {
      position: absolute;
      right: 0;
      &.top {
        top: 0;
        img {
          @include breakpoint('xs') {
            max-width: 60px;
          }
        }
      }
      &.center {
        top: 50%;
        margin-top: -45px;
        img {
          max-height: 90px;
        }
      }
    }
  }
  .text-holder {
    width: 48%;
    @include breakpoint('sm-xs') {
      text-align: center;
      width: 100%;
    }
    .title {
      color: #1f375b;
      font-family: $additionalFont_bold;
      font-size: 45px;
      font-style: italic;
      text-transform: uppercase;
      margin: 0 0 45px;
      @include breakpoint('xs') {
        font-size: 24px;
        margin: 0 0 10px;
        padding: 0 40px;
      }
    }
    .text {
      color: #595756;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 1.2;
      margin: 25px 0 0;
      @include breakpoint('xs') {
        font-size: 13px;
        margin: 0;
      }
    }
    .link {
      color: #1f375b;
      font-family: $additionalFont_Semibold;
      font-size: 20px;
      text-decoration: underline;
      text-transform: uppercase;
      display: block;
      margin: 40px 0 0;
      @include breakpoint('xs') {
        font-size: 14px;
        margin: 7px 0 0;
      }
    }
  }
}