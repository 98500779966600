.payment-widget {
  background-color: $primaryColour;
  padding: 25px 0;
  text-align: center;
  @include breakpoint('xs') {
    padding: 13px 0;
  }
  .payment-container {
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 15px;
      @include breakpoint('xs') {
        padding: 0 8px;
      }
      a {
        display: inline-block;
        @include breakpoint('xs') {
          max-width: 40px;
        }
      }
    }
  }
  .grayscale {
    &:hover {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}