///// Star Rating
.rating {
    .stars {
        position: relative;
        display: inline-block;
        height: 23px;
        width: 120px;
        background-image: url("../img/stars.png");
        background-position: 0 0;
        background-repeat: repeat-x;
        .on {
            height: 23px;
            background-image: url("../img/stars.png");
            background-position: 0 -23px;
            background-repeat-y: no-repeat;
        }
    }
}