.anchor-nav-widget {
  background-color: #f0f3f8;
  .list {
    list-style-type: none;
    padding: 0;
    @include breakpoint('sm-xs') {
      text-align: center;
    }
    li {
      display: inline-block;
      text-align: center;
      a {
        color: #34455f;
        display: block;
        padding: 10px 18px 0;
        position: relative;
        line-height: 1.5;
        font-size: 17px;
        @include breakpoint('xs') {
          padding: 10px 10px 0;
        }
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 3px;
          left: 0;
          bottom: -1px;
          background-color: #39a3fe;
          visibility: hidden;
          @include anim(all 0.3s ease-in-out 0s);
        }
        &:hover {
          color: #39a3fe;
          &:after {
            visibility: visible;
            width: 100%;
          }
        }
      }
    }
    .img-holder {
      img {
        vertical-align: middle;
      }
    }
  }
}

.anchor-nav-category {
  .list {
    .img-holder {
      img {
        max-height: 30px;
      }
    }
  }
}