.page-footer {
  background-color: #299cff;
  color: $baseWhite;
  padding: 70px 0 40px;
  @include breakpoint('sm-xs') {
    padding: 30px 0 100px;
  }
  .flex-row {
    @include flexbox();
    @include justify(space-between);
    @include breakpoint('md-xs') {
      @include flex-wrap(wrap);
    }
  }
  .list-section {
    @include flexbox();
    @include justify(space-between);
    width: 59%;
    @include breakpoint('md-xs') {
      @include flex-wrap(wrap);
      width: 100%;
    }
    @include breakpoint('sm-xs') {
      padding: 0 15px;
    }
    @include breakpoint($max: 400px) {
      padding: 0;
    }
    .footer-nav {
      @include breakpoint('sm-xs') {
        padding: 0 15px;
        margin: 0 0 30px;
        width: 50%;
      }
      @include breakpoint($max: 400px) {
        padding: 5px;
      }
      .list-title {
        font-size: 20px;
        font-family: $additionalFont_Semibold;
        margin: 0 0 23px;
        @include breakpoint('xs') {
          font-size: 18px;
          margin: 0 0 5px;
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        li {
          line-height: 1.2;
          @include breakpoint('xs') {
            line-height: 1.4;
          }
          a {
            color: $baseWhite;
            font-size: 20px;
            @include breakpoint('xs') {
              font-size: 18px;
            }
            &:hover {
              color: $primaryColour;
            }
          }
        }
      }
    }
  }
  .contact-section {
    text-align: right;
    @include breakpoint('md-xs') {
      text-align: center;
      margin: 40px 0 0;
      width: 100%;
    }
    @include breakpoint('sm-xs') {
      margin: 5px 0 0;
    }
    .list-title {
      margin: 0 0 15px;
      @include breakpoint('sm-xs') {
        margin: 0 0 15px;
        font-size: 18px;
      }
    }
    .foot-copy {
      display: block;
      font-size: 15px;
      margin: 17px 0 0;
    }
  }
  .social-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0 3px;
      &.fb {
        a {
          padding: 0;
          background: #299cff;
          &:hover {
            svg {
              fill: $baseWhite;
              background: $primaryColour;
            }
          }
        }
        svg {
          height: 49px;
          width: 49px;
          fill: $baseWhite;
        }
      }
      a {
        background: $baseWhite;
        border-radius: 50%;
        padding: 8px;
        display: block;
        overflow: hidden;
        &:hover {
          svg {
            fill: $primaryColour;
          }
        }
      }
    }
    svg {
      height: 33px;
      width: 33px;
      fill: #299cff;
      @include anim(all .25s ease);
    }
  }
}