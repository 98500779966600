/* SLIDERS */
.slick-slider {
  .slide {
    display: block;
    padding: 0 0 10px;
  }
  .slick-arrow {
    position: absolute;
    top: calc( 50% - 58px );
    width: 50px;
    height: 50px;
    z-index: 5;
    cursor: pointer;
    text-indent: -9999px;
	@include anim(all .25s ease);
	&:hover {
		opacity: 0.75;
        @include breakpoint('sm-xs') {
          opacity: 0;
        }
	}
    &:after,
    &:before {
      content: '';
      background-color: #2759a4;
      position: absolute;
      left: 0;
      height: 12px;
      width: 30px;
      border-radius: 3px;
    }
    &:before {
      top: 12px;
    }
    &:after {
      top: 26px;
    }
  }
  .slick-prev {
    left: -5px;
    @include breakpoint('lg') {
      left: -45px;
    }
    @include breakpoint('xl') {
      left: -120px;
    }
    @include breakpoint('sm-xs') {
      height: 100%;
      top: 0;
      left: 0;
      width: 100px;
      opacity: 0;
    }
    @include breakpoint($max: 500px) {
      width: 65px;
    }
    &:before {
      transform: rotate(-45deg) translateX(0);
    }
    &:after {
      transform: rotate(45deg) translateX(0);
    }
  }
  .slick-next {
    right: -20px;
    @include breakpoint('lg') {
      right: -65px;
    }
    @include breakpoint('xl') {
      right: -120px;
    }
    @include breakpoint('sm-xs') {
      height: 100%;
      top: 0;
      right: 0;
      width: 100px;
      opacity: 0;
    }
    @include breakpoint($max: 500px) {
      width: 65px;
    }
    &:before {
      transform: rotate(45deg) translateX(0);
    }
    &:after {
      transform: rotate(-45deg) translateX(0);
    }
  }
  .slick-dots {
    list-style: none;
    padding: 0;
    width: 100%;
    text-align: center;
    margin: 27px 0 0;
    li{
      display: inline-block;
      margin: 0 15px;
    }
    button {
      background: none;
      border: 0;
      box-shadow: none !important;
      text-indent: -9999px;
      position: relative;
      width: 15px;
      height: 15px;
      outline: 0;
      padding: 0;
      background-color: #b7b7b7;
      border-radius: 50%;
      @include breakpoint('xs-sml') {
        width: 12px;
        height: 12px;
      }
    }
    .slick-active {
      button {
        background: $primaryColour;
      }
    }
  }
}

.guides-slider {
  .slide {
    display: inline-block;
  }
  .slick-current {
    @include breakpoint('sm-xs') {
      position: relative;
      transform: scale(1.1);
      z-index: 1;
      .slide-caption {
        .text-holder {
          background-color: $baseWhite;
        }
      }
    }
  }
  .slide {
    @include breakpoint('sm-xs') {
      padding: 30px 0 30px;
    }
  }
  .slide-caption{
    margin: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    max-width: 350px;
    @include breakpoint('lg') {
      max-width: 300px;
    }
    @include breakpoint('xl') {
      max-width: 407px;
    }
    @include breakpoint('sm-xs') {
      max-width: 100%;
    }
    .img-holder {
      height: 257px;
      position: relative;
      @include breakpoint('xs') {
        height: 162px;
      }
      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    .text-holder {
      text-align: center;
      padding: 16px 35px 20px;
      @include breakpoint('xs') {
        background-color: rgba(39, 89, 164, 0.1);
        padding: 10px 30px 10px;
      }
      .title {
        color: #34455f;
        font-family: $additionalFont_Semibold;
        font-size: 22px;
        @include breakpoint('xs') {
          font-size: 16px;
        }
      }
      .text {
        color: #828282;
        font-size: 16px;
        margin: 25px 0 39px;
        line-height: 1.2;
        @include breakpoint('xs') {
          font-size: 13px;
          margin: 13px 0 13px;
        }
      }
      .link {
        color: #e70021;
        font-family: $additionalFont_Semibold;
        font-size: 17px;
        @include breakpoint('xs') {
          font-size: 13px;
        }
      }
    }
  }
}