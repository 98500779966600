/* Theme specific variables */
//$primaryColour:blue;
//$downloadsWidgetColour: #35aee2;
//$downloadsWidgetColourDark: #055a7f;

/* SASS guidelines

.element{
	// Normal element styles here e.g. position: relative etc.

	@include breakpoint for this element

	.inner-elements{
		// Styles for inner elements here
	}

	&.modifier {
		// Styles for modifier of element here
	}
}
*/


//$grid-breakpoints: (
//				xs: 0,
//				sm: 576px,
//				md: 768px,
//				lg: 992px,
//				xl: 1200px
//) !default;


//bootstrap grid
$grid-breakpoints: (
	xs: 0,
	//sm: 576px,
	//md: 768px,
	xsm: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1530px
) !default;
$container-max-widths: (
	//sm: 540px,
	//md: 720px,
	xsm: 540px,
	sm: 720px,
	md: 960px,
	lg: 1160px,
	xl: 1500px
) !default;
@import '../../node_modules/bootstrap-4-grid/scss/grid';

// Base, Mixins and variables
@import 'base/mixins',
		'base/variables';

@import 'base/resets',
		'base/helpers',
		'base/slider',
		'base/base';

// Site Layout
@import 'layout/header',
		'layout/footer';

// Site Components
@import 'components/button',
		'components/rating',
		'components/pagination',
		'components/typography',
		'components/loader';

// Pages
@import 'pages/homepage',
		'pages/inner-casino',
		'pages/404';

// Global Widget Styles
@import 'widgets/widgets';

// Widgets
@import 'widgets/intro',
		'widgets/nav-cta',
		'widgets/casino-items',
		'widgets/casino-title',
		'widgets/tab-slider',
		'widgets/info-cta',
		'widgets/info-hold',
		'widgets/gambling',
		'widgets/faq',
		'widgets/payment',
		'widgets/anchor-nav.scss',
		'widgets/list-categories',
		'widgets/filters',
		'widgets/intro-game',
		'widgets/category-list-items';
