@font-face {
    font-family: ProximaNova-Regular;
    src: url("../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ProximaNova-Light;
    src: url("../fonts/proxima-nova/ProximaNova-Light.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Light.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Light.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Light.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova-Thin;
    src: url("../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Thin.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Thin.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Thin.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ProximaNova-Semibold;
    src: url("../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Semibold.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Semibold.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ProximaNova-Bold;
    src: url("../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Bold.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ProximaNova-Extrabold;
    src: url("../fonts/proxima-nova/ProximaNova-Extrabold.eot?#iefix");
    src: url("../fonts/proxima-nova/ProximaNova-Extrabold.eot?#iefix") format("eot"),
    url("../fonts/proxima-nova/ProximaNova-Extrabold.woff2") format("woff2"),
    url("../fonts/proxima-nova/ProximaNova-Extrabold.woff") format("woff"),
    url("../fonts/proxima-nova/ProximaNova-Extrabold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/*----------------------------------------------------------------
------------------------------------------------------------------
------------------------------ FONTS -----------------------------
------------------------------------------------------------------
----------------------------------------------------------------*/

.container-fluid{
    max-width: 1360px;
}

body {
    font-family: ProximaNova-Regular;
    font-size: 17px;
    @include breakpoint('xs') {
        font-size: 14px;
    }
}

.right,
.left {
    float: none;
}
.clearfix {
    clear: both;
    height: 0;
    width: 0;
}

button {
    background: none;
    border: 0;
    box-shadow: none !important;
    outline: none !important;
    @include anim(all .25s ease);
}
.stop-scrolling {
    overflow: hidden;
}
.no-anim,
.no-anim * {
    @include anim(none !important);
}
.no-anim-single {
    @include anim(none !important);
}
a {
    color: $primaryColour;
    @include anim(all .25s ease);
    cursor: pointer;
    outline: 0 !important;
    // &[href='#'], &[href=''] {
    //     cursor: default !important;
    // }
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

/* amending the default bootstrap 15px gutters */

//.container,
//.container-fluid {
//    padding-left: $gutterWidth;
//    padding-right: $gutterWidth;
//    @include breakpoint($max: 1050px) {
//        padding-left: $gutterWidthMobile;
//        padding-right: $gutterWidthMobile;
//    }
//}

//.container {
//    margin-right:auto;
//    margin-left:auto;
//    padding-left:15px;
//    padding-right:15px;
//    @media (min-width:768px) {
//        width:750px;
//    }
//    @media (min-width:992px) {
//        width:970px;
//    }
//    @media (min-width:1200px) {
//        width:1170px;
//    }
//    @media (min-width:1600px) {
//        width:1530px;
//    }
//}
.container-fluid{
    margin-right:auto;
    margin-left:auto;
    padding-left:15px;
    padding-right:15px;
}

.container {
    @include breakpoint('sm') {
        width: 100%;
    }
}
//.row {
//    margin-left: -$gutterWidth;
//    margin-right: -$gutterWidth;
//}
//
//.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9 {
//    padding-left: $gutterWidth;
//    padding-right: $gutterWidth;
//}

.main-body {
    padding: 0 0 105px;
    .page-template-page-home & {

    }
    @include breakpoint('xs') {
        padding: 0 0 50px;
    }
    .wb-standard-page & {
        padding: 0 0 80px;
        @include breakpoint($max: 1024px) {
            padding: 0 0 60px;
        }
        @include breakpoint('xs') {
            padding: 0 0 40px;
        }
    }
}

.badge {
    color: $textOnPrimaryColour;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    &.badge-primary {
        background-color: $primaryColour;
    }
}
.spinner{
    display: none;
}