.btn {
    color: $baseWhite;
    font-family: $additionalFont_bold;
    font-size: 18px;
    border: $borderWidth solid $baseWhite;
    box-shadow: 3px 3px 0 #214400;
    border-radius: 29px;
    padding: 12px 20px;
    position: relative;
    &:hover {
        color: $baseWhite;
    }
    &.green-btn {
        background-color: #60b511;
        &:hover {
            background-color: #6ac714;
        }
    }
    &.blue-gr {
        background-image: linear-gradient(-253deg, #3ccaff 0%, #0089ff 100%);
        position: relative;
        z-index: 1;
        & > span {
            position: relative;
            z-index: 3;
        }
        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 29px;
            background-image: linear-gradient(-253deg, #0089ff 0%, #3ccaff 100%);
            transition: opacity 0.25s ease-out;
            z-index: 2;
            opacity: 0;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        .arrow {
            display: inline-block;
            width: 25px;
            height: 15px;
            position: relative;
            &:before,
            &:after {
                content: '';
                position: absolute;
                right: 0;
                background-color: $baseWhite;
                height: 3px;
                width: 13px;
                @include breakpoint('xs') {
                    width: 10px;
                }
            }
            &:before {
                transform: rotate(45deg) translateX(0px);
                top: calc(50% - 5px);
                @include breakpoint('xs') {
                    top: calc(50% - 1px);
                }
            }
            &:after {
                transform: rotate(-45deg) translateX(0px);
                top: 10px;
                @include breakpoint('xs') {
                    top: 11px;
                }
            }
        }
    }
}