.faq-widget {
    background-color: $widgetBackgrounGray;
    padding: 80px 0 80px;
    @include breakpoint('xs') {
        padding: 30px 0 40px;
    }
    .title {
        font-family: $additionalFont_bold;
        font-size: 35px;
        text-align: center;
        margin: 0 0 43px;
        @include breakpoint('xs') {
            font-size: 20px;
            margin: 0 0 15px;
        }
    }
    .card {
        border-top: 2px solid #a1a1a1;
        position: relative;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        margin: 0 50px;
        @include flexbox();
        @include breakpoint('xs') {
            margin: 0 10px;
        }
        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 2px solid #a1a1a1;
        }
        &.active {
            background-color: #e1e4e7;
            border-radius: 10px;
            border-color: transparent;
            margin: 0;
            padding: 0 50px 30px;
            @include breakpoint('xs') {
                padding: 0 10px 20px;
            }
            & + .card {
                border-top: 0;
            }
            .accordion-arrow {
                @include transform(rotate(#{180}deg));
            }
            .card-link {
                padding: 22px 0 10px;
                @include breakpoint('xs') {
                    padding: 15px 0 10px;
                }
            }
        }
    }
    .accordion {
        max-width: 1200px;
        margin: auto;
    }
    .card-header {
        .card-link {
            color: #299cff;
            font-family: $additionalFont_Semibold;
            font-size: 23px;
            padding: 27px 0;
            margin-bottom: 0;
            @include flexbox();
            @include align(center);
            @include justify(space-between);
            @include breakpoint('xs') {
                font-size: 18px;
                padding: 20px 0;
            }
        }
        .accordion-arrow {
            display: inline-block;
            position: relative;
            height: 35px;
            min-width: 35px;
            margin-left: 15px;
            @include anim(all .25s ease);
            @include breakpoint('xs') {
                height: 25px;
                min-width: 25px;
            }
            &:before,
            &:after {
                content: '';
                top: 50%;
                height: 3px;
                width: 13px;
                position: absolute;
                background-color: #5b5b5b;
                @include breakpoint('xs') {
                    width: 8px;
                }
            }
            &:before {
                @include transform(rotate(#{45}deg));
                left: 7px;
                // @include breakpoint('xs') {
                //     left: 6px;
                // }
            }
            &:after {
                @include transform(rotate(#{-45}deg));
                right: 7px;
                @include breakpoint('xs') {
                    right: 6px;
                }
            }
        }
    }
    .card-body {
        color: #545454;
        font-size: 16px;
        line-height: 1.2;
        @include breakpoint('xs') {
            font-size: 15px;
        }
    }
    .link-more{
        color: $primaryColour;
        font-family: $additionalFont_Semibold;
        font-size: 23px;
        text-decoration: underline;
        display: block;
        text-align: center;
        margin: 55px 0 0;
        @include breakpoint('xs') {
            font-size: 18px;
            margin: 40px 0 0;
        }
    }
}