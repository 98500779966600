.pagenumber-container {
  margin: 0;
  .wb-case-studies & {
    margin: 60px 0 0;
    @include breakpoint('xs') {
      margin: 30px 0 0;
    }
  }
  .wb-news & {
    @include breakpoint('xs') {
      margin: 20px 0 0;
    }
  }
}

ul.page-numbers {
  @include flexbox;
  @include justify(center);
  align-items: center;
  padding-left: 0;
  list-style: none;
  @include breakpoint('xs') {
    //@include justify(space-between);
  }

  li {
    float: left;
    @include breakpoint('xs') {
      display: none;
      &.current-li,
      &.prev,
      &.next {
        display: block !important;
      }
    }

    & + li {
      margin-left: 5px;
    }

    .page-numbers {
      width: 32px;
      height: 32px;
      text-align: center;
      color: $textDark;
      font-weight: 600;
      @include anim(all 250ms ease-in-out);
      @include flexbox;
      @include justify(center);
      align-items: center;
      border-radius: 100%;
      font-size: 24px;
      @include breakpoint('xs') {
        width: 30px;
        height: 30px;
      }
      i {
        line-height: 30px;
        font-size: 16px;
        font-weight: 600;
      }

      &:hover,
      &:focus {
        background: $primaryColour;
        &:not(.prev, .next) {
          color: #fff;
        }
      }

      &.next,
      &.prev {
        background: none;
        color: $primaryColour;
        background: transparent;
        border: 1px solid $primaryColour;
        &:hover,
        &:focus {
          background: $primaryColour;
          color: #fff;
        }

        &:not(a) {
          color: #838383;
          border: 1px solid #838383;
          &:hover,
          &:focus {
            background: transparent;
            color: #838383;
          }
        }

        @include breakpoint('xs') {
          padding: 0 5px;
        }
      }
      &.prev {
        margin: 0 13px 0 0;
        i {
          padding: 0 3px 0 0;
        }
      }
      &.next {
        margin: 0 0 0 13px;
        i {
          padding: 0 0 0 3px;
        }
      }

      &.current {
        color: #fff;
        background: $primaryColour;
      }

      &.dots {
        &:hover {
          background: transparent;
          color: $textDark;
        }
      }
    }
  }
}