.intro-widget {
  background: url("../img/intro.jpg");
  color: $baseWhite;
  padding: 30px 0 35px;
  background-size: cover;
  background-position: 50% 50%;
  @include breakpoint('xs') {
    padding: 18px 0 35px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 24, 64, 0.5);
    z-index: 1;
  }
  .text-holder {
    position: relative;
    z-index: 2;
    max-width: 62%;
    @include breakpoint('sm-xs') {
      max-width: 100%;
    }
  }
  .title {
    font-size: 60px;
    font-family: $additionalFont_bold;
    font-style: italic;
    text-transform: uppercase;
    @include breakpoint('sm-xs') {
      font-size: 45px;
    }
    @include breakpoint('xs') {
      font-size: 25px;
    }
  }
  p {
    font-size: 20px;
    line-height: 1;
    @include breakpoint('xs') {
      font-size: 16px;
      line-height: 0.95;
      margin: 9px 0 0;
    }
  }
}