.loader-widget {
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    @include flex-wrap(wrap);
    @include align(center);
    &.fade {
      .modal-dialog {
        transition: transform .3s ease-out;
        transform: translate(0,-25%);
      }
    }
    &.show {
      @include flexbox();
      .modal-dialog {
        transform: translate(0,0);
      }
    }
    .modal-dialog { 
      position: relative;
      margin: auto;
      max-width: 470px;
      @include breakpoint('sm-xs') {
        max-width: 370px;
      }
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      outline: 0;
      padding: 60px 30px;
      @include breakpoint('sm-xs') {
        padding: 50px 30px;
      }
    }
    .modal-title {
      font-size: 20px;
      color: $primaryColour;
      margin: 0 0 20px;
      @include breakpoint('sm-xs') {
        font-size: 18px;
      }
    }
    .img-holder {
      position: relative;
      img {
        display: block;
        max-height: 223px;
        margin: 0 auto;
        @include breakpoint('sm-xs') {
          max-height: 175px;
        }
      }
      .lds-spinner {
        position: absolute;
        bottom: 40px;
        right: 0;
        left: 60px;
        margin: auto;
        @include breakpoint('sm-xs') {
          bottom: 22px;
          left: 45px;
        }
      }
    }
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 29px;
  width: 2px;
  height: 12px;
  border-radius: 20%;
  background: $primaryColour;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
